import React from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../../contexts/Context";
import IconLibrary from './IconLibrary';

const Modal = ({
  show,
  setShowDelive,
  confirmDelivery,
  lanIndex,
  setDeliveryType,
  type,
  currencySymbol,
  deliveryPrice,
  includeDineIn,
  deliveryOrNot,
  tkDiMenuDiff,
  hasTakeAway
}) => {
  //State
  const displayShow = show ? "grid" : "none";
  const themeContext = React.useContext(ThemeContext);
  const languageContext = React.useContext(LanguageContext);
  const { deliveryCalMethod } = React.useContext(UserContext);

  const modalButton = () => {
    return (
      <ModalButtonContainer>
        <ModalConfirmButton
          themeContext={themeContext}
          onClick={() => {
            setShowDelive(false);
          }}
        >
          <ModalText
            fontSize={16}
            fontWeight={600}
            color={themeContext.fontPrimary}
          >
            {languageContext[lanIndex].cancel}
          </ModalText>
        </ModalConfirmButton>
        <ModalConfirmButton
          color="#fff"
          backgroundColor={themeContext.fontPrimary}
          themeContext={themeContext}
          onClick={() => {
            setShowDelive(false);
            confirmDelivery(type);
          }}
        >
          <ModalText
            fontSize={16}
            fontWeight={600}
            color={themeContext.cartFont}
          >
            {languageContext[lanIndex].ok}
          </ModalText>
        </ModalConfirmButton>
      </ModalButtonContainer>
    );
  }

  return (
    <ModalContainer display={displayShow}>
      <ModalMain themeContext={themeContext}>
        <ModalTitle themeContext={themeContext}>
          {languageContext[lanIndex].pleaseSelect}
          {(includeDineIn && !tkDiMenuDiff) &&
            <ModalDiv style={{ marginTop: '4px', fontSize: 16 }}>
              <ModalSpan onClick={() => { setDeliveryType(1) }} style={{ color: type == 1 ? themeContext.fontPrimary : '#bdbdbd' }}>
                {type == 1 ?
                  IconLibrary.dineInUnfocus(themeContext.fontPrimary)
                  :
                  IconLibrary.dineInFocus()
                }
                DINE IN
              </ModalSpan>
            </ModalDiv>
          }
          {hasTakeAway &&
            <ModalDiv style={{ marginTop: '4px', fontSize: 16 }}>
              <ModalSpan onClick={() => { setDeliveryType(0) }} style={{ color: type == 0 ? themeContext.fontPrimary : '#bdbdbd' }}>
                {type == 0 ?
                  IconLibrary.takeAwayUnfocus(themeContext.fontPrimary)
                  :
                  IconLibrary.takeAwayFocus()
                }
                TAKEAWAY
              </ModalSpan>
            </ModalDiv>
          }
          {deliveryOrNot &&
            <ModalDiv style={{ marginTop: '4px', fontSize: 16 }}>

              <ModalSpan onClick={() => { setDeliveryType(2) }} style={{ color: type == 2 ? themeContext.fontPrimary : '#bdbdbd' }}>
                {type == 2 ?
                  IconLibrary.deliveryUnfocus(themeContext.fontPrimary)
                  :
                  IconLibrary.deliveryFocus()
                }
                DELIVERY
              </ModalSpan>
            </ModalDiv>
          }
          {deliveryCalMethod == 0 &&
            <ModalContent style={{ opacity: type == 0 || type == 1 ? 0 : 1, marginTop: '10px' }}>
              {`${languageContext[lanIndex].deliveryFee}: ${currencySymbol} ${deliveryPrice}`}
            </ModalContent>
          }
        </ModalTitle>
        {modalButton()}
      </ModalMain>
    </ModalContainer>
  );
};

export default Modal;



const ModalContainer = styled.div`
  display: ${(props) => props.display};
  position: fixed;
  place-content: center;
  top: 0;

  width: 100vw;
  max-width: 550px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
`;

const ModalMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  position: relative;
  
  font-family: ${(props) => props.themeContext.fontFamily};
  background: #fff;
  z-index: 10;
  padding: 20px;
`;
const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
`;
const ModalContent = styled.div`
  font-size: 16px;
  margin-left: 10px;
  font-weight: normal;
  margin-top: 4px;
`;
const ModalButtonContainer = styled.div`
  margin-top: 12px;
  display: flex;
  width: 100%;
`;

const ModalDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
`;

const ModalSpan = styled.span`
  align-items: center;
  display: flex;
  padding: 8px;
  border-radius:2px;
  border: 1px solid;
  flex: 1;
  margin: 2%;
  justify-content: space-around;
`
const ModalConfirmButton = styled.div`
  flex: 1;
  background-color: ${(props) => props.backgroundColor};
  margin: 2%;
  margin-bottom: 0;
  padding: 5px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  place-content: center;
  border-radius: 6px;
  border: 2px solid;
  border-color: ${(props) => props.themeContext.fontPrimary};
`;

const ModalText = styled.span`
  align-self: center;
  justify-content: center;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  padding-left: ${(props) => (props.margin ? props.margin : 0)}px;
  padding-right: ${(props) => (props.margin ? props.margin : 0)}px;
`;
