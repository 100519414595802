import React from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext } from "../../contexts/Context";

const Modal = ({
  show,
  setShow,
  pax
}) => {
  //State
  const displayShow = show ? "grid" : "none";
  const themeContext = React.useContext(ThemeContext);
  const {lanIndex} = React.useContext(UserContext);
  const languageContext = React.useContext(LanguageContext);

  const modalContent = () => {
    return (
      <React.Fragment key={0}>
        <ModalContentText
          textAlign={"center"}
          fontSize={24}
          fontWeight={400}
          color={"black"}
          margin={10}
        >
          {pax == 0 ?
            languageContext[lanIndex].paxWarning : languageContext[lanIndex].orderWarning
          }
          <br />
        </ModalContentText>
      </React.Fragment>
    );

  };

  const modalButton = () => {
    return (
      <ModalConfirmButton
        themeContext={themeContext}
        onClick={() => setShow(false)}
      >
        <ModalText
          fontSize={20}
          fontWeight={600}
          color={themeContext.cartFont}
        >
          OK
          </ModalText>
      </ModalConfirmButton>
    );
  }

  return (
    <ModalContainer display={displayShow}>
      <ModalMain themeContext={themeContext}>
        {modalContent()}
        {modalButton()}
      </ModalMain>
    </ModalContainer>
  );
};

export default Modal;

const ModalContainer = styled.div`
  display: ${(props) => props.display};
  position: fixed;
  place-content: center;
  top: 0;

  width: 100vw;
  max-width: 550px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
`;

const ModalMain = styled.div`
  border-radius: 5px;
  position: relative;
  min-height: 200px;
  max-height: 500px;
  width: 80%;
  font-size: 20px;
  font-weight: 500;
  font-family: ${(props) => props.themeContext.fontFamily};
  background-color: ${(props) => props.themeContext.backgroundColor};
  color: black;
  z-index: 10;
  padding: 20px;
  box-sizing: border-box;
`;

const ModalConfirmButton = styled.div`
  background-color: ${(props) => props.themeContext.primaryColor};
  color: white;
  margin: 2%;
  padding: 3%;
  margin-top: 50px;
  max-height: 40px;
  text-align: center;
  place-content: center;
  border-radius: 10px;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
`;

const ModalContentText = styled.ul`
  align-self: center;
  justify-content: center;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  padding-left: ${(props) => (props.margin ? props.margin : 0)}px;
  padding-right: ${(props) => (props.margin ? props.margin : 0)}px;
`;

const ModalText = styled.span`
  align-self: center;
  justify-content: center;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  padding-left: ${(props) => (props.margin ? props.margin : 0)}px;
  padding-right: ${(props) => (props.margin ? props.margin : 0)}px;
`;
