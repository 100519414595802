import React, { useState } from "react";
import { ThemeContext, ActionContext, LanguageContext, UserContext } from "../contexts/Context";
import styled from "@emotion/styled";
import { navigate } from "@reach/router"
import ReviewOrderModal from './ReviewOrderModal';
import { IoReaderOutline, IoArrowBack, IoPersonOutline, IoSearchOutline, IoCloseCircle } from "react-icons/io5";
import AdvocadoLogin from "./advocadoComponents/AdvocadoLogin";
import AdvocadoPage from "./advocadoComponents/AdvocadoPage";

import CustomerLogin from "./customerComponents/CustomerLogin";
import CustomerDetail from "./customerComponents/CustomerDetail";

const Header = ({ hasBack }) => {
    const languageContext = React.useContext(LanguageContext);
    const themeContext = React.useContext(ThemeContext);
    const { lanIndex, isTakeAway, isMenuView, isInRoom, tableLabel, company, tkType, supportMultiLan, hasCategoryScreen, hasAdvocado, adCustomer, isSearching, hasMember, customer, showLogo } = React.useContext(UserContext);
    const { setLanIndex, setIsSearch, setSearchTxt, setSearching } = React.useContext(ActionContext);
    const [showOrder, setShowOrder] = useState(false);
    const [showAdvocadoLogin, setShowAdvocadoLogin] = useState(false);
    const [showAdCustomer, setShowAdCustomer] = useState(false);
    const [showCustomerLogin, setShowCustomerLogin] = useState(false);
    const [showCustomer, setShowCustomer] = useState(false);

    const handleLanIndex = (lanIndex) => (e) => {
        setLanIndex(lanIndex);
    }

    if (typeof lanIndex === 'undefined') {
        return <></>
    }
    const showAdC = () => {
        setShowAdvocadoLogin(false);
        setShowAdCustomer(true);
    }

    return (
        <React.Fragment>
            <HeaderDisplay themeContext={themeContext}>
                {hasBack && hasCategoryScreen && !isSearching &&
                    <IconButton themeContext={themeContext} onClick={() => {
                        // window.history.back();
                        navigate('/category')
                    }}>
                        <IoArrowBack fontSize={24} />
                    </IconButton>
                }
                {!isSearching && showLogo && company.companyLogo &&
                    <HeaderImg
                        src={
                            `https://ireachfnb.com${company.companyLogo}`
                            // `https://newdemo.chachingfnb.com${company.companyLogo}`
                        }
                    />
                }
                {hasBack && isSearching &&
                    <SearchTxt themeContext={themeContext} onChange={(e) => { setSearchTxt(e.target.value) }} />
                }
                {hasBack &&
                    <TableDisplay themeContext={themeContext} style={{ marginLeft: 8 }} onClick={() => {
                        if (!isSearching) {
                            setIsSearch(true);
                            setSearching(true);
                            let el = document.getElementById("rightBar");
                            if (el != null) {
                                el.classList.remove("bar-ani");
                            }
                        } else {
                            setIsSearch(false);
                            setSearching(false);
                            setSearchTxt("");
                            let el = document.getElementById("rightBar");
                            if (el != null) {
                                el.classList.add("bar-ani");
                            }
                        }
                    }}>
                        {!isSearching ?
                            <IoSearchOutline fontSize={24} />
                            :
                            <IoCloseCircle fontSize={24} />
                        }
                    </TableDisplay>
                }
                {/* <HeaderLogo image={company.companyLogo} themeContext={themeContext} /> */}
                <RightWrapper id="rightBar">
                    {!isSearching &&
                        <>
                            {/* {!isTakeAway && !isMenuView &&
                        <TableDisplay themeContext={themeContext}>
                            {languageContext[lanIndex].table}: {tableLabel}
                        </TableDisplay>
                    } */}

                            {isTakeAway && tkType == 0 &&
                                <TableDisplay themeContext={themeContext}>
                                    Takeaway
                                </TableDisplay>
                            }
                            {isTakeAway && tkType == 1 &&
                                <TableDisplay themeContext={themeContext}>
                                    Dine In
                                </TableDisplay>
                            }
                            {isInRoom &&
                                <TableDisplay themeContext={themeContext}>
                                    In Room Dining
                                </TableDisplay>
                            }
                            {isMenuView &&
                                <TableDisplay themeContext={themeContext}>
                                    E Menu
                                </TableDisplay>
                            }
                            {supportMultiLan &&
                                <TableLanWrapper themeContext={themeContext}>
                                    <TableLan onClick={handleLanIndex(0)} isSelected={lanIndex === 0} themeContext={themeContext}>En</TableLan>|
                                    <TableLan onClick={handleLanIndex(1)} isSelected={lanIndex === 1} themeContext={themeContext}>中</TableLan>
                                </TableLanWrapper>
                            }
                            <TableDisplay themeContext={themeContext} onClick={() => { setShowOrder(true) }}>
                                <IoReaderOutline fontSize={24} />
                                <IconTitle2>{languageContext[lanIndex].reviewOrder}</IconTitle2>
                            </TableDisplay>
                            {hasAdvocado &&
                                <TableDisplay themeContext={themeContext} onClick={() => {
                                    adCustomer ? setShowAdCustomer(true) : setShowAdvocadoLogin(true)
                                    // setShowAdCustomer(true);
                                }}>
                                    <IoPersonOutline fontSize={24} />
                                    <IconTitle>{adCustomer ? languageContext[lanIndex].memberInfo : languageContext[lanIndex].MemberLogin}</IconTitle>
                                </TableDisplay>
                            }
                            {hasMember &&
                                <TableDisplay themeContext={themeContext} onClick={() => {
                                    customer ? setShowCustomer(true) : setShowCustomerLogin(true)
                                    // setShowAdCustomer(true);
                                }}>
                                    <IoPersonOutline fontSize={24} />
                                    <IconTitle>{customer ? languageContext[lanIndex].memberInfo : languageContext[lanIndex].MemberLogin}</IconTitle>
                                </TableDisplay>
                            }
                        </>
                    }
                </RightWrapper>
            </HeaderDisplay>
            {showOrder &&
                <ReviewOrderModal
                    show={showOrder}
                    setShow={setShowOrder}
                />
            }
            {showAdvocadoLogin &&
                <AdvocadoLogin
                    show={showAdvocadoLogin}
                    setShow={setShowAdvocadoLogin}
                    handleCallback={showAdC}
                />
            }
            {showAdCustomer &&
                <AdvocadoPage
                    show={showAdCustomer}
                    setShow={setShowAdCustomer}
                />
            }
            {showCustomer &&
                <CustomerDetail
                    show={showCustomer}
                    setShow={setShowCustomer}
                />
            }
            {showCustomerLogin &&
                <CustomerLogin
                    show={showCustomerLogin}
                    setShow={setShowCustomerLogin}
                />
            }
        </React.Fragment>
    )



}

export default Header;
const HeaderImg = styled.img`
    height: 100%;
`;
const SearchTxt = styled.input`
    &:focus-visible {
        outline: none;
    }
    width: 200px;
    height: 30px;
    border: none;
    background: none;
    margin-bottom: 4px;
    padding-left: 8px;
    color: ${props => props.themeContext.headerColor};
    border-bottom: 1px solid ${props => props.themeContext.headerColor};
    animation: slidIn .1s;
    animation-timing-function: ease-out;
    @keyframes slidIn {
        0%  {
        transform: translateX(-200px);
        opacity: 0;
        }
        
        100%  {
        transform: translateX(0px);
        opacity: 1;
        }
        
    }
`;
const RightWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-grow:1;
`;
const IconButton = styled.div`
    color: ${(props) => props.themeContext.fontSecondary};
    display: flex;
    right: 0.5rem;
   
    justify-content: center;
    align-items: center;
    margin-left: 4px;
    margin-right: 4px;
    z-index: 5;
`;
const TableDisplay = styled.div`
  display: flex;
  right: 0.5rem;
  text-align: right;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-right: 4px;
  font-weight: 400;
  font-family: ${(props) => props.themeContext.fontFamily};
  color: ${(props) => props.themeContext.headerColor};
  z-index: 5;
`;
const IconTitle = styled.div`
    font-size: 10px;
    text-wrap: wrap;
    text-align: left;
    padding-left: 4px;
    width: 38px;
    line-height: 12px;
`;

const IconTitle2 = styled.div`
    font-size: 10px;
    text-wrap: wrap;
    text-align: left;
    padding-left: 4px;
    width: 29px;
    line-height: 12px;
`;

//Styled Components
const HeaderDisplay = styled.div`
  display: flex;
  position: fixed;
  top: 0rem;
  text-align: center;
  background-color: ${(props) => props.themeContext.menuHeaderColor};
  justify-content: flex-start;
  align-items: left;
  box-shadow: 0px 0px 3px rgba(0,0,0,0.7);
  height: ${(props) => props.themeContext.headerHeight}px;
  width: 100%;
  //Adjust the image height to be adjusted based on the screen size at a later date.
  z-index: 5;
  max-width: 550px;
`;

const HeaderLogo = styled.div`
  position: absolute;
  height: 24px;
  width: 100%;
  flex-grow: 1;
  margin-top: 8px;
  margin-bottom: 8px;
  background-image: url(${(props) => `https://ireachfnb.com${props.image}`});
  /*background-image: url(${(props) => `https://newdemo.chachingfnb.com${props.image}`});*/
  background-size: contain;
  background-position: ${(props) => props.themeContext.headerLogoPosition};
  background-repeat: no-repeat;
`;


const TableLanWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 5;
  color: ${(props) => props.themeContext.headerColor}
`;

const TableLan = styled.div`
  width: 40px;
  color: ${(props) => props.isSelected ?
        props.themeContext.cartFont :
        props.themeContext.headerColor}
`;

