import React from "react";
import styled from "@emotion/styled";
import { ThemeContext } from "../../contexts/Context";

const ArrayBar = ({
  marginTop,
  marginBottom,
  setActiveState,
  produceNewState,
  produceArrayStateMulti,
  array,
  height,
  widthPercentage,
  bold,
  fontSize,
  lanIndex,
  isLanReverse,
  menuIndex,
  isMultiple
}) => {
  const themeContext = React.useContext(ThemeContext);

  return (
    <ArrayBarContainer
      height={height + 5}
      marginTop={marginTop}
      marginBottom={marginBottom}
      width={widthPercentage}
    >
      {array.map((item, index) => (
        <React.Fragment key={item.title + index}>
          <ArrayOption themeContext={themeContext}>
            <ArrayBarButton
              fontSize={fontSize}
              bold={bold}
              themeContext={themeContext}
              onClick={() => {
                if (!isMultiple)
                  setActiveState(produceNewState(array, item.itemOptionId, false, false, menuIndex));
                else
                  setActiveState(produceArrayStateMulti(array, item.itemOptionId));
              }}
              index={index}
              activeState={item.active}
              array={array}
            >
              {(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? item.title : item.title2 ? item.title2 : item.title}
            </ArrayBarButton>
          </ArrayOption>
        </React.Fragment>
      ))}
    </ArrayBarContainer>
  );
};

export default ArrayBar;

const ArrayBarButton = styled.div`
  display: grid;
  background-color: ${(props) => {
    if (props.activeState) {
      return props.themeContext.primaryColor;
    } else {
      return "white";
    }
  }};
  color: ${(props) => (props.activeState ? "white" : "black")};
  font-family: ${(props) => props.themeContext.fontFamily};
  word-wrap: break-word;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 4px;
  padding-top: 6px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : 12)}px;
  font-weight: ${(props) => (props.bold ? "bold" : 500)};
  border: 1px solid ${(props) => props.themeContext.borderColor};
  border-radius: 0px;
  border-top-left-radius: ${(props) => {
    if (props.index === 0) {
      return 5;
    }
  }}px;
  border-bottom-left-radius: ${(props) => {
    if (props.index === 0) {
      return 5;
    }
  }}px;
  border-top-right-radius: ${(props) => {
    if (props.index === props.array.length - 1) {
      return 5;
    }
  }}px;
  border-bottom-right-radius: ${(props) => {
    if (props.index === props.array.length - 1) {
      return 5;
    }
  }}px;
  min-width: 60px;
  box-shadow: ${(props) => (!props.activeState ?
    "0px 1px 10px rgba(0,0,0,70%);"
    : "none")};
    margin-bottom: 12px;
`;

const ArrayOption = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${(props) => (props.height ? props.height : 48)}px;
  width: 100%;
  
`;
const ArrayBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)}px;
  min-height: ${(props) => (props.height ? props.height : 48)}px;
  width: ${(props) => (props.width ? props.width : '100%')};
  overflow-y: hidden;
  overflow-x: auto;
`;
