import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext } from "../contexts/Context";
import axios from "axios";
import { MdOutlineFoodBank } from "react-icons/md";
import { IoPricetagOutline } from "react-icons/io5";
const KitchenStatus = () => {
    const [isComplete, setComplete] = useState(false);
    const [warningMsg, setWarningMsg] = useState("");
    const [tableId, setTableId] = useState("");
    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { lanIndex, showKitchenStatus, showPickUpInfo, pickUpPoint, token, userId, isTakeAway, orderTime} = React.useContext(UserContext);
    const TAKEAWAY = "5e054c3d69fu3ivdAFf3cc";
    useEffect(() => {
        const fetchKitchenStatus = () => {
            let orderId = localStorage.getItem("orderId");
            axios.get(`/v2/api/selfOrder/order/status?userId=${userId}&orderTime=${orderTime}&orderId=${orderId}`, {
                headers: { Authorization: "Bearer " + token },
            })
                .then(function (response) {
                    // handle success
                    setComplete(response.data.isComplete);
                    setTableId(response.data.tableId);
                })
                .catch(function (response) {
                    console.log(response);
                })
        }
        let intervalId;
        if (token) {
            fetchKitchenStatus();
            intervalId = setInterval(fetchKitchenStatus, 30000);
        }

        return () => clearInterval(intervalId);

    }, [token]);
    const getPickUpPoint = () => {
        let pickUpTxt = "";
        if (tableId == TAKEAWAY) {
            pickUpPoint.forEach((pu) => {
                if (pu.type == 1) {
                    pickUpTxt = pu.pickUpPoint;
                }
            });
        } else {
            pickUpPoint.forEach((pu) => {
                if (pu.tableIds.indexOf(tableId) > -1) {
                    pickUpTxt = pu.pickUpPoint;
                }
            });
        }
        return (
            <KitchenMsgContainer>
               {pickUpTxt}
            </KitchenMsgContainer>
        );
    }

    return (
        <React.Fragment>
            <KitchenContainer themeContext={themeContext}>
                <Wrapper>
                    <IconWraper>
                        <MdOutlineFoodBank fontSize={24} />
                    </IconWraper>
                    <Content themeContext={themeContext} style={{ marginLeft: 8 }}>

                        <SubContent>{languageContext[lanIndex].currentOrderStatus}</SubContent>
                        <KitchenStatusContainer themeContext={themeContext} isComplete={isComplete}>
                            {isComplete ? languageContext[lanIndex].readyToCollect : languageContext[lanIndex].preparing}
                        </KitchenStatusContainer>
                    </Content>
                </Wrapper>
                {showPickUpInfo && isComplete &&
                <Wrapper>
                    <IconWraper>
                        <IoPricetagOutline fontSize={24} />
                    </IconWraper>
                    <Content themeContext={themeContext} style={{ marginLeft: 8 }}>
                        <SubContent>{languageContext[lanIndex].pickUpPoint}</SubContent>
                        {getPickUpPoint()}
                    </Content>
                </Wrapper>
                
                    
                }
            </KitchenContainer>
        </React.Fragment >
    );
}

export default KitchenStatus;

const Content = styled.div`
    
`;

const KitchenContainer = styled.div`
 
  margin: 0 16px;
   padding:8px;
   border: 1px solid;
   border-radius: 6px;
   margin-bottom: 8px;
   display: flex;
`;

const KitchenMsgContainer = styled.div`
    font-size: 16px;
`;

const SubContent = styled.div`
    font-size: 12px;
`;

const KitchenStatusContainer = styled.div`
  color: ${(props) => props.isComplete ? "#009688" : "#ffc107"};
  animation: wave-animation 1.5s linear infinite;

  @keyframes wave-animation {
      0%, 100% {
        opacity: 1;
      }
      50% {
        opacity: 0.6;
      }
    }
`;

const Wrapper = styled.div`
    display:flex;
    align-items: center;
    margin-right: 16px;
`;
const IconWraper = styled.div`
   
`;


