import React from "react";
import styled from "@emotion/styled";
import { ThemeContext } from "../../contexts/Context";
//import RemarksTab from "./RemarksTab";
// import produce from "immer";

const Modal = ({
  show,
  setShow,
  error,
}) => {
  //State
  const displayShow = show ? "flex" : "none";
  const themeContext = React.useContext(ThemeContext);

  const modalContent = (error) => {
    return error.map((errorObject, index) => {
      return (
        <React.Fragment key={errorObject.errorElement + index}>
          <ModalContentText
            textAlign={"center"}
            fontSize={16}
            fontWeight={400}
            color={themeContext.fontPrimary}
            margin={10}
          >
            {errorObject.errorMessage}
            <br />
          </ModalContentText>
        </React.Fragment>
      );
    });
  };

  const modalButton = () => {
    return (
      <ModalButtonContainer>
        <ModalConfirmButton
          themeContext={themeContext}
          onClick={() => setShow(false)}
        >
          <ModalText
            fontSize={16}
            fontWeight={600}
            color={themeContext.fontPrimary}
          >
            OK
          </ModalText>
        </ModalConfirmButton>
      </ModalButtonContainer>
    );
  };

  return (
    <ModalContainer display={displayShow}>
      <ModalMain themeContext={themeContext}>
        <ModalContent themeContext={themeContext} error={error}>
          {modalContent(error)}
        </ModalContent>
        {modalButton()}
      </ModalMain>
    </ModalContainer>
  );
};

export default Modal;

const ModalContainer = styled.div`
  display: ${(props) => props.display};
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;

  width: 100vw;
  max-width: 550px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
`;

const ModalMain = styled.div`
  border-radius: 5px;
  position: relative;
  justify-content: center;
  max-height: 500px;
  width: 80%;
  font-size: 20px;
  font-weight: 500;
  font-family: ${(props) => props.themeContext.fontFamily};
  background-color: #fff;
  color: black;
  z-index: 10;
`;

const ModalTitle = styled.div`
  background: ${(props) => props.themeContext.secondaryColor};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 10px;
  padding-bottom:8px;
  height: 100%;
  align-self: center;
  justify-content: center;
  text-align: left;
`;
const ModalContent = styled.div`
  color: ${(props) => props.themeContext.fontPrimary};

  overflow: scroll;
  text-align: ${(props) => (props.error ? "left" : "center")};
  align-items: center;
  margin-top: 36px;
`;
const ModalButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
`;

const ModalConfirmButton = styled.div`
  flex: 1;
  background-color: ${(props) => props.backgroundColor};
  color: white;
  margin: 6%;
  padding: 3%;
  line-height: 20px;
  max-height: 40px;
  text-align: center;
  place-content: center;
  border-radius: 6px;
  border: 2px solid;
  border-color: ${(props) => props.themeContext.fontPrimary};
`;

const ModalContentText = styled.div`
  align-self: center;
  justify-content: center;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  padding-left: ${(props) => (props.margin ? props.margin : 0)}px;
  padding-right: ${(props) => (props.margin ? props.margin : 0)}px;
`;

const ModalText = styled.span`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  padding-left: ${(props) => (props.margin ? props.margin : 0)}px;
  padding-right: ${(props) => (props.margin ? props.margin : 0)}px;
`;
