import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "@reach/router";
import styled from "@emotion/styled";
import { navigate } from "@reach/router"
import { wrap } from "popmotion";

import { UserContext, ThemeContext } from "../contexts/Context";

// const images = ["/Promo1.png", "/Promo2.png", "/Promo3.png"];
const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;

const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const Promotion = () => {
  const { ads, title, hasCategoryScreen } = React.useContext(UserContext);
  const themeContext = React.useContext(ThemeContext);
  const [[page, direction], setPage] = useState([0, 0]);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
    setPage([index, index - page]);
  };
  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.

  let finalAds = [];
  if (ads && ads.length > 0) {
    finalAds = ads.filter((mn) => {
      if (!title || !mn.titles) return true;
      return !mn.titles || mn.titles.length == 0 || mn.titles.indexOf(title) > -1;
    })
  }
  if (finalAds.length == 0) {
    if (hasCategoryScreen)
      navigate("/category");
    else
      navigate("/menu");
  }
  const imageIndex = wrap(0, finalAds.length, page);

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };
  let timer;
  useEffect(() => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      paginate(1);
    }, 5000);

  }, [page])

  let setTimer = () => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      paginate(1);
    }, 5000);
  }

  const SkipButton = styled.div`
    display: grid;
    position: fixed;
    bottom: 24px;
    right: 1rem;
    align-self: center;
    place-content: center;
    color: white;
    border: 5px solid white;
    border-radius: 10px;
    width: 100px;
    height: 50px;
    font-weight: bold;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10;
  `;

  const PromotionContainer = styled(motion.img)`
    display: grid;
    position: absolute;
    place-content: start;
    top: 0;
    width: 100vw;
    max-width: 550px;
    object-fit: contain;
  `;
  const BackgroundColor = styled.div`
    width: 100vw;
    max-width: 550px;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
  `;

  const DotWrapper = styled.div`
    position: fixed;
    bottom: 20px;
    width: 100vw;
    max-width: 550px;
  
  `;

  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <Link to={hasCategoryScreen ? "/category" : "/menu"}>
          <SkipButton>SKIP</SkipButton>
        </Link>
        <PromotionContainer
          key={page}
          src={`https://ireachfnb.com${finalAds[imageIndex].img}`}
          // src={`https://newdemo.chachingfnb.com${finalAds[imageIndex].img}`}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragStart={(e) => {
            if (timer) clearTimeout(timer);
          }}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);
            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            } else {
              setTimer();
            }
          }}
        />

      </AnimatePresence>
      <BackgroundColor />
      <DotWrapper style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
        {finalAds.map((_, index) => (
          <div
            key={index}
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: page % finalAds.length === index ? themeContext.primaryColor : 'gray',
              margin: '0 0.5rem',
              cursor: 'pointer',
            }}
          />
        ))}
      </DotWrapper>
    </>
  );
};

export default Promotion;
