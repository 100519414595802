import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import "../styles/styles.css";
import { ThemeContext } from "../contexts/Context";
import Cell from './MenuItemUnlimitedCell';


const MenuItemUnlimitedScroll = ({
  height,
  width,
  sidebarWidth,
  headerHeight,
  selectedCategory,
  setSelectedMenu,
  setSelectedCategory,
  theme,
  selectedItems,
  addItemDirect,
  cart,
  removeFromCart,
  menuList,
  lanIndex,
  isLanReverse,
  isScrolling,
  lastPos,
  setLastPos,
  cp,
  menuSelectionFormRef
}) => {
  const scrollPos = useRef(0);
  const scrolling = useRef(false);

  const catPos = useRef([]);

  const onscroll = (e) => {
    if (scrolling.current) return;

    if (!catPos.current || catPos.current.length == 0 || !catPos.current[0].category) return;
    let firstMenu = document.getElementById(catPos.current[0].category);
    if (firstMenu == null) return;

    let pos = firstMenu.getBoundingClientRect().top;
    let offset = pos - scrollPos.current;
    if (Math.abs(offset) < 160) return;
    scrollPos.current = pos;
    if (offset < 0) {//swipe up
      //height
      for (let i = catPos.current.length - 1; i >= 0; i--) {
        if (catPos.current[i].pos + pos < height - 20) {
          let category = catPos.current[i].category.replaceAll("/", "");
          category = category.replaceAll("%", "");
          setSelectedCategory(catPos.current[i].cat);
          break;
        }
      }
    } else { //swipe down
      for (let it of catPos.current) {
        if (it.pos + pos > 20) {
          let category = it.category.replaceAll("/", "");
          category = category.replaceAll("%", "");
          setSelectedCategory(it.cat);
          break;
        }
      }
    }


  }

  useEffect(() => {
    scrolling.current = isScrolling;
  }, [isScrolling]);


  const lazyLoad = () => {
    let lazyBg = document.querySelectorAll('.item-img');
    for (let bg of lazyBg) {
      const top = bg.getBoundingClientRect().top;
      if (top < window.innerHeight) {
        bg.style.backgroundImage = `url('${bg.dataset.bg}')`;
        bg.classList.remove('item-img');
      } else {
        break;
      }
    };
  }

  useEffect(() => {
    //preset each element position
    if (lastPos != 0) {
      // let currentPos = document.getElementById("menu_scroll").firstElementChild.getBoundingClientRect().top;
      // let offset = currentPos - lastPos;
      // document.getElementById("menu_scroll").scrollBy({ top: offset, behavior: "instant" });
      document.getElementById("menu_scroll").scrollTo({ top: lastPos, behavior: "instant" });
      window.setTimeout(() => {
        lazyLoad();
      }, 50);
    } else if (selectedCategory) {
      // console.log(selectedCategory.category);
      let catPos = document.getElementById(selectedCategory.category).getBoundingClientRect().top + 20;
      document.getElementById("menu_scroll").scrollTo({ top: catPos, behavior: "instant" });
      window.setTimeout(() => {
        lazyLoad();
      }, 50);
    }

    document.getElementById("menu_scroll").addEventListener('scroll', lazyLoad);
    document.getElementById("menu_scroll").addEventListener('resize', lazyLoad);
    document.getElementById("menu_scroll").addEventListener('scroll', onscroll);
    return () => {
      if (document.getElementById("menu_scroll") != null) {
        document.getElementById("menu_scroll").removeEventListener('scroll', onscroll);
        document.getElementById("menu_scroll").removeEventListener('scroll', lazyLoad);
        document.getElementById("menu_scroll").removeEventListener('resize', lazyLoad);
      }
    }
  }, []);

  useEffect(() => {
    let i = 0, fs = 0;
    catPos.current = [];
    for (let mn of menuList) {
      let el = document.getElementById(mn.category);
      if (el == null) continue;
      let pos = el.getBoundingClientRect().top;
      if (i == 0) {
        fs = pos;
      }

      catPos.current.push({ category: mn.category, pos: pos - fs, cat: mn });
      for (let sc of mn.type) {
        if (!sc.isCategory) continue;
        let el = document.getElementById(sc.category);
        let pos = el.getBoundingClientRect().top;
        catPos.current.push({ category: sc.category, pos: pos - fs, cat: sc });
      }
      i++;
    }
  }, [menuList]);

  const getHeight = (lt) => {
    let menuWidth = (Math.min(width, 550) - sidebarWidth);
    if (!cp) {
      if (lt.size == 0 || !lt.size) { //standard size
        //theme
        return (menuWidth / 6 * 3 + 36);
        // return (theme == 3 ? menuWidth / 4 * 3 );
      } else if (lt.size == 1) { //single size
        return (menuWidth / 3 * 3 + 36);
      } else if (lt.size == 2) { //pure text only
        return (96);
      } else if (lt.size == 3) {
        return (120);
      }
    } else {
      if (lt.size == 0 || !lt.size) { //standard size
        return (menuWidth / 6 * 3 + 36);
      } else if (lt.size == 1) { //single size
        return (menuWidth / 3 * 3 + 36);
      } else if (lt.size == 2) { //pure text only
        return (96);
      } else if (lt.size == 3) {
        return (120);
      }
    }
  }
  let menuWidth = (Math.min(width, 550) - sidebarWidth);

  //List Required Constants
  return (
    <MenuWrapper
      className="no-scrollbars"
      height={height - headerHeight}
      width={width}

    >
      {menuList && menuList.map((mn, i) =>
        <React.Fragment key={i}>
          <CategoryTitle id={mn.category}>
            {(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? mn.category : mn.category2}
          </CategoryTitle>
          <MenuItemWrapper>
            {mn.type.map((item, j) =>
              item.isCategory ?
                (
                  <React.Fragment key={"S" + j}>
                    <SubCategoryTitle id={item.category} width={menuWidth}>
                      {(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? item.category : item.category2}
                    </SubCategoryTitle>
                    {item.type.map((it, k) =>
                      it.codeId == "" ? "" :
                        <div style={{
                          width: it.size == 0 || !it.size ? (menuWidth) / 2 : menuWidth,
                          height: getHeight(it),
                          marginTop: 16,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                          <Cell
                            key={"" + i + k}
                            data={{
                              item: it,
                              setSelectedMenu,
                              setSelectedCategory,
                              theme,
                              selectedItems,
                              addItemDirect,
                              cart,
                              removeFromCart,
                              setLastPos,
                              cp,
                              menuSelectionFormRef
                            }}
                            columnIndex={i}
                            rowIndex={k}
                            containerWidth={it.size == 0 || !it.size ? (menuWidth / 2) : (menuWidth)}
                            containerHeight={getHeight(it)}
                          />
                        </div>
                    )}

                  </React.Fragment>
                )
                :
                (item.codeId == "" ? "" :
                  <div style={{
                    width: item.size == 0 || !item.size ? (menuWidth / 2) : (menuWidth),
                    height: getHeight(item),
                    marginTop: 16,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <Cell
                      key={"" + i + j}
                      data={{
                        item,
                        setSelectedMenu,
                        setSelectedCategory,
                        theme,
                        selectedItems,
                        addItemDirect,
                        cart,
                        removeFromCart,
                        setLastPos,
                        catPos,
                        cp,
                        menuSelectionFormRef
                      }}
                      columnIndex={i}
                      rowIndex={j}
                      containerWidth={item.size == 0 || !item.size ? (menuWidth - 15) / 2 : (menuWidth - 15)}
                      containerHeight={getHeight(item)}
                    />
                  </div>
                )
            )}
          </MenuItemWrapper>
        </React.Fragment>
      )}
      <Buffer height={200} />
    </MenuWrapper>
  );
};
export default (MenuItemUnlimitedScroll);


const Buffer = styled.div`
  display: block;
  height: ${(props) => props.height}px;
`;

const MenuWrapper = styled.div`
 
`;
const CategoryTitle = styled.div`
  display: flex;
  margin-left: 16px;
  align-items: center;
  padding-top: 36px;
  font-size: 18px;
  font-weight: 700;
`;

const SubCategoryTitle = styled.div`
  display: flex;
  margin-left: 16px;
  align-items: center;
  padding-top: 32px;
  font-size: 14px;
  font-weight: 500;
  width: ${props => props.width}px;
`;

const MenuItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;




