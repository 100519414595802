import React, { useEffect, useState } from "react";
import { ThemeContext, ActionContext, LanguageContext, UserContext } from "../contexts/Context";
import styled from "@emotion/styled";
import { Link } from "@reach/router";
import useWindowDimensions from "../hooks/useWindowDimensions";
import axios from "axios";

const Fail = () => {
  const themeContext = React.useContext(ThemeContext);
  const languageContext = React.useContext(LanguageContext);
  const { width } = useWindowDimensions();
  const { addTakeAwayId } = React.useContext(ActionContext);
  const { isTakeAway, takeAwayId, tkSuccessMessage, lanIndex, successMessage } = React.useContext(UserContext);
  const [totalAmount, setTotalAmount] = useState(0);


  useEffect(() => {
    localStorage.removeItem("order");
  }, []);

  useEffect(() => {
    let orderId = localStorage.getItem("orderId");
    let token = localStorage.getItem("token");
    if (!token) return;
    axios.get(
      `/v2/api/selfOrder/takeAwayId?orderId=${orderId}`,
      { headers: { Authorization: "Bearer " + token } }
    )
      .then(function (response) {
        // handle success
        addTakeAwayId(response.data.takeAwayId);
        setTotalAmount(response.data.totalAmount);
      })
      .catch(function (response) {
        // handle error
        console.log(response);
      });
    //remove
    localStorage.removeItem("order");
  }, [addTakeAwayId]);

  if (typeof lanIndex === 'undefined') {
    return <></>
  }
  return (
    <BackgroundColor themeContext={themeContext}>
      <Circle className="circle">
        <CheckIcon className="checkmark">!</CheckIcon>
      </Circle>

      <Header className="header">{languageContext[lanIndex].orderFail}</Header>
      <Paragraph className="paragraph">
        <br />{languageContext[lanIndex].orderFailDesc}<br />
      </Paragraph>
      {isTakeAway &&
        <Paragraph2 className="paragraph">{languageContext[lanIndex].yourOrderNo}<br />
          {takeAwayId}
        </Paragraph2>
      }
      {isTakeAway &&
        <Paragraph3 className="paragraph">
          {languageContext[lanIndex].totalAmount}: {totalAmount ? totalAmount.toFixed(2) : 0}
        </Paragraph3>
      }
     
      <OkButtonContainer width={width}>
        <Link to="/menu">
          <OkButton width={width} themeContext={themeContext}>
            {languageContext[lanIndex].returnCK}
          </OkButton>
        </Link>
      </OkButtonContainer>

    </BackgroundColor>
  );


}

export default Fail;

const Header = styled.div`
    color: #fcba03;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 32px;
    margin-top: 40px;
    text-align: center;
`;

const Paragraph = styled.div`
color: #404F5E;
font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
font-size:15px;
margin-top: 40px;
text-align: center;
`;
const Paragraph2 = styled.div`
color: #404F5E;
font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
font-size:25px;
margin-top: 20px;
font-weight: 700;
text-align: center;
`;
const Paragraph3 = styled.div`
color: #404F5E;
font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
font-size: 18px;
margin-top: 20px;
font-weight: 700;
text-align: center;
`;
const CheckIcon = styled.div`
    color: #fcba03;
    font-family: "Arial", sans-serif;
    font-size: 100px;
    line-height: 200px;
    text-align: center;
`;

const Circle = styled.div`
    border-radius:200px; 
    height:200px; 
    width:200px; 
    background: #F8FAF5; 
    margin:auto;
    margin-top: 100px;
`;

const BackgroundColor = styled.div`

  height: 100vh;
  width: 100vw;
  max-width: 550px;
  background-color: ${(props) => props.themeContext.backgroundColor};
  overflow-y: scroll;
  z-index: -10;
`;

const OkButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  position: absolute;
  bottom: 0rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

const OkButton = styled.div`
  display: flex;
  position: fixed;
  bottom: 5px;
  text-align: center;
  color: ${(props) => props.themeContext.cartFont};
  font-family: ${(props) => props.themeContext.fontFamily};
  font-size: 20px;
  background-color: ${(props) =>
    props.themeContext.fontPrimary
  };
  justify-content: center;
  align-items: center;
  height: 40px;
  max-width:500px;
  width: 85%;
  margin-left: 25px;
  border: 1px solid darkgrey;
  border-radius: 7px;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 70%);
  
`;
