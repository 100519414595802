import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../../contexts/Context";
import axios from "axios";
import { IoCloseCircle } from "react-icons/io5";

const CustomerPdpa = ({ show, setShow, setError, setShowError }) => {
    const [displayShow, setDisplay] = useState(show ? "grid" : "none");

    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { lanIndex, token, userId } = React.useContext(UserContext);

    const [pdpa, setPdpa] = useState("");
    console.log({ show });
    useEffect(() => {
        setDisplay(show ? "flex" : "none");
        if (token && userId && show) {
            axios.get(`/v2/api/customer/pdpa`, {
                headers: { Authorization: "Bearer " + token },
            })
                .then(function (response) {
                    console.log(response.status);
                    if (response.status == 200) {
                        setPdpa(response.data.pdpa);
                        document.getElementById("cusPdpa").innerHTML = response.data.pdpa;
                   
                    } 

                })
                .catch(function (response) {
                    //show warning
                    console.log("RETURN catch");
                    setError([{ errorMessage: "Something went wrong. Please try again" }]);
                    setShowError(true);
                })

            
        }
    }, [show]);

    return (
        <React.Fragment>
            <ModalContainer display={displayShow}>
                <IconButton onClick={() => {
                    setShow(false);
                    setDisplay(false);
                }}>
                    <IoCloseCircle size={36} />
                </IconButton>
                <Title>
                    PDPA Policy
                </Title>
                <ContentWrapper>

                    <InputWrapper>
                        <ModalContentText
                            fontWeight={400}
                            color={"black"}
                            id="cusPdpa"
                        >

                        </ModalContentText>
                    </InputWrapper>
                    <ButtonWrapper>
                        <Button themeContext={themeContext} onClick={() => {
                            setShow(false)
                        }} >
                            {languageContext[lanIndex].ok}
                        </Button>
                        
                    </ButtonWrapper>
                </ContentWrapper>
            </ModalContainer>
        </React.Fragment >
    );
}

export default CustomerPdpa;
const ButtonWrapper = styled.div`
    display: flex;
`;
const Button = styled.div`
    display: flex;
    width: 120px;
    margin: 8px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    border-radius: 25px;
    line-height: 40px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
   
`;

const InputWrapper = styled.pre`
   
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
`;


const IconButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  background: white;
  z-index: 99;
`;
const ModalContentText = styled.div`
    padding: 8px;
    white-space: pre-wrap;
`;
const Title = styled.div`
    font-size: 18px;
    font-weight: 500;
    margin-top: 24px;
    margin-left: 8px;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    overflow-y: auto;
    margin-top: 24px;
`;

const ModalContainer = styled.div`
    display: ${(props) => props.display};
    position: fixed;
    flex-direction: column;
    background: #fff;
    top: 0;

    width: 100vw;
    max-width: 550px;
    height: 100dvh;
    z-index: 9;

    animation: modalOrder .1s;
    animation-timing-function: ease-out;
    @keyframes modalOrder {
        0%  {
        transform: translateY(350px);
        opacity: 0;
        }
        
        100%  {
        transform: translateY(0px);
        opacity: 1;
        }
        
    }
  `;

