import React, { useEffect, useRef, useState } from "react";
import { VariableSizeList as List } from "react-window";
import "../styles/styles.css";
import styled from "@emotion/styled";
import { ThemeContext, UserContext, ActionContext } from "../contexts/Context";
import { navigate } from '@reach/router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const MenuSideBar = ({
  height,
  itemList,
  setSelectedCategory,
  selectedCategory,
  category,
  setSelectedMenu,
  otherUrls,
  unlimitedScroll,
  selectedMenu,
  setLastPos
}) => {
  const [newList, setList] = useState([]);
  const [itemLen, setLen] = useState(0);
  useEffect(() => {
    //Stuff for List
    let newList = JSON.parse(JSON.stringify(itemList));

    newList.push({ category: "" });
    newList.push({ category: "_________" });

    if (otherUrls && otherUrls.length > 0) {
      otherUrls.forEach((ol) => {
        newList.push({ category: ol.title });
      });
    }
    newList = newList.concat({ category: "ABOUT US" });

    setList(newList);

    let itemLen = itemList.length;
    setLen(itemLen);
  }, [itemList]);


  const listRef = useRef(null);

  var rowHeights = newList.map((_, index) => {
    return 50;
  });
  const getItemSize = (index) => rowHeights[index];

  //Context
  const { company, lanIndex, isLanReverse } = React.useContext(UserContext);
  const themeContext = React.useContext(ThemeContext);
  const { setScrolling } = React.useContext(ActionContext);

  const scrollToView = (element) => {
    //get view position
    if (element == null) return;
    let parentElement = document.getElementById("menu_scroll");
    let parentTop = 0;
    if (parentElement != null) {
      parentTop = parentElement.getBoundingClientRect().top;
    }
    let posTarget = element.getBoundingClientRect().top - parentTop + 20;
    // let margin = posTarget / 1000 * 60;
    // let i = 0;
    setScrolling(true);
    document.getElementById("menu_scroll").scrollBy({ top: posTarget });

    // function step() {
    //   document.getElementById("root").scrollBy({ top: margin });
    //   posTarget -= margin;
    //   if (i < 17 && Math.abs(posTarget) > 50) { //acceptable
    //     i++;
    //     window.requestAnimationFrame(step);
    //   } else {

    //     let offset = element.getBoundingClientRect().top - 20 - parentTop;
    //     alert("PAS TO LAST STEP " + offset +" ORIGINAL "+ posTarget + " margin "+ margin +" parent "+ parentTop);
    //     document.getElementById("root").scrollBy({ top: offset });
    window.setTimeout(() => {
      setScrolling(false);
    }, 500);

    //   }
    // }
    // window.requestAnimationFrame(step);



  }

  useEffect(() => {
    if (unlimitedScroll) {
      window.setTimeout(() => {
        scrollToView(document.getElementById(category));
      }, 50);
    }
  }, []);

  useEffect(() => {
    if (unlimitedScroll && selectedCategory) {
      let selectedIndex = 0;
      newList.forEach((nl, i) => {
        let mCat = nl.category;
        mCat = mCat.replaceAll("/", "").replaceAll("%", "");
        if (mCat == selectedCategory.category) {
          selectedIndex = i;
        }
      });

      if (newList.length > 0) {
        window.setTimeout(() => {
          if (listRef.current != null)
            listRef.current.scrollToItem(selectedIndex, 'auto');
        }, 50);

      }
    } else if (category) {
      let selectedIndex = 0;
      newList.forEach((nl, i) => {
        let mCat = nl.category;
        mCat = mCat.replaceAll("/", "").replaceAll("%", "");
        if (mCat == category) {
          selectedIndex = i;
        }
      });

      if (newList.length > 0) {
        window.setTimeout(() => {
          if (listRef.current != null)
            listRef.current.scrollToItem(selectedIndex, 'auto');
        }, 50);

      }
    }

  }, [newList, category, selectedCategory])



  return (
    <SideBarContainer themeContext={themeContext}>
      <TransitionGroup>
        <List
          ref={listRef}
          // onScroll={scrollCheck}
          className="no-scrollbars"
          //Height of Header(80) + Title(40) + NavBar(40) = 160
          height={height - themeContext.headerHeight - 50}
          itemCount={newList.length}
          itemSize={getItemSize}
          width={themeContext.sidebarWidth}
          itemData={{
            setSelectedCategory: setSelectedCategory,
            setSelectedMenu: setSelectedMenu,
            selectedCategory: selectedCategory ? selectedCategory.category : "",
            newList: newList,
            otherUrls: otherUrls,
            themeContext: themeContext,
            url: company.url,
            itemLen,
            lanIndex,
            isLanReverse,
            unlimitedScroll,
            scrollToView,
            selectedMenu,
            setScrolling,
            setLastPos
          }}
          overscanCount={200}
        >

          {Row}

        </List>
      </TransitionGroup>
    </SideBarContainer>
  );
};
export default React.memo(MenuSideBar);

const Row = ({ index, data, style }) => {
  //Data passed as props to List Component

  const {
    newList,
    setSelectedCategory,
    setSelectedMenu,
    selectedCategory,
    themeContext,
    url,
    itemLen,
    otherUrls,
    lanIndex,
    isLanReverse,
    unlimitedScroll,
    scrollToView,
    setLastPos
  } = data;

  let category = (lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? newList[index].category : newList[index].category2 ? newList[index].category2 : newList[index].category;
  if (newList[index].isSub) {
    category = "* " + category + " *";
  }
  return (
    <React.Fragment>
      <CSSTransition
        key={category}
        in={true}
        timeout={250}
        classNames="cat"
      // unmountOnExit
      >
        {selectedCategory == newList[index].category ?
          <CategorySelected
            style={style}
            themeContext={themeContext}
            textLength={newList[index].category.length}
            finalItem={newList.length}
            isSub={newList[index].isSub}
            index={index}
            onClick={() => {
              window.history.back();
            }}
          >
            {category}
          </CategorySelected>
          :
          <CategoryContainer
            style={style}
            themeContext={themeContext}
            isSub={newList[index].isSub}
            active={newList[index].active}
            textLength={newList[index].category.length}
            onClick={() => {
              if (index === newList.length - 1) {
                //Final Item of the list should be About Us
                //Go to company link
                window.open(url, "_blank");
              } else if (index >= itemLen) {
                if (!otherUrls) return;
                let ou = otherUrls.find((v) => v.title == newList[index].category);

                if (ou) {
                  window.open(ou.url, "_blank");
                }
              } else {

                setSelectedMenu(null);
                setSelectedCategory(newList[index]);

                let category = newList[index].category;
                if (unlimitedScroll) {
                  setLastPos(0);
                  scrollToView(document.getElementById(category));
                }
                category = category.replaceAll("/", "");
                category = category.replaceAll("%", "");
                if (!unlimitedScroll)
                  navigate('/menu/menu/' + category);
                else
                  navigate('/menu');

              }
            }}
            finalItem={newList.length}
            index={index}
          >
            {category}
          </CategoryContainer>
        }
      </CSSTransition>
    </React.Fragment >
  );
};

//Styled Component
const CategorySelected = styled.div`
  display: grid;
  height: 50px;
  color: ${(props) => props.themeContext.selectedCategory};
  font-size: ${(props) => {
    if (props.isSub) {
      return "9px";
    } else if (props.themeContext.sidebarFixedSize === true) {
      return "11px";
    } else if (props.textLength > 12) {
      return "11px";
    } else {
      if (props.textLength > 8) {
        return "11px";
      } else {
        return "11px";
      }
    }
  }};
  font-family: ${(props) => props.themeContext.fontFamily};
  font-weight: 700;
  text-align: center;
  place-content: center;
  
  min-width: ${(props) => props.themeContext.sidebarWidth - 2}px;
  z-index: 2;
`;
const CategoryContainer = styled.div`
  display: grid;
  height: 50px;
  font-size: ${(props) => {
    if (props.isSub) {
      return "9px";
    } else if (props.themeContext.sidebarFixedSize === true) {
      return "11px";
    } else if (props.textLength > 12) {
      return "11px";
    } else {
      if (props.textLength > 8) {
        return "11px";
      } else {
        return "11px";
      }
    }
  }};
  
  font-family: ${(props) => props.themeContext.fontFamily};
  font-weight: 500;
  text-align: center;
  place-content: center;
  color: ${(props) => props.themeContext.unselectedCategory};
  min-width: ${(props) => props.themeContext.sidebarWidth - 2}px;
  z-index: 2;
  padding-bottom: ${(props) => {
    if (props.index === props.finalItem - 1) {
      return "300px";
    } else {
      return "0px";
    }
  }};
 `;

const SideBarContainer = styled.div`
  position: absolute;
  top: ${(props) => props.themeContext.headerHeight}px;
  
  bottom: 0rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-width: ${(props) => props.themeContext.sidebarWidth}px;
  border-right: 1px solid #ddd;
  z-index: 2;
  background-color: ${(props) => props.themeContext.secondaryColor};

`;
