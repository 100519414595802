import React, { useEffect, useRef, useState } from "react";
import "../styles/styles.css";
import styled from "@emotion/styled";
import { ThemeContext, UserContext, ActionContext } from "../contexts/Context";
import { navigate } from '@reach/router';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";

const MenuTopBar = ({
  width,
  itemList,
  setSelectedCategory,
  selectedCategory,
  category,
  setSelectedMenu,
  unlimitedScroll,
  selectedMenu,
  setLastPos,
  subCatObj,
  shouldExpand
}) => {
  //Stuff for List
  const [newList, setList] = useState([]);
  const [itemLen, setLen] = useState(0);
  useEffect(() => {
    //Stuff for List
    let newList = JSON.parse(JSON.stringify(itemList));
    setList(newList);
    let itemLen = itemList.length;
    setLen(itemLen);
  }, [itemList]);


  const [selectedIndex, setIndex] = useState(0);
  const [selectedSubIndex, setSubIndex] = useState(0);

  //Context
  const { company, lanIndex, isLanReverse } = React.useContext(UserContext);
  const themeContext = React.useContext(ThemeContext);
  const { setScrolling } = React.useContext(ActionContext);

  const scrollToView = (element, isSub) => {
    //get view position
    if (element == null) return;
    let parentTop = 0;
    let parentElement = document.getElementById("menu_scroll");
    if (parentElement != null) {
      parentTop = parentElement.getBoundingClientRect().top;
    }
    let posTarget = element.getBoundingClientRect().top - (isSub ? 20 : -20) - parentTop;
    setScrolling(true);
    document.getElementById("menu_scroll").scrollBy({ top: posTarget });
    // let margin = posTarget / 1000 * 60;
    // let i = 0;

    // function step() {
    //   document.getElementById("root").scrollBy({ top: margin });
    //   posTarget -= margin;
    //   if (i < 17 && Math.abs(posTarget) > 70) { //acceptable
    //     i++;
    //     window.requestAnimationFrame(step);
    //   } else {

    //     let offset = element.getBoundingClientRect().top - (isSub ? 110 : 70) - parentTop;
    //     document.getElementById("root").scrollBy({ top: offset });
    window.setTimeout(() => {
      setScrolling(false);
    }, 500);

    //   }
    // }
    // window.requestAnimationFrame(step);



  }
  useEffect(() => {
    if (unlimitedScroll) {
      window.setTimeout(() => {
        console.log(category);
        scrollToView(document.getElementById(category));
      }, 50);
    }
  }, []);

  useEffect(() => {
    setIndex(0);
    setSubIndex(0);
    let selectI = -1;
    let selectJ = -1;
    newList.forEach((nl, i) => {
      let mCat = nl.category;

      mCat = mCat.replaceAll("/", "").replaceAll("%", "");
      if (unlimitedScroll && selectedCategory) {
        if (mCat == selectedCategory.category) {
          setIndex(i);
          selectI = i;
        }
      } else if (category) {
        if (mCat == category) {
          setIndex(i);
          selectI = i;
        }
      }

      //searching for subcategory
      if (subCatObj[nl.category]) {
        let j = 0;
        for (let sc of subCatObj[nl.category]) {
          let mCat = sc.category;

          mCat = mCat.replaceAll("/", "").replaceAll("%", "");
          if (unlimitedScroll && selectedCategory) {
            if (mCat == selectedCategory.category) {
              setIndex(i);
              setSubIndex(j);
              selectI = i;
              selectJ = j;
            }
          } else if (category) {
            if (mCat == category) {
              setIndex(i);
              setSubIndex(j);
              selectI = i;
              selectJ = j;
            }
          }
          j++;
        }
      }
    });

    // if (selectedIndex == -1) return;
    if (newList.length > 0) {
      window.setTimeout(() => {
        //to revise
        if (document.getElementById("CAT" + selectI) == null) {
          return;
        }
        let left = document.getElementById("CAT" + selectI).getBoundingClientRect().left;
        document.getElementById("cat-scrollbars").scrollBy({ left: left - 24 });
        if (selectJ != -1) {
          let sLeft = document.getElementById("SCAT" + selectJ).getBoundingClientRect().left;
          document.getElementById('scat-scrollbars').scrollBy({ left: sLeft - 24 });
        }

      }, 50);

    }

  }, [newList, category, selectedCategory]);

  const handleDeccrease = () => {
    if (selectedIndex <= 0) return;
    setSelectedCategory(newList[selectedIndex - 1]);
    setSelectedMenu(null);

    let category = newList[selectedIndex - 1].category;
    if (unlimitedScroll) {
      setLastPos(0);
      scrollToView(document.getElementById(category), false);
      //scroll sub category
    }
    category = category.replaceAll("/", "");
    category = category.replaceAll("%", "");
    if (!unlimitedScroll)
      navigate('/menu/menu/' + category);
    else
      navigate('/menu');
  }
  const handleIncrease = () => {
    if (selectedIndex >= itemList.length - 1) return;
    setSelectedCategory(newList[selectedIndex + 1]);
    setSelectedMenu(null);

    let category = newList[selectedIndex + 1].category;
    if (unlimitedScroll) {
      setLastPos(0);
      scrollToView(document.getElementById(category), false);
    }
    category = category.replaceAll("/", "");
    category = category.replaceAll("%", "");
    if (!unlimitedScroll)
      navigate('/menu/menu/' + category);
    else
      navigate('/menu');
  }

  return (

    <React.Fragment>
      {!shouldExpand ?
        <div></div>
        :
        <React.Fragment>
          <SideBarContainer themeContext={themeContext} width={width}>
            <IconButton onClick={() => handleDeccrease()}>
              <IoChevronBackOutline size={24} color="white" />
            </IconButton>
            <List
              id="cat-scrollbars"
              height={50}
              itemCount={newList.length}
              width={width}
            >
              {newList && newList.map((item, i) =>
                <Row
                  index={i}
                  data={{
                    setSelectedCategory: setSelectedCategory,
                    setSelectedMenu: setSelectedMenu,
                    selectedCategory: selectedCategory ? selectedCategory.category : "",
                    newList: newList,
                    themeContext: themeContext,
                    itemLen,
                    lanIndex,
                    isLanReverse,
                    unlimitedScroll,
                    scrollToView,
                    selectedMenu,
                    setScrolling,
                    setLastPos,
                    selectedIndex
                  }}
                />
              )}
            </List>
            <IconButton onClick={() => handleIncrease()}>
              <IoChevronForwardOutline size={24} color="white" />
            </IconButton>
          </SideBarContainer>
          {(selectedIndex != -1 && newList.length > selectedIndex && subCatObj[newList[selectedIndex].category]) &&
            <SubCatContainer themeContext={themeContext} id="scat-scrollbars">
              {subCatObj[newList[selectedIndex].category].map((item, j) =>
                <Row
                  index={j}
                  data={{
                    setSelectedCategory: setSelectedCategory,
                    setSelectedMenu: setSelectedMenu,
                    selectedCategory: selectedCategory ? selectedCategory.category : "",
                    newList: subCatObj[newList[selectedIndex].category],
                    themeContext: themeContext,
                    itemLen,
                    lanIndex,
                    unlimitedScroll,
                    scrollToView,
                    selectedMenu,
                    setScrolling,
                    setLastPos,
                    selectedIndex: selectedSubIndex,
                    isSub: true,
                  }}
                />
              )}
            </SubCatContainer>
          }
        </React.Fragment>
      }
    </React.Fragment>
  );
};
export default React.memo(MenuTopBar);

const SubCatContainer = styled.ul`
  position: fixed;
  
  top: ${props => props.themeContext.headerHeight + 50}px;
  display:flex;
  flex-grow:1;
  overflow-x: auto;
  height: 32px;
  width: 100%;
  max-width:550px;
  font-size: 12px;
  margin: 0px;
  padding-left: 0px;
  background-color: ${(props) => props.themeContext.topBarSubBg};
  z-index: 7;
  align-items: center;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
const List = styled.ul`
  display: flex;
  flex-grow:1;
  overflow-x: auto;
 
  margin: 0px;
  padding-left: 0px;
  align-items: center;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
const IconButton = styled.div`
`;

const Row = ({ index, data }) => {
  //Data passed as props to List Component

  const {
    newList,
    setSelectedCategory,
    setSelectedMenu,
    themeContext,
    lanIndex,
    isLanReverse,
    unlimitedScroll,
    scrollToView,
    setLastPos,
    selectedIndex,
    isSub,
    catImg
  } = data;

  let category = (lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? newList[index].category : newList[index].category2 ? newList[index].category2 : newList[index].category;
  let isSelected = index == selectedIndex;

  return (
    <React.Fragment>
      {isSelected ?
        <CategorySelected
          themeContext={themeContext}
          finalItem={newList.length}
          isSub={isSub}
          index={index}
          id={(isSub ? "SCAT" : "CAT") + index}
        >
          {!isSub && newList[index].catImg &&
            <CategoryImg catImg={newList[index].catImg} />
          }
          {category}
        </CategorySelected>
        :
        <CategoryContainer
          id={(isSub ? "SCAT" : "CAT") + index}
          themeContext={themeContext}
          isSub={isSub}
          onClick={() => {
            setSelectedMenu(null);
            setSelectedCategory(newList[index]);

            let category = newList[index].category;
            if (unlimitedScroll) {
              setLastPos(0);
              scrollToView(document.getElementById(category), isSub);
            }
            category = category.replaceAll("/", "");
            category = category.replaceAll("%", "");
            if (!unlimitedScroll)
              navigate('/menu/menu/' + category);
            else
              navigate('/menu');
          }}
          finalItem={newList.length}
          index={index}
        >
          {!isSub && newList[index].catImg &&
            <CategoryImg catImg={newList[index].catImg} />
          }
          {category}
        </CategoryContainer>
      }
    </React.Fragment >
  );
};

//Styled Component
const CategoryImg = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 18px;
  margin-right: 4px;
  //Image based on image url in item given
  background-image: url(${(props) => `https://ireachfnb.com${props["catImg"]}`});
  /*background-image: url(${(props) => `https://newdemo.chachingfnb.com${props["catImg"]}`});*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
const CategorySelected = styled.li`
  position: relative;
  display: flex;
  font-size: ${props => props.isSub ? 12 : 14}px;
  font-family: ${(props) => props.themeContext.fontFamily};
  color: ${(props) => props.themeContext.cartFont};
  align-items: center;
  white-space: nowrap;
  padding: 0 16px;
  height: ${(props) => !props.isSub ? 36: 30}px;
  background-color: ${(props) => props.isSub ? props.themeContext.selectedSubCatBg: props.themeContext.selectedCatBg};
  border-radius: 30px;
  /*&:before {
    position: absolute;
    height: 3px;
    left:0px;
    bottom:0px;
    width:100%;
    content:"";
    background: ${props => props.themeContext.unselectedCategory};
    transition: all 0.2s linear;
  }*/
`;
const CategoryContainer = styled.li`
  display: flex;
  font-size: ${props => props.isSub ? 12 : 14}px;
  align-items: center;
  padding: 0 16px;
  font-family: ${(props) => props.themeContext.fontFamily};
  font-weight: 500;
  color: ${(props) => props.themeContext.unselectedCategory};
  white-space: nowrap;
  height: ${(props) => !props.isSub ? 36: 30}px;
 `;

const SideBarContainer = styled.div`
  position: fixed;
  width: 100vw;
  max-width: 550px;
  top: ${(props) => props.themeContext.headerHeight}px;
  
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props) => props.themeContext.secondaryColor};
  height:50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #9E9E9E;
  z-index: 7;
 
`;
