import React, { useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import "../styles/styles.css";
import MenuItem from "../newcomponents/optioncomponents/MenuItem";
import { VariableSizeGrid as Grid } from "react-window";
import { ThemeContext, UserContext } from "../contexts/Context";
import { navigate } from '@reach/router';
import { SwitchTransition, CSSTransition } from "react-transition-group";


const Buffer = styled.div`
  display: block;
  height: ${(props) => props.height}px;
`;

const MenuItemSelectionGrid = ({
  height,
  width,
  selectedCategory,
  setSelectedMenu,
  setSelectedCategory,
  theme,
  selectedItems,
  addItemDirect,
  cart,
  removeFromCart,
  sidebarWidth,
  headerHeight,
  cp,
  menuSelectionFormRef,
  promos
}) => {
  const themeContext = React.useContext(ThemeContext);
  const [heightList, setHeightList] = useState([]);

  //Clone of Array for Form Submission

  const ref = React.useRef();


  const updateListDimension = () => {
    
    let menuWidth = (Math.min(width, 550) - sidebarWidth);
    let list = [];
    selectedCategory.type.forEach((lt, index) => {
      if (!cp) {
        if (lt.size == 0 || !lt.size) {
          if (!list[parseInt(index / 2)])
            list[parseInt(index / 2)] = (menuWidth / 6 * 3 + 60);
        } else if (lt.size == 1) {
          list[parseInt(index / 2)] = (menuWidth / 3 * 3 + 60);
        } else if (lt.size == 2) {
          list[parseInt(index / 2)] = (80 + 16);
        } else if (lt.size == 3) {
          list[parseInt(index / 2)] = (120 + 16);
        }
      } else {
        if (lt.size == 0 || !lt.size) {
          if (!list[parseInt(index / 2)])
            list[parseInt(index / 2)] = (menuWidth / 6 * 3 + 60);
        } else if (lt.size == 1) {
          list[parseInt(index / 2)] = (menuWidth / 3 * 3 + 60);
        } else if (lt.size == 2) {
          list[parseInt(index / 2)] = (80 + 16);
        } else if (lt.size == 3) {
          list[parseInt(index / 2)] = (120 + 16);
        }
      }

    });
    setHeightList(list);

    if (ref.current) {
      ref.current.resetAfterIndices({
        columnIndex: 0,
        rowIndex: 0,
        shouldForceUpdate: true
      });
    }
  }

  useEffect(() => {
    updateListDimension();
  }, [selectedCategory.type]);

  //List Required Constants

  return (

    <SwitchTransition>
      <CSSTransition
        key={selectedCategory.category}
        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
        classNames='fade'
      >
        <Grid
          ref={ref}
          className="no-scrollbars"
          columnCount={selectedCategory.type.length > 1 ? 2 : 1}
          columnWidth={index => (Math.min(width, 550) - sidebarWidth) / 2}
          height={height - headerHeight}
          rowCount={Math.ceil(selectedCategory.type.length / 2)}
          rowHeight={index => {
            return heightList[index] ? heightList[index] : 160 + 16
          }}
          width={width - sidebarWidth}
          itemData={{
            item: selectedCategory.type,
            setSelectedMenu: setSelectedMenu,
            setSelectedCategory: setSelectedCategory,
            theme: theme,
            selectedItems,
            addItemDirect,
            cart,
            removeFromCart,
            cp,
            menuSelectionFormRef,
            promos
          }}
        >
          {Cell}
        </Grid>
      </CSSTransition>
    </SwitchTransition>
  );
};
export default React.memo(MenuItemSelectionGrid);

const Cell = ({ columnIndex, rowIndex, data, style }) => {
  //Data passed as props to List Component
  const { item, setSelectedMenu, setSelectedCategory, theme, selectedItems, addItemDirect,
    cart, removeFromCart, cp, menuSelectionFormRef, promos } = data;
  const { lanIndex, currencySymbol, isLanReverse } = React.useContext(UserContext);
  const handleSelect = () => {
    let it = item[columnIndex + rowIndex * 2];
    if (!it.isCategory) {
      //To Determine if has options
      //selectedMenu.optionArray 
      if (addItemDirect && (!it.optionArray || it.optionArray.length == 0)) {
        it.qty = 1;
        setSelectedMenu(it);
        menuSelectionFormRef.current = JSON.parse(JSON.stringify(it));

      } else {
        setSelectedMenu(it);
        menuSelectionFormRef.current = JSON.parse(JSON.stringify(it));
        navigate('/menu/item/' + it.codeId);
      }
    } else {
      setSelectedCategory(it);
      navigate('/menu/menu/' + it.category);
    }
  };


  const minusQty = (e) => {
    e.stopPropagation();
    let it = item[columnIndex + rowIndex * 2];
    //remove the latest one
    let newCart = JSON.parse(JSON.stringify(cart));
    for (let i = newCart.length - 1; i >= 0; i--) {
      if (newCart[i].codeId == it.codeId) {
        newCart[i].qty--;
        let minQty = newCart[i].minQty ? newCart[i].minQty - 1 : 0;
        if (newCart[i].qty <= minQty) {
          newCart.splice(i, 1);
        }
        localStorage.setItem("order", JSON.stringify(newCart));
        removeFromCart(newCart, i);
        break;
      }
    }

  }

  return (
    <div style={{ ...style, display: (columnIndex + rowIndex * 2 < item.length && item[columnIndex + rowIndex * 2].hideDisplay ? "none" : "") }}>
      {columnIndex + rowIndex * 2 < item.length ? (
        <MenuItem
          key={"" + columnIndex + (rowIndex * 2)} /* ??? */
          item={item[columnIndex + rowIndex * 2]}
          containerWidth={style.width - 24}
          handleOnClick={handleSelect}
          addQty={handleSelect}
          minusQty={minusQty}
          theme={theme}
          lanIndex={lanIndex}
          isLanReverse={isLanReverse}
          selectedItems={selectedItems}
          currencySymbol={currencySymbol}
          addItemDirect={addItemDirect}
          cp={cp}
          promos={promos}
        />
      ) : (
        <React.Fragment />
      )}

      <Buffer height={250} />
    </div>
  );
};
