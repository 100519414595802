import React, { useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../contexts/Context";
import axios from "axios";
import { navigate } from "@reach/router"
import Modal from '../newcomponents/optioncomponents/Modal';
import CusPdpa from '../newcomponents/customerComponents/CustomerPdpa';
import CusRegister from "../newcomponents/customerComponents/CustomerRegister2";
import CusPass from '../newcomponents/customerComponents/CustomerPassword';

const CustomerLogin = ({ token }) => {

    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { registerUserData, addCustomer } = React.useContext(ActionContext);
    const { lanIndex, userId, company, landingImg } = React.useContext(UserContext);
    const [phone, setPhone] = useState("");
    const [warningMsg, setWarningMsg] = useState("");
    const [error, setError] = useState([]);
    const [showError, setShowError] = useState(false);
    const [showPdpa, setShowPdpa] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [processing, setProcessing] = useState(false);


    useEffect(() => {
        let currentDate = new Date().getTime();
        const makeid = (length) => {
            var result = [];
            var characters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result.push(
                    characters.charAt(Math.floor(Math.random() * charactersLength))
                );
            }
            return result.join("");
        };

        const generateUserId = () => {
            let currentDate = new Date().getTime();
            return makeid(20) + currentDate;
        };
        console.log("Identify User");
        registerUserData(token, generateUserId(), currentDate);

    }, [registerUserData, token]);

    const submitPassword = (password) => {
        console.log("PASS HERE");
        if (password == "") {
            setError([{ errorMessage: "Password cannot be empty" }]);
            return;
        }

        if (processing) return;
        setProcessing(true);
        if (token) {
            axios.post(`/v2/api/customer/login`,
                {
                    phone: phone,
                    password: password
                },
                { headers: { Authorization: "Bearer " + token } })
                .then(function (response) {
                    setProcessing(false);
                    if (response.status == 200) {
                        addCustomer(response.data);
                        navigate('/member/info');
                    }

                })
                .catch(function (error) {
                    //show warning
                    setProcessing(false);
                    if (error.response && error.response.status == 401) {
                        setError([{ errorMessage: "Invalid phone no or password" }]);
                        setShowError(true);
                    } else {
                        setError([{ errorMessage: "Something went wrong. Please try again" }]);
                        setShowError(true);
                    }
                })


        }

    }

    const submitRegister = (customer) => {
        if (processing) return;
        if (token && userId) {
            setProcessing(true);
            axios.post(`/v2/api/customer`,
                customer,
                {
                    headers: { Authorization: "Bearer " + token },
                })
                .then(function (response) {
                    setProcessing(false);
                    if (response.status == 200) {
                        setShowRegister(false);
                        addCustomer(response.data.customer);
                        navigate('/member/info');
                    }

                })
                .catch(function (error) {
                    //show warning
                    setProcessing(false);
                    if (error.response && error.response.status == 402) {
                        console.log("RETURN catch");
                        setError([{ errorMessage: "Phone No has been used" }]);
                        setShowError(true);
                    } else {
                        console.log("RETURN catch");
                        setError([{ errorMessage: "Something went wrong. Please try again" }]);
                        setShowError(true);
                    }
                })
        }
    };

    const memberSubmit = () => {
        if (phone == "") {
            setWarningMsg("Phone No is required");
            return;

        }
        if (processing) return;
        setProcessing(true);
        if (token && userId) {
            axios.post(`/v2/api/customer/check`,
                {
                    phone: phone
                },
                {
                    headers: { Authorization: "Bearer " + token },
                })
                .then(function (response) {
                    setProcessing(false);
                    console.log(response.status);
                    if (response.status == 200) {
                        //check if user already register
                        if (response.data.status == 0) {//not register yet
                            setWarningMsg("Incorrect phone no");
                        } else if (response.data.status == 1) {
                            addCustomer(response.data.customer);
                            register();
                        } else if (response.data.status == 2) { //has data, need password
                            setShowPass(true);
                        } else { //go direct to member page
                            addCustomer(response.data.customer);
                            navigate('/member/info');
                        }

                    }

                })
                .catch(function (error) {
                    //show warning
                    console.log(error);
                    setProcessing(false);
                    if (error.response && error.response.status == 401) {
                        setError([{ errorMessage: "Invalid phone no or password" }]);
                        setShowError(true);
                    } else {
                        setError([{ errorMessage: "Something went wrong. Please try again" }]);
                        setShowError(true);
                    }
                })


        }
    }



    const register = (e) => {
        setShowRegister(true);
    }
    const handleChange = (e, name) => {
        if (name == 'phone') {
            setPhone(e);
        }
        setWarningMsg("");
    }

    console.log({ languageContext, lanIndex });
    return (
        //phone, name, email, dob, gender password
        <React.Fragment>
            <ModalContainer themeContext={themeContext} >
                <ContentWrapper>
                    <ImgWrapper image={landingImg || company.companyLogo} />
                    <WarningMsg themeContext={themeContext}>
                        {warningMsg}
                    </WarningMsg>
                    <InputWrapper>
                        <ModalContentText
                            fontWeight={400}
                            color={"black"}
                        >
                            {languageContext[lanIndex].phone}
                        </ModalContentText>
                        <PhoneWrapper>
                            <Input width={100} value={phone} onChange={(e) => { handleChange(e.target.value, 'phone') }} placeholder="e.g. 91231111" />
                        </PhoneWrapper>
                    </InputWrapper>

                    <InputWrapper>
                        <LoginButton themeContext={themeContext} onClick={memberSubmit} >
                            {languageContext[lanIndex].login}
                        </LoginButton>
                    </InputWrapper>

                    <InputWrapper style={{ flexDirection: "row", alignItems: 'center' }}>
                        <FgButton onClick={register}>
                            {languageContext[lanIndex].register}
                        </FgButton>
                    </InputWrapper>
                </ContentWrapper>

            </ModalContainer>
            <Modal show={showError} setShow={setShowError} error={error} />
            <CusRegister
                show={showRegister}
                setShow={setShowRegister}
                setShowError={setShowError}
                setError={setError}
                onSubmit={submitRegister}
                setShowPdpa={setShowPdpa} />
            <CusPdpa
                show={showPdpa}
                setShow={setShowPdpa}
                setShowError={setShowError}
                setError={setError}
            />
            <CusPass
                show={showPass}
                setShow={setShowPass}
                setShowError={setShowError}
                setError={setError}
                handleCallback={submitPassword}
            />
        </React.Fragment >
    );
}

export default CustomerLogin;

const WarningMsg = styled.div`
    color: ${(props) => props.themeContext.fontAlert};
`;

const LoginButton = styled.div`
    display: flex;
    width: 60%;
    margin: 8px auto;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    border-radius: 25px;
    line-height: 40px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
   
`;

const FgButton = styled.div`
    margin-top: 16px;
    font-size: 12px;
    text-decoration: underline;
`;
const Divider = styled.div`
    margin-top: 16px;
    margin-left: 8px;
    margin-right: 8px;
`;
const ImgWrapper = styled.div`
    width: 30%;
    height: 20%;
    margin-top: 10%;
    background-image: url(${(props) => `https://ireachfnb.com${props.image}` || '/toppings/toppings.jpg'});
    /*background-image: url(${(props) => `https://newdemo.chachingfnb.com${props.image}` || '/toppings/toppings.jpg'});*/

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;
const PhoneWrapper = styled.div`
    display: flex;
    width: 80%;
`;
const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Input = styled.input`
  height: 30px;
  border-radius: 4px;
  padding-left: 16px;
  font-size: 16px;
  border: 1px solid #aaa;
  margin-bottom: 10px;
  width: ${(props) => props.width}%;
`;

const IconButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  background: white;
  z-index: 99;
`;
const ModalContentText = styled.div`
    width: 80%;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    overflow: auto;
`;

const ModalContainer = styled.div`
    display: flex;
    background-color:${(props) => props.themeContext.backgroundColor};
    flex-direction: column;
    background: #fff;
    top: 0;

    width: 100vw;
    max-width: 550px;
    height: 100dvh;
    z-index: 8;

    animation: modalOrder .1s;
    animation-timing-function: ease-out;
    @keyframes modalOrder {
        0%  {
        transform: translateY(350px);
        opacity: 0;
        }
        
        100%  {
        transform: translateY(0px);
        opacity: 1;
        }
        
    }
  `;

