import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext } from "../contexts/Context";
import { IoCloseCircle } from "react-icons/io5";

const PaxModel = ({ show, setShow, handleCallback }) => {
    const [displayShow, setDisplay] = useState(show ? "grid" : "none");
    const [pax, setPax] = useState(0);
    const [warningMsg, setWarningMsg] = useState("");
    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { lanIndex } = React.useContext(UserContext);

    useEffect(() => {
        setDisplay(show ? "flex" : "none");
    }, [show]);

    const editPax = (value) => {
        if (pax >= 0 && pax <= themeContext.maxPax) {
            if (value > 0 && pax !== themeContext.maxPax) {
                setPax(pax + value);
            }
            if (value < 0 && pax !== 0) {
                setPax(pax + value);
            }
        }
        setWarningMsg("");
    };
    return (
        <React.Fragment>
            <ModalContainer display={displayShow}>

                <ContentWrapper>
                    <IconButton onClick={() => {
                        setShow(false);
                        setDisplay(false);
                    }}>
                        <IoCloseCircle size={36} />
                    </IconButton>
                    <WarningMsg themeContext={themeContext}>
                        {warningMsg}
                    </WarningMsg>
                    <PaxContainer themeContext={themeContext}>
                        <div style={{ marginTop: 16, marginBottom: 16 }}>{languageContext[lanIndex].noOfPax}</div>
                        <PaxButtonContainer themeContext={themeContext}>
                            <PaxNumTitle style={{ fontSize: 24 }} themeContext={themeContext}>Guest: </PaxNumTitle>
                            <PaxButton
                                style={{ fontSize: 38, lineHeight: '26px' }}
                                themeContext={themeContext}
                                onClick={() => {
                                    editPax(-1);
                                    let element = document.getElementById("pax2");
                                    if (element != null) {
                                        element.classList.add("pax-animate");
                                        setTimeout(() => {
                                            element.classList.remove("pax-animate");
                                        }, 200);

                                    }
                                }}
                            >-</PaxButton>
                            <PaxNum themeContext={themeContext} id="pax2">{pax}</PaxNum>
                            <PaxButton
                                style={{ fontSize: 38, lineHeight: '26px' }}
                                themeContext={themeContext}
                                onClick={() => {
                                    editPax(1);
                                    let element = document.getElementById("pax2");
                                    if (element != null) {
                                        element.classList.add("pax-animate");
                                        setTimeout(() => {
                                            element.classList.remove("pax-animate");
                                        }, 200);
                                    }

                                }}
                            >+</PaxButton>
                        </PaxButtonContainer>
                        <ButtonWrapper>
                            <Button themeContext={themeContext} onClick={() => {
                                if (pax == 0) {
                                    setWarningMsg("Pax is required");
                                    return;
                                }
                                handleCallback(pax);
                            }} >
                                {languageContext[lanIndex].continue}
                            </Button>

                        </ButtonWrapper>
                    </PaxContainer>

                </ContentWrapper>
            </ModalContainer>
        </React.Fragment >
    );
}

export default PaxModel;

const WarningMsg = styled.div`
    color: ${(props) => props.themeContext.fontAlert};
`;
const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 24px;
    margin-bottom: 16px;
`;

const PaxContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const PaxButton = styled.div`
  &:active{
    background: rgba(0,0,0,0.1);
  }
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  color: ${(props) => props.themeContext.primaryColor};
  box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
  transition: background 0.1s;
`
const PaxButtonContainer = styled.div`
  display: -webkit-flex;
  color: white;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
`;
const PaxNumTitle = styled.div`
  font-size: 36px;
  font-weight: 400;
  padding: 0 15px;
  color: ${(props) => props.themeContext.primaryColor};
`;
const PaxNum = styled.div`
  font-size: 36px;
  font-weight: 400;
  padding: 0 15px;
  color: ${(props) => props.themeContext.primaryColor};

`;

const Button = styled.div`
    display: flex;
    width: 120px;
    margin: 8px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    border-radius: 25px;
    line-height: 40px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
   
`;


const IconButton = styled.div`
  
  display:flex;
  justify-content: flex-end;
  margin-top: 16px;
  width: 100%;
  background: white;
  z-index: 99;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    overflow-y: auto;
    margin-top: 48px;
    background: white;
    border-radius: 16px;
    padding: 0px 16px;
`;

const ModalContainer = styled.div`
    display: ${(props) => props.display};
    position: fixed;
    flex-direction: column;
    background: rgba(0,0,0,0.6);
    top: 0;

    width: 100vw;
    max-width: 550px;
    height: 100dvh;
    z-index: 9;
    justify-content: center;
    align-items: center;
    animation: modalOrder .1s;
    animation-timing-function: ease-out;
    @keyframes modalOrder {
        0%  {
        transform: translateY(350px);
        opacity: 0;
        }
        
        100%  {
        transform: translateY(0px);
        opacity: 1;
        }
        
    }
  `;

