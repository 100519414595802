import React from "react";
import styled from "@emotion/styled";
import { ThemeContext } from "../../contexts/Context";

const DotContainer = styled.div`
  display: grid;
  background-color: white;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 20px;
`;
const Dot = styled.div`
  background-color: ${(props) => props.selectedColor};
  width: 12px;
  height: 12px;
  border-radius: 12px;
`;

const DotButton = ({
  activeState,
  setActiveState,
  produceNewState,
  title,
  array,
}) => {
  const { selectedColor, borderColor } = React.useContext(ThemeContext);
  return (
    <DotContainer
      borderColor={borderColor}
      onClick={() => {
        title
          ? setActiveState(produceNewState(array, title))
          : setActiveState(activeState);
      }}
    >
      {activeState ? <Dot selectedColor={selectedColor} /> : <React.Fragment />}
    </DotContainer>
  );
};

export default DotButton;
