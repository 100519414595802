import React, { useState } from "react";
import { ThemeContext, LanguageContext, UserContext } from "../../contexts/Context";
import styled from "@emotion/styled";

import { IoPhonePortrait, IoBookmark, IoTime, IoPencil } from "react-icons/io5";
import moment from 'moment';

const PickupInfo = ({ setShow, phone, customerName, scheduleTime }) => {
    const language = React.useContext(LanguageContext);
    const themeContext = React.useContext(ThemeContext);
    const { lanIndex, finalOrder } = React.useContext(UserContext);

    return (
        <Root>
            <IconEdit>
                <IconWraper onClick={setShow}>
                    <IoPencil fontSize={16} color={themeContext.fontAlert} />
                </IconWraper>
            </IconEdit>
            <HeaderDisplay themeContext={themeContext}>
                <Wrapper>
                    <IconWraper>
                        <IoPhonePortrait fontSize={24} />
                    </IconWraper>
                    <Content themeContext={themeContext} style={{ marginLeft: 8 }}>
                        <SubContent>{language[lanIndex].phone}</SubContent>
                        <b>{finalOrder ? finalOrder.phone : phone}</b>
                    </Content>
                </Wrapper>
                <Wrapper>
                    <IconWraper>
                        <IoBookmark fontSize={24} />
                    </IconWraper>
                    <Content themeContext={themeContext} style={{ marginLeft: 8 }}>
                        <SubContent>{language[lanIndex].name}</SubContent>
                        <b>{finalOrder ? finalOrder.customerName : customerName}</b>
                    </Content>
                </Wrapper>
                <Wrapper>
                    <IconWraper>
                        <IoTime fontSize={24} />
                    </IconWraper>
                    <Content themeContext={themeContext} style={{ marginLeft: 8 }}>
                        <SubContent>{language[lanIndex].bookingTime}</SubContent>
                        <b>{finalOrder && finalOrder.scheduleTime ? moment(finalOrder.scheduleTime).format("DD/MM HH:mm") : (scheduleTime ? moment(scheduleTime).format("DD/MM HH:mm") : "-")}</b>
                    </Content>
                </Wrapper>


            </HeaderDisplay>
        </Root>
    )



}

export default PickupInfo;
const Root = styled.div`
    position: relative;
`;
const Content = styled.div`
    
`;
const IconEdit = styled.div`
    position: absolute;
    right: 30px;
    top: 8px;
`;
const SubContent = styled.div`
    font-size: 12px;
`;
//Styled Components
const HeaderDisplay = styled.div`
   margin: 0 16px;
   padding:8px;
   border: 1px solid;
   border-radius: 6px;
   margin-bottom: 8px;
   display: flex;
   flex-wrap: wrap;

`;
const Wrapper = styled.div`
    display:flex;
    align-items: center;
    margin-right: 16px;
`;
const IconWraper = styled.div`
   
`;

