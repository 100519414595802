import React, { useState, useEffect, useRef } from "react";
import DotButton from "./DotButton";
import ArrayBar from "./ArrayBar";
import Carousel from "./Carousel";
import produce from "immer";
import styled from "@emotion/styled";
import { ThemeContext, UserContext } from "../../contexts/Context";
import MultiQtyOption from './MultiQtyOption';

const LABEL_ONLY = 0;
const LABEL_DOT = 1;
const LABEL_PRICE = 2;
const LABEL_ONLY_MULTI = 3;
const LABEL_ITEM_ONLY = 4;

const OPTION_BAR = 0;
const OPTION_DOT_SINGLE = 1;
const OPTION_DOT_MULTIPLE = 2;
const OPTION_CAROUSEL = 3;
const OPTION_MULTI_QTY = 4;
const OPTION_SET_MEAL_2 = 5;
const OPTION_SET_MEAL_3 = 6;
const scrollToView = (element) => {
  //get view position

  let posTarget = element.getBoundingClientRect().top - (window.innerHeight / 3);
  // let margin = posTarget / 1000 * 60;

  // function step() {
  if (document.getElementById("menu_scroll") != null)
    document.getElementById("menu_scroll").scrollBy({ top: posTarget, behavior: "smooth" });
  // posTarget -= margin;
  // if (Math.abs(posTarget) > 50) { //acceptable
  //   window.requestAnimationFrame(step);
  // }
  // }
  // window.requestAnimationFrame(step);
}

const MenuOptions = ({
  visibleSlide,
  menuIndex,
  menuSelectionFormRef,
  recommendedArray,
  setRecommendedArray,
  menuOptions,
  setPrice,
  setComparePrice,
  isSetMeal,
  fixedPrice,
  hasRecommended,
  isModal,
  cp
}) => {

  const themeContext = React.useContext(ThemeContext);
  const { lanIndex, currencySymbol, displayNetAmount, tax, hidePrice, isLanReverse } = React.useContext(UserContext);
  //if item has no codeId, option Types will become normal

  const {
    labelType,
    optionType,
    optionTitle,
    optionTitle2,
    optionPrice,
    optionActive,
    optionRequired,
    isMultiple,
    isReadOnly,
    minQty,
    maxQty,
    options, //Each Option Item Array
  } = menuOptions; //Each option Group

  //State
  const [activeLabel, setActiveLabel] = useState(optionActive);
  const [optionsState, setOptions] = useState(options);
  const [selected, setSelected] = useState(0);
  const [optionPriceHolder, setOptionPriceHolder] = useState(optionPrice);
  const [itemTax, setItemTax] = useState(0);
  //Ref to change
  const optionArray = useRef(null);


  //can be recommendation or option
  useEffect(() => {
    console.log("Pass To Update");
    optionArray.current = hasRecommended
      ? recommendedArray
      : menuSelectionFormRef.current.optionArray;
  }, [hasRecommended, menuSelectionFormRef, recommendedArray]);


  //Make sure state is updated
  //consist of option group & item
  useEffect(() => {
    setOptions(options);
    let selected = 0;
    options.forEach((item) => { if (item.active) selected += (item.qty ? item.qty : 1); });
    setSelected(selected);
  }, [options]);

  const setActiveLabelButton = (state) => {
    //Turn Active Label Off/On
    setActiveLabel(!state);
    //For Ref Form
    optionArray.current[menuIndex].optionActive = !state;
  };

  const adjustQuantity = produce((draft, qty, index) => {
    // console.log({ hasRecommended });
    if (qty == 0) {
      draft[index].qty = 0;
      draft[index].active = false;
    } else {
      draft[index].qty = qty;
      draft[index].active = true;
    }

    if (hasRecommended) {
      recommendedArray = JSON.parse(JSON.stringify(recommendedArray));
      recommendedArray[menuIndex].options[index].qty = qty;
      recommendedArray[menuIndex].options[index].active = true;
      if (qty == 0) {
        recommendedArray[menuIndex].options[index].qty = 0;
        recommendedArray[menuIndex].options[index].active = false;
      }
    } else {
      menuSelectionFormRef.current.optionArray[menuIndex].options[index].qty = qty;
    }



    if (hasRecommended) {
      // recommendedArray[0].options = recommendedArray[menuIndex].options;
      setRecommendedArray(recommendedArray);
    }
  });


  //draf is the array of current selection
  const produceArrayStateSingle = produce((draft, itemOptionId, isMultiple, hasRecommended, menuIndex) => {
    //For State
    if (isReadOnly) return;
    draft.forEach((mElement) => {
      if (!isMultiple && !hasRecommended) mElement.active = false;
      if (isSetMeal) {
        //DE-SELECT ITEM
        mElement.optionArray && mElement.optionArray.forEach((element) => {
          element = JSON.parse(JSON.stringify(element));
          element.options.forEach((em) => {
            if (!element.isMultiple && !hasRecommended && (mElement.itemOptionId != itemOptionId && mElement.codeId != itemOptionId)
            ) {
              em.active = false;
            }
          })
        })
      }
    });
    let hasChildIndex = false;
    let foundActive = draft.find((item) => item.itemOptionId == itemOptionId || item.codeId == itemOptionId);
    if (!hasRecommended) {
      foundActive.active = !foundActive.active;
    } else {
      foundActive.active = true;
      foundActive.qty = foundActive.qty ? foundActive.qty + 1 : 1;
    }
    // For Ref Form
    //reset all to false
    if (hasRecommended) {
      recommendedArray[menuIndex].options.forEach((element1) => {
        if (!isMultiple && !hasRecommended) element1.active = false;
        if (isSetMeal && element1.optionArray) {
          element1.optionArray.forEach((element) =>
            element.options.forEach((em) => {
              if (!isMultiple && !hasRecommended) em.active = false;
              // return element;
            })
          );
        }
      });
    } else {
      menuSelectionFormRef.current.optionArray[menuIndex].options.forEach((element1) => {
        if (!isMultiple && !hasRecommended) element1.active = false;
        if (isSetMeal && element1.optionArray) {
          element1.optionArray.forEach((element) => {
            element.options.forEach((em) => {
              if (!element.isMultiple && !hasRecommended) { em.active = false; }
              // return element;
            });

          });
        }
      });
    }

    if (hasRecommended) {
      recommendedArray[menuIndex].options = recommendedArray[menuIndex].options.map((item) => {
        item = JSON.parse(JSON.stringify(item));
        if (item.itemOptionId == itemOptionId || item.codeId == itemOptionId) {

          if (!hasRecommended) {
            item.active = !item.active;
          } else {
            item.active = true;
            item.qty = item.qty ? item.qty + 1 : 1;
          }
        }
        return item;
      });
    } else {
      menuSelectionFormRef.current.optionArray[menuIndex].options = menuSelectionFormRef.current.optionArray[menuIndex].options.map((item) => {
        if (item.itemOptionId == itemOptionId || item.codeId == itemOptionId) {
          if (!hasRecommended) {
            item.active = !item.active;
            if (item.optionArray && item.optionArray.length > 0) {
              hasChildIndex = true;
            }
          } else {
            item.active = true;
            item.qty = item.qty ? item.qty + 1 : 1;
          }
        }
        return item;
      });
    }

    let amount = 0;
    if (fixedPrice !== true && !hasRecommended) {
      if (isSetMeal !== true) {
        menuSelectionFormRef.current.optionArray[menuIndex].options.forEach((dt) => {
          if (dt.active) {
            amount += (dt.addPrice ? dt.addPrice : 0);
          }
        })
      } else {
        menuSelectionFormRef.current.optionArray[menuIndex].options.forEach((dt) => {
          if (dt.active) {
            amount += !isNaN(dt.addPrice) ? dt.addPrice : dt.price;

            if (dt.optionArray) {
              dt.optionArray.forEach((di) => {
                console.log("Main Optin Sub option List");
                if (di.options) {

                  di.options.forEach((df) => {
                    if (df.active) {
                      console.log("Main Optin Sub option List - Action ", df.addPrice);
                      amount += (dt.addPrice ? dt.addPrice : 0);
                    }
                  })
                }
              })
            }
          }
        })

      }
    }
    setOptionPriceHolder((amount * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : menuSelectionFormRef.current.tax)) : 1)).toFixed(2));
    if (hasRecommended) {
      // recommendedArray[0].options = optionArray.current[menuIndex].options;
      setRecommendedArray(recommendedArray);
    }

    if (isSetMeal && !hasRecommended) {
      //animate to next
      //check have indept option
      if (!isMultiple) {
        setTimeout(() => {
          //need to check if has next array
          if (hasChildIndex) {
            scrollToView(document.getElementById(menuIndex + "0"));
          } else {
            let nextIndex = menuIndex + 1;
            while (menuSelectionFormRef.current.optionArray.length > nextIndex) {
              if (!menuSelectionFormRef.current.optionArray[nextIndex].isReadOnly) {
                break;
              } else {
                nextIndex++;
              }
            }

            if (menuSelectionFormRef.current.optionArray.length > nextIndex) {
              setTimeout(() => {

                scrollToView(document.getElementById(nextIndex + ""));
              }, 200)
            }

          }
        }, 200)
        return;
      }

    }
  });

  const productMinusStateMulti = produce((draft, itemOptionId) => {
    // const foundActive = draft.find((item) => item.itemOptionId == itemOptionId || item.codeId == itemOptionId);
    if (isReadOnly) return;
    menuSelectionFormRef.current.optionArray[menuIndex].options = menuSelectionFormRef.current.optionArray[menuIndex].options.map((item) => {
      if (item.itemOptionId == itemOptionId || item.codeId == itemOptionId) {
        if (item.qty > 1) {
          item.active = true;
          item.qty--;
        } else {
          item.active = false;
          item.qty = 0;
        }
      }
      return item;
    });

    let selected = 0;
    menuSelectionFormRef.current.optionArray[menuIndex].options.forEach((item) => { if (item.active) selected += (item.qty ? item.qty : 1); });

    setSelected(selected);

    //Toggle Current Price
    let currentPrice = 0;
    menuSelectionFormRef.current.optionArray[menuIndex].options.forEach((element) => {
      if (element.active) {
        currentPrice = currentPrice + (element.addPrice ? element.addPrice * (element.qty ? element.qty : 1) : 0);
      }
    });
    setOptions(JSON.parse(JSON.stringify(menuSelectionFormRef.current.optionArray[menuIndex].options)));
    setOptionPriceHolder((currentPrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : menuSelectionFormRef.current.tax)) : 1)).toFixed(2));
  });

  const produceArrayStateMulti = produce((draft, itemOptionId) => {
    if (isReadOnly) return;
    //For State
    const foundActive = draft.find((item) => item.itemOptionId == itemOptionId || item.codeId == itemOptionId);
    //Check if has reach max Qty;

    if (maxQty) {
      let selected = 0;
      draft.forEach((item) => {
        if (item.active) { selected += (item.qty ? item.qty : 1) }
      });
      if (foundActive.active && optionType != 4) selected--;
      else selected++;

      if (selected > maxQty) return;
    }

    let hasChildIndex = false;

    if (optionType != 4)
      foundActive.active = !foundActive.active;
    else {
      foundActive.active = true;
    }
    // For Ref Form
    menuSelectionFormRef.current.optionArray[menuIndex].options = menuSelectionFormRef.current.optionArray[menuIndex].options.map((item) => {
      if (item.itemOptionId == itemOptionId || item.codeId == itemOptionId) {
        if (optionType != 4)
          item.active = !item.active;
        else if (item.qty) {
          item.active = true;
          item.qty++;
        } else {
          item.active = true;
          item.qty = 1;
        }
        if (!hasRecommended && item.optionArray && item.optionArray.length > 0) {
          hasChildIndex = true;
        }
      }
      return item;
    });

    let selected = 0;
    menuSelectionFormRef.current.optionArray[menuIndex].options.forEach((item) => { if (item.active) selected += (item.qty ? item.qty : 1); });

    setSelected(selected);

    //Toggle Current Price
    let currentPrice = 0;
    menuSelectionFormRef.current.optionArray[menuIndex].options.forEach((element) => {
      if (element.active) {
        currentPrice = currentPrice + (element.addPrice ? element.addPrice * (element.qty ? element.qty : 1) : (element.price ? element.price * (element.qty ? element.qty : 1) : 0));
      }
    });
    setOptions(JSON.parse(JSON.stringify(menuSelectionFormRef.current.optionArray[menuIndex].options)));
    setOptionPriceHolder((currentPrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : menuSelectionFormRef.current.tax)) : 1)).toFixed(2));

    //scroll to next section
    if (isSetMeal && !hasRecommended) {
      //animate to next
      //check have indept option
      if (maxQty && selected == maxQty) {
        setTimeout(() => {
          //need to check if has next array
          if (hasChildIndex) {
            scrollToView(document.getElementById(menuIndex + "0"));
          } else {
            let nextIndex = menuIndex + 1;
            while (menuSelectionFormRef.current.optionArray.length > nextIndex) {
              if (!menuSelectionFormRef.current.optionArray[nextIndex].isReadOnly) {
                break;
              } else {
                nextIndex++;
              }
            }

            if (menuSelectionFormRef.current.optionArray.length > nextIndex) {
              setTimeout(() => {
                scrollToView(document.getElementById(nextIndex + ""));
              }, 200)
            }

          }
        }, 200)
        return;
      }

    }

  });

  useEffect(() => {
    //run on mount
    let currentPrice = 0;
    //.active check
    console.log({ optionArray, menuIndex });
    optionArray.current[menuIndex].options.forEach((element) => {
      if (element.active) {
        if (isSetMeal == true) {
          currentPrice += (element.price ? element.price : 0);
        } else {
          currentPrice += (element.addPrice ? element.addPrice * (element.qty ? element.qty : 1) : 0);
        }
      }
    });

    setOptionPriceHolder(currentPrice.toFixed(2));
  }, [menuIndex, optionArray, isSetMeal]);


  useEffect(() => {
    //calculate total Price including add on
    //Run after optionPriceHolder is updated.
    if (fixedPrice !== true && menuSelectionFormRef.current) {
      let itemTax = hasRecommended == true
        ? menuSelectionFormRef.current.recommendedArray.tax
        : menuSelectionFormRef.current.tax;
      setItemTax(itemTax);
      let basePrice =
        hasRecommended == true
          ? menuSelectionFormRef.current.recommendedArray.price
          : menuSelectionFormRef.current.price;

      let comparePrice = 0;
      if ((hasRecommended && menuSelectionFormRef.current.recommendedArray.comparePrice) ||
        (!hasRecommended && menuSelectionFormRef.current.comparePrice)) {
        comparePrice = hasRecommended == true ? menuSelectionFormRef.current.recommendedArray.comparePrice : menuSelectionFormRef.current.comparePrice;
      } else if (menuSelectionFormRef.current.pricebook && Object.keys(menuSelectionFormRef.current.pricebook).length > 0) {
        let bp = menuSelectionFormRef.current.pricebook[Object.keys(menuSelectionFormRef.current.pricebook)[0]];

        if (bp.minQty == 1) {
          comparePrice = bp.price * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : menuSelectionFormRef.current.tax)) : 1);
        }
      }


      let addOnPrice = 0;

      optionArray.current.forEach((element) => {
        element.options.forEach((element) => {
          if (element.active) {
            if (isSetMeal == true) {
              addOnPrice += !isNaN(element.addPrice) ? element.addPrice : element.price;
              element.optionArray && element.optionArray.forEach((element) => {
                element.options.forEach((el) => {
                  if (el.active) {
                    addOnPrice += (el.addPrice ? el.addPrice * (el.qty ? el.qty : 1) : 0);

                  }
                });
              });
            } else {
              addOnPrice += (element.addPrice ? element.addPrice * (element.qty ? element.qty : 1) : 0);
            }
          }
        });
      });

      let totalPrice = basePrice + (!isNaN(addOnPrice) ? addOnPrice : 0);
      setPrice((totalPrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1)).toFixed(2));
      if (setComparePrice != null) {
        console.log("Trigger COmpare Price");
        setComparePrice((comparePrice + (!isNaN(addOnPrice) ? addOnPrice : 0) * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1)).toFixed(2));
      }
    }
  }, [
    optionPriceHolder,
    setPrice,
    setComparePrice,
    fixedPrice,
    isSetMeal,
    menuSelectionFormRef,
    optionArray,
    hasRecommended,
  ]);

  const optionComponentType = (optionType, menuIndex) => {
    switch (optionType) {
      case OPTION_BAR: {
        return (
          <MenuInfoOptionContainerBar>
            <ArrayBar
              height={40}
              lanIndex={lanIndex}
              isLanReverse={isLanReverse}
              array={optionsState}
              setActiveState={setOptions}
              menuIndex={menuIndex}
              isMultiple={isMultiple}
              produceArrayStateMulti={produceArrayStateMulti}
              produceNewState={produceArrayStateSingle}
            />
          </MenuInfoOptionContainerBar>
        );
      }
      case OPTION_MULTI_QTY: {
        return (
          <MenuInfoOptionContainer>
            <MultiQtyOption
              height={40}
              lanIndex={lanIndex}
              isLanReverse={isLanReverse}
              array={optionsState}
              setActiveState={setOptions}
              currencySymbol={currencySymbol}
              displayNetAmount={displayNetAmount}
              tax={tax}
              itemTax={itemTax}
              menuIndex={menuIndex}
              isMultiple={isMultiple}
              produceArrayStateMulti={produceArrayStateMulti}
              produceMinusArray={productMinusStateMulti}

            />
          </MenuInfoOptionContainer>
        )
      }
      case OPTION_DOT_SINGLE: {
        return (
          <MenuInfoOptionContainer>
            {optionsState.map((item, index) => (
              <ArrayOption
                themeContext={themeContext}
                key={item.title + index}
                /* Make clickable area bigger */
                onClick={() =>
                  setOptions(produceArrayStateSingle(optionsState, item.itemOptionId, false, false, menuIndex))
                }
              >
                <DotTextContainer themeContext={themeContext}>
                  {(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ?
                    item.title :
                    item.title2 ? item.title2 : item.title}<br />
                  <DotPrice>{item.addPrice ? " +" + currencySymbol + " " + (item.addPrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1)).toFixed(2) : ""}
                  </DotPrice>
                </DotTextContainer>
                <DotButtonContainer>
                  <DotButton
                    index={index}
                    title={(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ?
                      item.title :
                      item.title2 ? item.title2 : item.title}
                    activeState={item.active}
                    array={optionsState}
                    setActiveState={() => { }}
                    produceNewState={() => { }}
                  />
                </DotButtonContainer>
              </ArrayOption>
            ))}
          </MenuInfoOptionContainer>
        );
      }

      case OPTION_DOT_MULTIPLE: {
        return (
          <MenuInfoOptionContainer>
            {optionsState.map((item, index) => (
              <ArrayOption
                themeContext={themeContext}
                key={item.title + index}
                /* Make clickable area bigger */
                onClick={() =>
                  setOptions(produceArrayStateMulti(optionsState, item.itemOptionId, true, menuIndex))
                }
              >
                <DotTextContainer themeContext={themeContext}>
                  {(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ?
                    item.title :
                    item.title2 ? item.title2 : item.title}<br />
                  <DotPrice>{item.addPrice ? " +" + currencySymbol + " " + (item.addPrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1)).toFixed(2) : ""}
                  </DotPrice>
                </DotTextContainer>
                <DotButtonContainer>
                  <DotButton
                    index={index}
                    title={(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ?
                      item.title :
                      item.title2 ? item.title2 : item.title}
                    activeState={item.active}
                    array={optionsState}
                    setActiveState={() => { }}
                    produceNewState={() => { }}
                  />
                </DotButtonContainer>
              </ArrayOption>
            ))}
          </MenuInfoOptionContainer>
        );
      }

      case OPTION_CAROUSEL:
      case OPTION_SET_MEAL_2:
      case OPTION_SET_MEAL_3:
        {
          return (
            <React.Fragment>
              <Carousel
                key={"carousel"}
                visibleSlide={visibleSlide}
                isSetMeal={isSetMeal}
                isMultiple={isMultiple}
                array={optionsState}
                hasRecommended={hasRecommended}
                setArrayState={setOptions}
                produceNewState={produceArrayStateSingle}
                produceMultiState={produceArrayStateMulti}
                adjustQuantity={adjustQuantity}
                lanIndex={lanIndex}
                isLanReverse={isLanReverse}
                menuIndex={menuIndex}
                currencySymbol={currencySymbol}
                cp={cp}
                optionType={optionType}
              />
              {(!isMultiple && !hasRecommended) && GetActiveForRecursion(optionsState, menuIndex)}
            </React.Fragment>
          );
        }
      default: {
        return <div>Error</div>;
      }
    }
  };

  const GetActiveForRecursion = (optionsState, parentIndex) => {
    let recursionTarget = optionsState.find((item) => item.active == true);

    if (recursionTarget) {
      console.log(recursionTarget.optionArray);
      return (
        <React.Fragment>
          {recursionTarget.optionArray.map((item, index) => (
            <MenuOptionsSetMenu
              key={"carouseloptions" + index}
              menuIndexParent={parentIndex}
              menuIndex={index}
              menuSelectionFormRef={menuSelectionFormRef}
              menuOptions={item}
              setPrice={setPrice}
              setComparePrice={setComparePrice}
              isSetMeal={isSetMeal}
              fixedPrice={fixedPrice}
              lanIndex={lanIndex}
              isLanReverse={isLanReverse}
              itemTax={itemTax}
              currencySymbol={currencySymbol}
              hasRecommended={hasRecommended}
              cp={cp}
            />
          ))}
        </React.Fragment>
      );
    } else {
      <React.Fragment />;
    }
  };

  const labelComponentType = (labelType) => {
    switch (labelType) {
      case LABEL_ONLY: {
        return (
          <TitleContainer themeContext={themeContext}>
            {`${(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? optionTitle : optionTitle2 ? optionTitle2 : optionTitle} ${optionRequired ? "*" : ""}`}
          </TitleContainer>
        );
      }
      case LABEL_ONLY_MULTI: {
        return (
          <React.Fragment>
            <TitleContainer themeContext={themeContext}>
              {`${(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? optionTitle : optionTitle2 ? optionTitle2 : optionTitle} ${optionRequired ? `*` : ""}`}
              {(optionRequired) &&
                <TitleSelect isAllSelect={minQty - selected}>
                  {"select " + (selected) + "/" + minQty}
                </TitleSelect>
              }
            </TitleContainer>
            {(!hidePrice && optionPriceHolder > 0) &&
              <PriceContainer
                themeContext={themeContext}
              >{`${currencySymbol} ${optionPriceHolder}`}</PriceContainer>
            }
          </React.Fragment>
        );
      }
      case LABEL_ITEM_ONLY: {
        return (
          <React.Fragment>
            <TitleContainer themeContext={themeContext}>
              {`${(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? optionTitle : optionTitle2 ? optionTitle2 : optionTitle} ${optionRequired ? "*" : ""}`}
            </TitleContainer>
          </React.Fragment>
        );
      }
      case LABEL_DOT: {
        return (
          <React.Fragment>
            <TitleContainer themeContext={themeContext}>
              {`${(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? optionTitle : optionTitle2 ? optionTitle2 : optionTitle} ${optionRequired ? "*" : ""}`}
            </TitleContainer>
            <DotButtonContainer
              /* Make clickable area bigger */
              onClick={() => {
                setActiveLabelButton(activeLabel);
              }}
            >
              <DotButton activeState={activeLabel} setActiveState={() => { }} />
            </DotButtonContainer>
          </React.Fragment>
        );
      }
      case LABEL_PRICE: {
        return (
          <React.Fragment>
            <TitleContainer themeContext={themeContext}>
              {`${(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? optionTitle : optionTitle2 ? optionTitle2 : optionTitle} ${optionRequired ? "*" : ""}`}
            </TitleContainer>
            {(!hidePrice && optionPriceHolder > 0) &&
              <PriceContainer
                themeContext={themeContext}
              >{`${currencySymbol} ${optionPriceHolder}`}</PriceContainer>
            }
          </React.Fragment>
        );
      }
      default: {
        return <div>Error</div>;
      }
    }
  };

  return (
    <React.Fragment>
      {!isModal &&
        <MenuInfoHeaderContainer themeContext={themeContext} id={menuIndex + ""}>
          {labelComponentType(isMultiple ? 3 : labelType)}
        </MenuInfoHeaderContainer>
      }
      {activeLabel ? (
        <React.Fragment>{optionComponentType(optionType, menuIndex)}</React.Fragment>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};

export default MenuOptions;
const DotPrice = styled.div`
  font-size: 11px;
`;
const MenuInfoHeaderContainer = styled.div`
  scroll-behavior: smooth;
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${(props) => props.themeContext.borderColor};
  border-bottom: 1px solid ${(props) => props.themeContext.borderColor};
  align-items:center;
  color: ${(props) => props.themeContext.fontPrimary};
  margin-top: 24px;
  padding-left: 8px;
  padding-right: 8px;
`;

const MenuInfoOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 99%;
  padding-bottom:5px;
`;

const MenuInfoOptionContainerBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 94%;
  margin: 3%;
  min-height: 50px;
  margin-bottom: -24px;
`;

const TitleContainer = styled.div`
  font-family: ${(props) => props.themeContext.fontFamily};
  display: grid;
  align-items: center;
  min-width: 65%;
  max-width: 100%;
  min-height: 25px;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 400;
  text-align: left;
  display: flex;
  margin-left: 4px;
  flex-wrap: wrap;
`;
const TitleSelect = styled.div`
  font-size: 10px;
  background-color: ${(props) => props.isAllSelect != 0 ? "#9e9e9e" : "#4caf50"};
  padding: 4px;
  margin-left: 4px;
  color: white;
  border-radius: 4px;
`;
const DotTextContainer = styled.div`
  font-family: ${(props) => props.themeContext.fontFamily};
  display: grid;
  align-items: center;
  min-width: 70%;
  max-width: 100%;
  min-height: 25px;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 3px;
  font-weight: 500;
  text-align: left;
`;
const PriceContainer = styled.div`
  display: grid;
  width: 35%;
  color: ${(props) => props.themeContext.fontPrimary};
  font-size: 12px;
  font-weight: bold;
  font-family: ${(props) => props.themeContext.fontFamily};
  margin-right: 5px;

  text-align: right;
  align-items: center;
  white-space: nowrap;
`;
const DotButtonContainer = styled.div`
  display: grid;
  width: 30%;
  margin-right: 5px;
  justify-content: right;
  align-items: center;
`;

const ArrayOption = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.themeContext.backgroundColor};
  border-bottom: 1px solid ${(props) => props.themeContext.borderColor};
  margin: 0px;
  min-height: 40px;
  margin-left:8px;
  margin-right: 8px;
`;

//Basically a copy to be recursively called, the only difference is the produce functions
//*********************************************************************
//******SET MEAL OPTION MENU        ****************************** */
//******************************************************************* */
const MenuOptionsSetMenu = ({
  menuIndex,
  menuIndexParent,
  menuSelectionFormRef,
  menuOptions,
  setPrice,
  setComparePrice,
  isSetMeal,
  fixedPrice,
  hasRecommended,
  lanIndex,
  isLanReverse,
  currencySymbol,
  cp,
  itemTax
}) => {
  const {
    labelType,
    optionType,
    optionTitle,
    optionTitle2,
    optionPrice,
    optionActive,
    optionRequired,
    options,
    minQty,
    maxQty,
    isMultiple,
    isReadOnly
  } = menuOptions;
  const themeContext = React.useContext(ThemeContext);
  const { displayNetAmount, tax } = React.useContext(UserContext);
  //State
  const [activeLabel, setActiveLabel] = useState(optionActive);
  const [optionsState, setOptions] = useState(options);
  const [selected, setSelected] = useState(0);

  const [optionPriceHolder, setOptionPriceHolder] = useState(
    optionPrice.toFixed(2)
  );

  //Ref to change
  const optionArray = useRef(null);

  useEffect(() => {
    optionArray.current = hasRecommended
      ? menuSelectionFormRef.current.recommendedArray
      : menuSelectionFormRef.current.optionArray;

  }, [hasRecommended, menuSelectionFormRef]);
  useEffect(() => {
    setOptions(options);
    let selected = 0;
    options.forEach((item) => { if (item.active) selected += (item.qty ? item.qty : 1); });
    setSelected(selected);
  }, [options]);

  const setActiveLabelButton = (state) => {
    //Turn Active Label Off/On
    setActiveLabel(!state);

    //For Ref Form
    let findOptionMenu = optionArray.current[menuIndexParent].options.find(
      (item) => item.active == true
    );

    findOptionMenu.optionArray[menuIndex].optionActive = !state;
  };

  const produceArrayStateSingle = produce((draft, itemOptionId) => {
    if (isReadOnly) return;
    //For State
    draft.forEach((element) => {
      element.active = false;
    });

    const foundActive = draft.find((item) => item.itemOptionId == itemOptionId || item.codeId == itemOptionId);
    foundActive.active = !foundActive.active;
    // For Ref Form

    let findOptionMenu = menuSelectionFormRef.current.optionArray[menuIndexParent].options.find((item) => item.active == true);

    findOptionMenu.optionArray[menuIndex].options.forEach((element) => {
      element.active = false;
    });

    const menuSelectionFoundAction = findOptionMenu.optionArray[menuIndex].options.find((item) => item.itemOptionId == itemOptionId || item.codeId == itemOptionId);
    menuSelectionFoundAction.active = !menuSelectionFoundAction.active;
    let selected = 0;
    findOptionMenu.optionArray[menuIndex].options.forEach((item) => { if (item.active) selected++; });
    setSelected(selected)
    if (fixedPrice !== true) {
      setOptionPriceHolder(((!isNaN(foundActive.addPrice) ? foundActive.addPrice : foundActive.price) * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1)).toFixed(2));
    }

    if (isSetMeal) {
      //animate to next
      //check have indept option
      if (findOptionMenu.optionArray.length > menuIndex + 1) {
        setTimeout(() => {
          //document.getElementById(menuIndexParent + "" + (menuIndex + 1)).scrollIntoView({ behavior: 'smooth', block: "center", inline: "center" });
          scrollToView(document.getElementById(menuIndexParent + "" + (menuIndex + 1)));
        }, 200)
        return;
      }

      let nextIndex = menuIndexParent + 1;
      while (optionArray.current.length > nextIndex) {
        if (!optionArray.current[nextIndex].isReadOnly) {
          break;
        } else {
          nextIndex++;
        }
      }

      if (optionArray.current.length > nextIndex) {
        setTimeout(() => {
          scrollToView(document.getElementById(nextIndex + ""));
        }, 200)
      }
    }

  });

  const productMinusStateMulti = produce((draft, itemOptionId) => {
    if (isReadOnly) return;
    let findOptionMenu = menuSelectionFormRef.current.optionArray[menuIndexParent].options.find(
      (item) => item.active == true
    );

    //e.g. menuIndex => shiro's noodle texture or shiro's topping
    //title => Yawa, kata or bari
    const menuSelectionFoundAction = findOptionMenu.optionArray[menuIndex].options.find(
      (item) => item.itemOptionId == itemOptionId || item.codeId == itemOptionId);

    if (menuSelectionFoundAction.qty && menuSelectionFoundAction.qty > 1)
      menuSelectionFoundAction.qty--;
    else {
      menuSelectionFoundAction.qty = 0;
      menuSelectionFoundAction.active = false;
    }

    let selected = 0;
    findOptionMenu.optionArray[menuIndex].options.forEach((item) => { if (item.active) selected += (item.qty ? item.qty : 1); });
    setSelected(selected);

    //e.g: check if Shiro's topping has additional
    let currentPrice = 0;
    console.log("sub Option index", menuIndex);
    if (findOptionMenu.optionArray[menuIndex].options)
      findOptionMenu.optionArray[menuIndex].options.forEach((element) => {
        if (element.active) {
          console.log("Active sub Option", element);
          if (element.price) {
            currentPrice = currentPrice + element.price;
          } else if (element.addPrice) {
            currentPrice = currentPrice + element.addPrice * (element.qty ? element.qty : 1);
          }
        }
      });
    console.log("sub Option index final price", currentPrice);
    if (fixedPrice !== true) {
      setOptionPriceHolder((currentPrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1)).toFixed(2));
    }
    setOptions(JSON.parse(JSON.stringify(findOptionMenu.optionArray[menuIndex].options)));
  });

  const produceArrayStateMulti = produce((draft, itemOptionId) => {
    if (isReadOnly) return;
    //For State
    const foundActive = draft.find((item) => item.itemOptionId == itemOptionId || item.codeId == itemOptionId);
    let selected = 0;

    if (maxQty != 0) {
      draft.forEach((item) => { if (item.active) { selected += (item.qty ? item.qty : 1) } });
      if (foundActive.active && optionType != 4) selected--;
      else selected++;

      if (selected > maxQty) return;

      setSelected(selected);
    }

    if (optionType != 4) {
      foundActive.active = !foundActive.active;
      if (foundActive.active) {
        selected++;
      }
    } else {
      foundActive.active = true;
      selected++;
    }
    // For Ref Form
    //e.g. selected shiro
    let findOptionMenu = menuSelectionFormRef.current.optionArray[menuIndexParent].options.find(
      (item) => item.active == true
    );

    //e.g. menuIndex => shiro's noodle texture or shiro's topping
    //title => Yawa, kata or bari
    const menuSelectionFoundAction = findOptionMenu.optionArray[menuIndex].options.find(
      (item) => item.itemOptionId == itemOptionId || item.codeId == itemOptionId);

    if (optionType != 4)
      menuSelectionFoundAction.active = !menuSelectionFoundAction.active;
    else {
      menuSelectionFoundAction.active = true;
      if (menuSelectionFoundAction.qty) menuSelectionFoundAction.qty++;
      else menuSelectionFoundAction.qty = 1;
    }
    //e.g: check if Shiro's topping has additional
    let currentPrice = 0;

    if (findOptionMenu.optionArray[menuIndex].options)
      findOptionMenu.optionArray[menuIndex].options.forEach((element) => {
        if (element.active) {

          if (element.price) {
            currentPrice = currentPrice + element.price;
          } else if (element.addPrice) {
            currentPrice = currentPrice + element.addPrice * (element.qty ? element.qty : 1);
          }
        }
      });

    if (fixedPrice !== true) {
      setOptionPriceHolder((currentPrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1)).toFixed(2));
    }
    setOptions(JSON.parse(JSON.stringify(findOptionMenu.optionArray[menuIndex].options)));


    if (maxQty != 0 && selected == maxQty) {
      //animate to next
      //check have indept option

      if (findOptionMenu.optionArray.length > menuIndex + 1) {
        setTimeout(() => {
          //document.getElementById(menuIndexParent + "" + (menuIndex + 1)).scrollIntoView({ behavior: 'smooth', block: "center", inline: "center" });
          scrollToView(document.getElementById(menuIndexParent + "" + (menuIndex + 1)));
        }, 200)
        return;
      }

      let nextIndex = menuIndexParent + 1;
      while (optionArray.current.length > nextIndex) {
        if (!optionArray.current[nextIndex].isReadOnly) {
          break;
        } else {
          nextIndex++;
        }
      }

      if (optionArray.current.length > nextIndex) {
        setTimeout(() => {
          //document.getElementById(nextIndex + "").scrollIntoView({ behavior: 'smooth', block: "center", inline: "center" });
          scrollToView(document.getElementById(nextIndex + ""));
        }, 200)
      }

    }

  });

  useEffect(() => {
    if (fixedPrice !== true) {
      if (optionArray.current[menuIndexParent].active == true) {
        let currentPrice = 0;
        //.active check
        optionArray.current[menuIndexParent].options.forEach((op) => {
          op.optionArray[menuIndex].options.forEach((element) => {
            console.log("RUNNING EFFECT")
            if (element.active) {
              //if (isSetMeal == true) {
              console.log("Active sub Option at initial array", element);
              if (element.price) {
                currentPrice = currentPrice + element.price;
              } else if (element.addPrice) {
                currentPrice = currentPrice + element.addPrice * (element.qty ? element.qty : 1);
              }
            }
          });
        });
        setOptionPriceHolder((currentPrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1)).toFixed(2));
      }
    }
  }, [menuIndexParent, menuIndex, fixedPrice, isSetMeal, optionArray]);

  useEffect(() => {
    console.log("Recalculate total price");
    if (fixedPrice !== true) {
      let basePrice =
        hasRecommended == true
          ? menuSelectionFormRef.current.recommendedArray.price
          : menuSelectionFormRef.current.price;

      let comparePrice = 0;
      if ((hasRecommended && menuSelectionFormRef.current.recommendedArray.comparePrice) ||
        (!hasRecommended && menuSelectionFormRef.current.comparePrice)) {
        comparePrice = hasRecommended == true
          ? menuSelectionFormRef.current.recommendedArray.comparePrice
          : menuSelectionFormRef.current.comparePrice;

      } else if (menuSelectionFormRef.current.pricebook && Object.keys(menuSelectionFormRef.current.pricebook).length > 0) {
        let bp = menuSelectionFormRef.current.pricebook[Object.keys(menuSelectionFormRef.current.pricebook)[0]];
        if (bp.minQty == 1) {
          comparePrice = bp.price * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : menuSelectionFormRef.current.tax)) : 1);
        }
      }

      let addOnPrice = 0;

      optionArray.current.forEach((element) => {
        //Add OptionPrice Here
        element.options.forEach((element) => {
          if (element.active) {
            //if (isSetMeal == true) {
            if (element.price) {
              addOnPrice = addOnPrice + element.price;
            } else if (element.addPrice) {
              addOnPrice = addOnPrice + element.addPrice * (element.qty ? element.qty : 1);
            }
            if (element.optionArray) {
              element.optionArray.forEach((element) => {
                if (element.options)
                  element.options.forEach((element) => {
                    if (element.active) {
                      addOnPrice = addOnPrice + element.addPrice * (element.qty ? element.qty : 1);
                    }
                  });
              });
            }
            //} else {

            //}
          }
        });
      });
      console.log("Final Add On Price", addOnPrice);
      let totalPrice = basePrice + (!isNaN(addOnPrice) ? addOnPrice : 0);
      setPrice((totalPrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1)).toFixed(2));
      if (setComparePrice != null) {
        console.log("Trigger COmpare Price");
        setComparePrice((comparePrice + (!isNaN(addOnPrice) ? addOnPrice : 0) * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1)).toFixed(2));
      }
    } else {
      setPrice(
        hasRecommended
          ? (menuSelectionFormRef.recommendedArray.current.price * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1)).toFixed(2)
          : (menuSelectionFormRef.current.price * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1)).toFixed(2)
      );

      if ((hasRecommended && menuSelectionFormRef.current.recommendedArray.comparePrice) ||
        (!hasRecommended && menuSelectionFormRef.current.comparePrice)) {
        if (setComparePrice != null)
          setComparePrice(
            hasRecommended
              ? (menuSelectionFormRef.recommendedArray.current.comparePrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1)).toFixed(2)
              : (menuSelectionFormRef.current.comparePrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1)).toFixed(2)
          );
      }
    }
  }, [
    optionPriceHolder,
    menuSelectionFormRef,
    setPrice,
    fixedPrice,
    isSetMeal,
    hasRecommended,
    optionArray,
  ]);

  const optionComponentType = (optionType) => {
    switch (optionType) {
      case OPTION_BAR: {
        return (
          <MenuInfoOptionContainerBar>
            <ArrayBar
              key={labelType + menuIndex + "arraybar"}
              height={40}
              array={optionsState}
              lanIndex={lanIndex}
              isLanReverse={isLanReverse}
              setActiveState={setOptions}
              produceNewState={produceArrayStateSingle}
            />
          </MenuInfoOptionContainerBar>
        );
      }
      case OPTION_MULTI_QTY: {
        return (
          <MenuInfoOptionContainer>
            <MultiQtyOption
              height={40}
              array={optionsState}
              setActiveState={setOptions}
              lanIndex={lanIndex}
              isLanReverse={isLanReverse}
              isMultiple={isMultiple}
              currencySymbol={currencySymbol}
              displayNetAmount={displayNetAmount}
              tax={tax}
              itemTax={itemTax}
              produceArrayStateMulti={produceArrayStateMulti}
              produceMinusArray={productMinusStateMulti}

            />
          </MenuInfoOptionContainer>
        )
      }
      case OPTION_DOT_SINGLE: {
        return (
          <MenuInfoOptionContainer>
            {optionsState.map((item, index) => (
              <ArrayOption
                themeContext={themeContext}
                key={item.title + index}
                /* Make clickable area bigger */
                onClick={() =>
                  setOptions(produceArrayStateSingle(optionsState, item.itemOptionId))
                }
              >
                <DotTextContainer themeContext={themeContext}>
                  {(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? item.title : item.title2 ? item.title2 : item.title}<br />
                  <DotPrice>{item.addPrice ? " +" + currencySymbol + " " + item.addPrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1) : ""}
                  </DotPrice>
                </DotTextContainer>
                <DotButtonContainer>
                  <DotButton
                    index={index}
                    title={(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? item.title : item.title2 ? item.title2 : item.title}
                    activeState={item.active}
                    array={optionsState}
                    setActiveState={() => { }}
                    produceNewState={() => { }}
                  />
                </DotButtonContainer>
              </ArrayOption>
            ))}
          </MenuInfoOptionContainer>
        );
      }

      case OPTION_DOT_MULTIPLE:
        {
          return (
            <MenuInfoOptionContainer>
              {optionsState.map((item, index) => (
                <ArrayOption
                  themeContext={themeContext}
                  key={item.title + index}
                  /* Make clickable area bigger */
                  onClick={() =>
                    setOptions(produceArrayStateMulti(optionsState, item.itemOptionId))
                  }
                >
                  <DotTextContainer themeContext={themeContext}>
                    {(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? item.title : item.title2 ? item.title2 : item.title}<br />
                    <DotPrice>{item.addPrice ? " +" + currencySymbol + " " + item.addPrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1) : ""}
                    </DotPrice>
                  </DotTextContainer>
                  <DotButtonContainer>
                    <DotButton
                      index={index}
                      title={(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? item.title : item.title2 ? item.title2 : item.title}
                      activeState={item.active}
                      array={optionsState}
                      setActiveState={() => { }}
                      produceNewState={() => { }}
                    />
                  </DotButtonContainer>
                </ArrayOption>
              ))}
            </MenuInfoOptionContainer>
          );
        }

      default: {
        return <div>Error</div>;
      }
    }
  };

  //Option Group Label
  const labelComponentType = (labelType) => {

    switch (labelType) {
      case LABEL_ONLY: {
        return (
          <TitleContainer themeContext={themeContext}>
            {`${(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? optionTitle : optionTitle2} ${optionRequired ? "*" : ""}`}
          </TitleContainer>
        );
      }
      case LABEL_ONLY_MULTI: {
        return (
          <React.Fragment>
            <TitleContainer themeContext={themeContext}>
              {`${(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? optionTitle : optionTitle2} ${optionRequired ? `*` : ""}`}
              {(optionRequired) &&
                <TitleSelect isAllSelect={minQty - selected}>
                  {"select " + (selected) + "/" + minQty}
                </TitleSelect>
              }
            </TitleContainer>
            {optionPriceHolder > 0 &&
              <PriceContainer
                themeContext={themeContext}
              >{`${currencySymbol} ${optionPriceHolder}`}</PriceContainer>
            }
          </React.Fragment>
        );
      }
      case LABEL_DOT: {
        return (
          <React.Fragment>
            <TitleContainer themeContext={themeContext}>
              {`${(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? optionTitle : optionTitle2} ${optionRequired ? "*" : ""}`}
            </TitleContainer>
            <DotButtonContainer
              /* Make clickable area bigger */
              onClick={() => {
                setActiveLabelButton(activeLabel);
              }}
            >
              <DotButton activeState={activeLabel} setActiveState={() => { }} />
            </DotButtonContainer>
          </React.Fragment>
        );
      }
      case LABEL_PRICE: {
        return (
          <React.Fragment>
            <TitleContainer themeContext={themeContext}>
              {`${(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? optionTitle : optionTitle2} ${optionRequired ? "*" : ""}`}
            </TitleContainer>
            {optionPriceHolder > 0 &&
              <PriceContainer
                themeContext={themeContext}
              >{`+${currencySymbol} ${optionPriceHolder}`}</PriceContainer>
            }
          </React.Fragment>
        );
      }
      default: {
        return <div>Error</div>;
      }
    }
  };

  return (
    <React.Fragment>
      <MenuInfoHeaderContainer themeContext={themeContext} id={menuIndexParent + "" + menuIndex}>
        {labelComponentType(isMultiple ? 3 : labelType)}
      </MenuInfoHeaderContainer>
      {activeLabel ? (
        <React.Fragment>{optionComponentType(optionType)}</React.Fragment>
      ) : (
        <React.Fragment />
      )}
    </React.Fragment>
  );
};
