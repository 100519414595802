import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../../contexts/Context";
import axios from "axios";
import { TbDiscount2  } from 'react-icons/tb';
import promo from './Promotion';
import Modal from '../optioncomponents/Modal';
import { IoTrashBinOutline, IoTicketOutline  } from "react-icons/io5";
const VoucherField = () => {
    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { lanIndex, token, cart, pm, vouchers, finalOrder } = React.useContext(UserContext);
    const { addToCart, addVoucher, addPromotion, setFinalOrder } = React.useContext(ActionContext);
    const [promoCode, setPromoCode] = useState('');
    const [error, setError] = useState([]);
    const [showError, setShowError] = useState(false);

    const submitVoucher = async () => {
        //send to voucher
        await axios.post("/v2/api/voucher", { promoCode }, { headers: { Authorization: "Bearer " + token } })
            .then(async function (response) {
                let voucher = response.data.voucherData;
                let promotion = response.data.promotion;
                if (promotion !== null) {
                    if (pm) {
                        pm.push(promotion);
                    } else {
                        pm = [promotion];
                    }
                }
                addVoucher([voucher]);
                localStorage.setItem("vouchers", JSON.stringify([voucher]));
                addPromotion(pm);
                let newCart = JSON.parse(JSON.stringify(cart));
                newCart = promo.clearPromotion(newCart);
                newCart = promo.applyPromotion(pm, newCart);

                addToCart(newCart);
                setPromoCode('');

                if (finalOrder) {
                    finalOrder.vouchers = [voucher];
                    setFinalOrder(finalOrder);
                }
            })
            .catch(function (response) {
                console.log(response);
                setError([{ errorMessage: "Voucher is not Applicable" }]);
                setShowError(true);
            });
    }
    const handleChange = (value, name) => {
        setPromoCode(value);
    };

    const handleVoucherRemove = (r, i) => () => {
        let newPromo = JSON.parse(JSON.stringify(pm));
        newPromo = newPromo.filter((p) => !p.isVoucher);
        addVoucher([]);
        localStorage.setItem("vouchers", JSON.stringify([]));
        addPromotion(newPromo);
        let newCart = JSON.parse(JSON.stringify(cart));
        newCart = promo.clearPromotion(newCart);
        newCart = promo.applyPromotion(newPromo, newCart);
        addToCart(newCart);
        if (finalOrder) {
            finalOrder.vouchers = [];
            setFinalOrder(finalOrder);
        }
    }

    return (
        <React.Fragment>
            {(vouchers && vouchers.length > 0) ?
                <VoucherContainer themeContext={themeContext}>
                    {vouchers.map((r, i) =>
                        <RedeemContent key={i}>
                            <RedeemIcon>
                                <TbDiscount2 fontSize={24} />
                            </RedeemIcon>
                            <RedeemTitle>
                                {r.voucherTitle}
                                <SubTitle>
                                    {r.voucherNo}
                                </SubTitle>
                            </RedeemTitle>
                            <RedeemRemove onClick={handleVoucherRemove(r, i)}>
                                <IoTrashBinOutline fontSize={20} color={themeContext.fontAlert} />
                            </RedeemRemove>
                        </RedeemContent>
                    )}
                </VoucherContainer>
                :
                <VoucherContainer themeContext={themeContext}>
                    <RedeemIcon>
                        <IoTicketOutline fontSize={24} />
                    </RedeemIcon>
                    <InputWrapper>
                        <ModalContentText
                            fontWeight={400}
                            color={"black"}
                        >
                            {languageContext[lanIndex].promoCode}
                        </ModalContentText>
                        <VoucherWrapper>
                            <Input width={100} value={promoCode} onChange={(e) => { handleChange(e.target.value, 'promoCode') }} placeholder="Type here" />
                        </VoucherWrapper>
                    </InputWrapper>
                    <SubmitBtn themeContext={themeContext} onClick={() => { submitVoucher(); }}>
                        {languageContext[lanIndex].apply}
                    </SubmitBtn>
                </VoucherContainer >
            }
            <div style={{ marginLeft: -20, position: "fixed", zIndex: 3 }}>
                <Modal show={showError} setShow={setShowError} error={error} />
            </div>
        </React.Fragment>
    );
};

export default VoucherField;
const VoucherContainer = styled.div`
    display:flex;
    width: 100%;
    margin-bottom: 8px;
    align-items: center;
    
    border-bottom: 1px solid ${(props) => props.themeContext.borderColor};
    
`;
const SubTitle = styled.div`
    font-size: 12px;
    color: grey;
`;
const ModalContentText = styled.div`
   font-size: 12px;
`;
const SubmitBtn = styled.div`
    flex-grow:1;
    border-radius: 7px;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 70%);
    padding: 8px 16px;
    color: ${(props) => props.themeContext.cartFont};
    background-color: ${(props) => props.themeContext.cartColor1};
  
`;

const VoucherWrapper = styled.div`
    display: flex;
`;
const InputWrapper = styled.div`
    width: 100%;
    margin-right:  20px;
`;

const Input = styled.input`
  height: 30px;
  border-radius: 4px;
  padding-left: 16px;
  font-size: 14px;
  border: 1px solid #aaa;
  margin-bottom: 10px;
  width: ${(props) => props.width}%;
`;

const RedeemIcon = styled.div`
  display: flex;
  margin-right: 6px;
`;
const RedeemRemove = styled.div`
    padding: 8px;
    background: white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);
    border-radius: 6px;
    display: flex;
`;
const RedeemTitle = styled.div`
  margin-left: 4px;
  flex-grow: 1;
  width: 100%;
`;
const RedeemContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 4px;
  padding: 8px 0px;
`;
const RedeemTxt = styled.div`
  margin-left: 4px;
`;