import React, { useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext } from "../../contexts/Context";
import { BsCreditCard2BackFill, BsCashStack } from "react-icons/bs";
import { MdCardMembership } from "react-icons/md";
const Modal = ({
  show,
  setShow,
  lanIndex,
  payType,
  setPayType,
  confirmPTCheckOut,
  total,
  payAtCounter
}) => {
  //State
  const displayShow = show ? "grid" : "none";
  const themeContext = React.useContext(ThemeContext);
  const languageContext = React.useContext(LanguageContext);
  const [warningMsg, setWarningMsg] = useState(false);
  const { isOnlinePayMinConsumption, onlinePayMinConsumptionVal, paymentSelectionMessage, isInRoom, advocadoStoreCredit, haveToPayOnline } = React.useContext(UserContext);
  const modalButton = () => {
    return (
      <ModalButtonContainer>
        <ModalConfirmButton
          themeContext={themeContext}
          backgroundColor="#d9d9d9"
          onClick={() => {
            setShow(false);
          }}
        >
          <ModalText
            fontSize={16}
            fontWeight={600}
            color="#4c4c4c"
          >
            {languageContext[lanIndex].cancel}
          </ModalText>
        </ModalConfirmButton>
        <ModalConfirmButton
          color="#fff"
          backgroundColor={themeContext.fontPrimary}
          themeContext={themeContext}
          onClick={() => {
            if (payType == 0 && isOnlinePayMinConsumption && total < onlinePayMinConsumptionVal) return;
            if (payType == -1) {
              setWarningMsg(true);
              return;
            }
            setShow(false);
            confirmPTCheckOut(payType);
          }}
        >
          <ModalText
            fontSize={16}
            fontWeight={600}
            color={themeContext.cartFont}
          >
            {languageContext[lanIndex].ok}
          </ModalText>
        </ModalConfirmButton>
      </ModalButtonContainer>
    );
  }

  return (
    <ModalContainer display={displayShow}>
      <ModalMain themeContext={themeContext}>
        <ModalTitle themeContext={themeContext}>

          {languageContext[lanIndex].pleasePayment}
          {warningMsg &&
            <div style={{ color: "rgb(207, 0, 13)", fontSize: 12, lineHeight: "12px", marginTop: 8 }}>
              {languageContext[lanIndex].pleasePayment}
            </div>
          }
          <ModalDiv style={{ marginTop: 22, fontSize: 16 }}>
            <ModalSpan onClick={() => { setPayType(0); setWarningMsg(false); }} style={{ color: payType == 0 ? themeContext.primaryColor : '#7474744d' }}>
              {payType == 0 ?
                <BsCashStack size={36} color={themeContext.primaryColor} /> :
                <BsCashStack size={36} color="#7474744d" />}
              <div>Pay Online</div>
            </ModalSpan>
            <Line />
            {(payAtCounter || !haveToPayOnline) &&
              <ModalSpan onClick={() => { setPayType(1); setWarningMsg(false); }} style={{ color: payType == 1 ? themeContext.primaryColor : '#7474744d' }}>
                {payType == 1 ?
                  <BsCreditCard2BackFill size={36} color={themeContext.primaryColor} /> :
                  <BsCreditCard2BackFill size={36} color="#7474744d" />}
                <div>{isInRoom ? 'Room Charge' : (payAtCounter ? 'Pay At Counter' : 'Pay Later')}</div>
                {paymentSelectionMessage && paymentSelectionMessage != null &&
                  <div style={{ color: "rgb(207, 0, 13)", textAlign: "center", fontSize: 12, lineHeight: "12px", marginTop: 8 }}>{`${paymentSelectionMessage}`}</div>
                }
              </ModalSpan>
            }
            {advocadoStoreCredit &&
              <ModalSpan onClick={() => { setPayType(2); setWarningMsg(false); }} style={{ color: payType == 2 ? themeContext.primaryColor : '#7474744d' }}>
                {payType == 2 ?
                  <MdCardMembership size={36} color={themeContext.primaryColor} /> :
                  <MdCardMembership size={36} color="#7474744d" />}
                <div>Advocado Store Credit</div>
              </ModalSpan>
            }
          </ModalDiv>
        </ModalTitle>
        {isOnlinePayMinConsumption && onlinePayMinConsumptionVal > 0 && payType == 0 &&
          <b style={{ color: "#3c3c3c", margin: '10px 0 0 10px' }}>{`${languageContext[lanIndex].minimumConsumptionTip} ${onlinePayMinConsumptionVal}`}</b>
        }

        {modalButton()}
      </ModalMain>
    </ModalContainer>
  );
};

export default Modal;

const ModalContainer = styled.div`
  display: ${(props) => props.display};
  position: fixed;
  place-content: center;
  top: 0;
  width: 100vw;
  max-width: 550px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
`;

const ModalMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  position: relative;

  font-family: ${(props) => props.themeContext.fontFamily};
  background: #fff;
  z-index: 10;
  padding: 20px;
`;
const ModalTitle = styled.div`
  // color:${(props) => props.themeContext.selectedColor};
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  justify-content: center;
`;
const ModalButtonContainer = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
`;

const ModalDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const Line = styled.span`
  width: 1px;
  display: inline-block;
  background: #747474;
  height: 30px;
  margin: 10px;
`
const ModalSpan = styled.span`
  align-items: center;
  text-align: center;
  width: 140px;
  margin: 2%;
  justify-content: space-around;
`
const ModalConfirmButton = styled.div`
  flex: 1;
  background-color: ${(props) => props.backgroundColor};
  margin: 2%;
  margin-bottom: 0;
  padding: 5px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  place-content: center;
  border-radius: 6px;
`;

const ModalText = styled.span`
  align-self: center;
  justify-content: center;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  padding-left: ${(props) => (props.margin ? props.margin : 0)}px;
  padding-right: ${(props) => (props.margin ? props.margin : 0)}px;
`;
