import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../../contexts/Context";
import axios from "axios";
import { IoCloseCircle, IoCallOutline, IoGiftOutline, IoCashOutline } from "react-icons/io5";
import AdvocadoTerm from "./AdvocadoTerm";
// import { adCustomer, campaign2 } from '../../screens/AdCustomer';
const AdvocadoPage = ({ show, setShow }) => {
    const [displayShow, setDisplay] = useState(show ? "grid" : "none");
    const [campaign, setCampaign] = useState({});
    const [loyalty, setLoyalty] = useState(0);
    const [credit, setCredit] = useState(0);
    const [cashback, setCashback] = useState(0);
    const [showTerm, setShowTerm] = useState(false);
    const [term, setTerm] = useState("");
    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { lanIndex, token, userId, advocadoRedeem } = React.useContext(UserContext);
    const { adCustomer } = React.useContext(UserContext);
    const { addAdCustomer } = React.useContext(ActionContext);


    console.log({ show });

    useEffect(() => {
        setDisplay(show ? "flex" : "none");
        // for (let c of campaign2) {
        //     if (c.type == "loyalty") {
        //         for (let r of c.redeem) {
        //             campaign[r.id] = ({
        //                 type: "loyalty",
        //                 description: r.description,
        //                 value: r.redeemPoint,
        //                 isValid: loyalty >= r.redeemPoint,
        //                 terms: c.description
        //             });
        //         }
        //     }
        // }
        // setCampaign(campaign);
        if (token && show) {
            getUserInfo();
            axios.get(`/v2/api/selfOrder/advocado/campaign`, {
                headers: { Authorization: "Bearer " + token },
            })
                .then(function (response) {
                    console.log(response.status);
                    if (response.status == 200) {
                        for (let c of response.data) {
                            if (c.type == "loyalty") {
                                for (let r of c.redeem) {
                                    campaign[r.id] = ({
                                        type: "loyalty",
                                        description: r.description,
                                        value: r.redeemPoint,
                                        isValid: loyalty >= r.redeemPoint,
                                        terms: c.description
                                    });
                                }
                            }
                        }
                        setCampaign(JSON.parse(JSON.stringify(campaign)));
                    }

                })
                .catch(function (response) {
                    //show warning
                });


        }
    }, []);

    const getUserInfo = () => {
        if (token && userId) {
            axios.post(`/v2/api/selfOrder/advocado/user`,
                {
                    userId: userId,
                    phone: adCustomer.phoneNumber,
                    countryCode: adCustomer.countryCallingCode.toString(),
                    pin: adCustomer.pin,
                },
                {
                    headers: { Authorization: "Bearer " + token },
                })
                .then(function (response) {
                    if (response.status == 200) {
                        response.data.pin = adCustomer.pin;
                        localStorage.setItem("adCustomer", JSON.stringify(response.data));
                        addAdCustomer(response.data);
                    }
                })
                .catch(function (response) {
                    //show warning

                })


        }
    }
    useEffect(() => {
        if (!adCustomer) return;
        if (!adCustomer.campaignBalances) return;
        for (let d of adCustomer.campaignBalances) {
            if (d.campaignType == "cashback") {
                setCashback(d.amounts);
            } else if (d.campaignType == "loyalty") {
                setLoyalty(d.points);
            } else if (d.campaignType == 'voucher') {
                if (d.vouchers > 0) {
                    campaign[d.campaignId] = ({
                        type: "voucher",
                        description: d.name,
                        value: d.vouchers,
                        isValid: true,
                        terms: d.description
                    });
                }
            } else if (d.campaignType == "stored-value") {
                setCredit(d.values);
            }
        }
        setCampaign(JSON.parse(JSON.stringify(campaign)));
    }, [adCustomer]);

    if (!adCustomer) {
        return <></>;
    }
    return (
        <React.Fragment>
            <ModalContainer display={displayShow}>
                <IconButton onClick={() => {
                    setShow(false);
                    setDisplay(false);
                }}>
                    <IoCloseCircle size={36} />
                </IconButton>
                <ContentWrapper>
                    <HeaderTitle>{languageContext[lanIndex].memberInfo}</HeaderTitle>
                    <Container>
                        <ItemContainer>
                            <Container>
                                <Subtitle>
                                    {languageContext[lanIndex].name}
                                </Subtitle>
                                <Title>
                                    {`${adCustomer.firstName ? adCustomer.firstName : "-"} ${adCustomer.lastName ? adCustomer.lastName : ""}`}
                                    <div style={{ marginLeft: 4, padding: "0px 4px", border: "1px solid #023047", borderRadius: 4 }}><b>{adCustomer.membership.length > 0 ? adCustomer.membership[adCustomer.membership.length - 1].membershipTagName : ""}</b></div>
                                </Title>
                            </Container>

                        </ItemContainer>
                    </Container>
                    <Container>
                        <ItemContainer>
                            <IoCallOutline size={24} />
                            <TextWrapper>
                                <Subtitle>
                                    {languageContext[lanIndex].phone}
                                </Subtitle>
                                <Title style={{ marginLeft: 4 }}>
                                    {`${adCustomer.countryCallingCode} ${adCustomer.phoneNumber}`}
                                </Title>
                            </TextWrapper>
                        </ItemContainer>
                    </Container>
                    <PContainer>
                        {cashback > 0 &&
                            <PItemContainer>
                                <IoCashOutline size={24} />
                                <TextWrapper>
                                    <Subtitle>
                                        {languageContext[lanIndex].cashback}
                                    </Subtitle>
                                    <Title>
                                        {`${cashback}`}
                                    </Title>
                                </TextWrapper>
                            </PItemContainer>
                        }
                        <PItemContainer>
                            <IoGiftOutline size={24} />
                            <TextWrapper>
                                <Subtitle>
                                    {languageContext[lanIndex].loyalty}
                                </Subtitle>
                                <Title>
                                    {`${loyalty}`}
                                </Title>
                            </TextWrapper>
                        </PItemContainer>
                        {credit > 0 &&
                            <PItemContainer>
                                <IoGiftOutline size={24} />
                                <TextWrapper>
                                    <Subtitle>
                                        Store Credit
                                    </Subtitle>
                                    <Title>
                                        {`${credit ? credit.toFixed(2) : "-"}`}
                                    </Title>
                                </TextWrapper>
                            </PItemContainer>

                        }
                    </PContainer>
                    <Title style={{ marginTop: 16, marginBottom: 4 }}>Available Rewards</Title>
                    <CContainer>
                        {campaign && Object.keys(campaign).map((c, i) =>
                            <CampaignWrapper onClick={() => { setTerm(campaign[c].terms); setShowTerm(true); }}
                                key={i}
                                isValid={campaign[c].type == "loyalty" ? loyalty >= campaign[c].value : campaign[c].isValid}>
                                <CType type={campaign[c].type}>
                                    {campaign[c].type}
                                </CType>
                                <Description>
                                    {campaign[c].description}
                                </Description>
                                <Value>
                                    {campaign[c].value}
                                </Value>
                            </CampaignWrapper>
                        )}
                    </CContainer>
                    <Disclaimer>
                        **{advocadoRedeem ? languageContext[lanIndex].rewardRedeemDisclaimer :
                            languageContext[lanIndex].rewardDisclaimer}**
                    </Disclaimer>
                    <ButtonWrapper>
                        <Button themeContext={themeContext} onClick={() => {
                            setShow(false);
                            localStorage.removeItem("adCustomer");
                            addAdCustomer("");
                        }} >
                            {languageContext[lanIndex].logout}
                        </Button>

                    </ButtonWrapper>
                </ContentWrapper>
            </ModalContainer>
            {showTerm &&
                <AdvocadoTerm
                    show={showTerm}
                    setShow={setShowTerm}
                    terms={term}
                />
            }
        </React.Fragment >
    );
}

export default AdvocadoPage;
const Disclaimer = styled.div`
    font-size: 12px;
    margin-top: 8px;
`;
const HeaderTitle = styled.div`
    font-size: 24px;
    font-weight: 500;
`;
const Container = styled.div`
    width: 100%;
    margin-bottom: 8px;
`;

const Subtitle = styled.div`
    font-size: 12px;
    font-weight: 300;
`;
const Title = styled.div`
    display: flex;
    font-size: 16px;
    font-weight: 400;
`;
const TextWrapper = styled.div`
    margin-left: 8px;
`;
const PItemContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px;
    margin: 4px;
    border: 1px solid;
    border-radius: 8px;
`;
const ItemContainer = styled.div`
    display: flex;
    align-items: flex-end;
    width: 45%;
    padding: 8px;

`;
const PContainer = styled.div`
    display: flex;
    width: 100%;
`;
const CContainer = styled.div`
    flex-grow:1;
    width: 100%;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
`;
const CampaignWrapper = styled.div`
    display: flex;
    position: relative;
    width: 95%;
    padding: 16px 4px;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.4);
    margin: 0px 4px;
    margin-top: 16px;
    opacity:${(props) => props.isValid ? 1 : 0.4};
`;
const CType = styled.div`
    position: absolute;
    top: -8px;
    left: -4px;
    color: white;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.4);
    background: ${(props) => {
        switch (props.type) {
            case "loyalty":
                return "#4caf50";
            case "voucher":
                return "#f44336";
        }
    }};
`;
const Description = styled.div`
    flex-grow:1;
`;
const Value = styled.div`
    padding: 0px 8px;
    font-size: 16px;
    font-weight: 500;
`;
const ButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;
const Button = styled.div`
    display: flex;
    width: 120px;
    margin: 8px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    border-radius: 25px;
    line-height: 40px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
   
`;

const IconButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  background: white;
  z-index: 99;
`;
const ContentWrapper = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    overflow-y: auto;
    margin-top: 0px;
`;
const ModalContainer = styled.div`
    display: ${(props) => props.display};
    position: fixed;
    flex-direction: column;
    background: #fff;
    top: 0;

    width: 100vw;
    max-width: 550px;
    height: 100dvh;
    z-index: 9;

    animation: modalOrder .1s;
    animation-timing-function: ease-out;
    @keyframes modalOrder {
        0%  {
        transform: translateY(350px);
        opacity: 0;
        }
        
        100%  {
        transform: translateY(0px);
        opacity: 1;
        }
        
    }
  `;

