import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../../contexts/Context";
import axios from "axios";
import Modal from '../optioncomponents/Modal';
import { useLocation } from "@reach/router"
const CustomerReset = () => {
    const [displayShow, setDisplay] = useState("flex");

    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { lanIndex } = React.useContext(UserContext);

    const [password, setPassword] = useState("");
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState([]);

    const handleChange = (e, name) => {
        setPassword(e);
    }
    let iv, code;
    let url = useLocation().search.split("?");
    if (url.length > 1) {
        let params = url[1].split("&");

        for (let pr of params) {
            if (pr.split("=")[0] == "iv") {
                iv = pr.split("=")[1];
            } else if (pr.split("=")[0] == "code") {
                code = pr.split("=")[1];
            }
        }
    }
    const submitReset = () => {

        axios.post(`/v2/api/customer/passwordReset`, { password, iv, code }, {})
            .then(function (response) {
                console.log(response.status);
                if (response.status == 200) {
                    setError([{ errorMessage: "Update Successfully" }]);
                    setShowError(true);
                }

            })
            .catch(function (response) {
                //show warning
                console.log("RETURN catch");
                setError([{ errorMessage: "Something went wrong. Update Fail. Please try again" }]);
                setShowError(true);
            })
    }

    return (
        <React.Fragment>
            <ModalContainer display={displayShow}>
                <Title>
                    Reset Password
                </Title>
                <ContentWrapper>

                    <InputWrapper>
                        <ModalContentText
                            fontWeight={400}
                            color={"black"}
                        >
                            New Password
                        </ModalContentText>
                        <PhoneWrapper>
                            <Input width={100} value={password} type="password" onChange={(e) => { handleChange(e.target.value, 'password') }} />
                        </PhoneWrapper>
                    </InputWrapper>
                    <ButtonWrapper>
                        <Button themeContext={themeContext} onClick={() => {
                            submitReset();
                        }} >
                            Submit
                        </Button>

                    </ButtonWrapper>
                </ContentWrapper>
            </ModalContainer>
            <Modal show={showError} setShow={setShowError} error={error} />
        </React.Fragment >
    );
}

export default CustomerReset;
const ButtonWrapper = styled.div`
    display: flex;
`;
const Button = styled.div`
    display: flex;
    width: 120px;
    margin: 8px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    border-radius: 25px;
    line-height: 40px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
   
`;

const Input = styled.input`
  height: 30px;
  border-radius: 4px;
  padding-left: 16px;
  font-size: 16px;
  border: 1px solid #aaa;
  margin-bottom: 10px;
  width: ${(props) => props.width}%;
`;


const PhoneWrapper = styled.div`
    display: flex;
    width: 100%;
`;
const InputWrapper = styled.pre`
   
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`;


const IconButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  background: white;
  z-index: 99;
`;
const ModalContentText = styled.div`
    padding: 8px;
    white-space: pre-wrap;
`;
const Title = styled.div`
    font-size: 18px;
    font-weight: 500;
    margin-top: 24px;
    margin-left: 8px;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    overflow-y: auto;
    margin-top: 24px;
    padding: 24px 24px;
`;

const ModalContainer = styled.div`
    display: ${(props) => props.display};
    position: fixed;
    flex-direction: column;
    background: #fff;
    top: 0;

    width: 100vw;
    max-width: 550px;
    height: 100dvh;
    z-index: 9;
  `;

