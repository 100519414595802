import React from "react";
import styled from "@emotion/styled";
import "../styles/styles.css";
import MenuItem from "./optioncomponents/MenuItem";
import { ThemeContext, UserContext } from "../contexts/Context";
import { navigate } from '@reach/router';

const Cell = ({ columnIndex, rowIndex, data, containerWidth, containerHeight }) => {
    //Data passed as props to List Component
    const { item, setSelectedMenu, setSelectedCategory, theme, selectedItems, addItemDirect,
        cart, removeFromCart, setLastPos, cp, menuSelectionFormRef } = data;
    const { lanIndex, currencySymbol, isLanReverse } = React.useContext(UserContext);
    const handleSelect = () => {
        let it = item;
        if (!it.isCategory) {
            //To Determine if has options
            if (addItemDirect && (!it.optionArray || it.optionArray.length == 0)) {
                it.qty = 1;
                setSelectedMenu(it);
                menuSelectionFormRef.current = JSON.parse(JSON.stringify(it));
            } else {
                setSelectedMenu(it);
                menuSelectionFormRef.current = JSON.parse(JSON.stringify(it));

                setLastPos(document.getElementById('menu_scroll').scrollTop);
                navigate('/menu/item/' + it.codeId);
            }
        } else {
            setSelectedCategory(it);
        }
    };


    const minusQty = (e) => {
        e.stopPropagation();
        let it = item;
        //remove the latest one
        let newCart = JSON.parse(JSON.stringify(cart));
        for (let i = newCart.length - 1; i >= 0; i--) {
            if (newCart[i].codeId == it.codeId) {
                newCart[i].qty--;
                let minQty = newCart[i].minQty ? newCart[i].minQty - 1 : 0;
                if (newCart[i].qty <= minQty) {
                    newCart.splice(i, 1);
                }
                localStorage.setItem("order", JSON.stringify(newCart));
                removeFromCart(newCart, i);
                break;
            }
        }

    }

    return (
        <MenuItem
            key={"" + columnIndex + (rowIndex)} /* ??? */
            item={item}
            containerWidth={containerWidth}
            containerHeight={containerHeight}
            handleOnClick={handleSelect}
            addQty={handleSelect}
            minusQty={minusQty}
            theme={theme}
            lanIndex={lanIndex}
            isLanReverse={isLanReverse}
            selectedItems={selectedItems}
            currencySymbol={currencySymbol}
            addItemDirect={addItemDirect}
            cp={cp}
            shouldHide={true}

        />
    );
};


export default React.memo(Cell);