import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../../contexts/Context";
import axios from "axios";
import { IoCloseCircle } from "react-icons/io5";

const AdvocadoTerm = ({ show, setShow, terms }) => {
    const [displayShow, setDisplay] = useState(show ? "flex" : "none");

    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { lanIndex, advocadoRedeem } = React.useContext(UserContext);

    useEffect(() => {
        document.getElementById("term").innerHTML = terms;
    }, []);
    return (
        <React.Fragment>
            <ModalContainer display={displayShow}>
                <IconButton onClick={() => {
                    setShow(false);
                    setDisplay(false);
                }}>
                    <IoCloseCircle size={36} />
                </IconButton>
                <Title>
                    Terms and Condition
                </Title>
                <ContentWrapper>
                    <InputWrapper>
                        <ModalContentText
                            fontWeight={400}
                            color={"black"}
                            id="term"
                        >

                        </ModalContentText>
                    </InputWrapper>
                    <Disclaimer>
                        **{advocadoRedeem ? languageContext[lanIndex].rewardRedeemDisclaimer :
                            languageContext[lanIndex].rewardDisclaimer}**
                    </Disclaimer>
                    <ButtonWrapper>
                        <Button themeContext={themeContext} onClick={() => {
                            setShow(false)
                        }} >
                            {languageContext[lanIndex].close}
                        </Button>

                    </ButtonWrapper>
                </ContentWrapper>
            </ModalContainer>
        </React.Fragment >
    );
}

export default AdvocadoTerm;
const Disclaimer = styled.div`
    border-radius: 8px;
    border: 1px solid #f44336;
    font-weight: 400;
    font-size: 16px;
    padding: 16px 8px;
    margin-bottom: 16px;
`;
const ButtonWrapper = styled.div`
    display: flex;
`;
const Button = styled.div`
    display: flex;
    width: 120px;
    margin: 8px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    border-radius: 25px;
    line-height: 40px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
   
`;

const InputWrapper = styled.pre`
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
`;


const IconButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  background: white;
  z-index: 99;
`;
const ModalContentText = styled.div`
    padding: 8px;
    white-space: pre-wrap;
`;
const Title = styled.div`
    font-size: 18px;
    font-weight: 500;
    margin-top: 24px;
    margin-left: 8px;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    overflow-y: auto;
    margin-top: 24px;
`;

const ModalContainer = styled.div`
    display: ${(props) => props.display};
    position: fixed;
    flex-direction: column;
    background: #fff;
    top: 0;

    width: 100vw;
    max-width: 550px;
    height: 100dvh;
    z-index: 9;

    animation: modalOrder .1s;
    animation-timing-function: ease-out;
    @keyframes modalOrder {
        0%  {
        transform: translateY(350px);
        opacity: 0;
        }
        
        100%  {
        transform: translateY(0px);
        opacity: 1;
        }
        
    }
  `;

