import React from "react";
import styled from "@emotion/styled";
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from "react-icons/io";
const QuantityRecAdjust = ({
  marginTop,
  marginBottom,
  quantity,
  size,
  adjustQuantity
}) => {
  
  const quantityEdit = (value) => {
    let finalQuantity = 0;
    if (value > 0) {
      if (quantity < 99) {
        finalQuantity = quantity + 1;
      } else {
        finalQuantity = 99;
      }
    } else if (value < 0) {
      if (quantity > 0) {
        finalQuantity = quantity - 1;
      } else {
        finalQuantity = 0;
      }
    }
    
    adjustQuantity(finalQuantity);
  };

  return (
    <React.Fragment>
    
      <RootContainer
        size={size}
      >
        <QtyButton onClick={(e) => { e.preventDefault(); e.stopPropagation(); quantityEdit(-1) }}>
          <IoIosRemoveCircleOutline size={32} />
        </QtyButton>
        <QuantityContainer>{quantity}</QuantityContainer>
        <QtyButton onClick={(e) => { e.preventDefault(); e.stopPropagation(); quantityEdit(1) }}>
          <IoIosAddCircleOutline size={32} />
        </QtyButton>
      </RootContainer>
    </React.Fragment>
  );
};

export default QuantityRecAdjust;

const RootContainer = styled.div`
  display: grid;
  grid-template-columns: 35% 30% 35%;
  justify-items: center;
  margin: 8px;
  height: ${(props) => (props.size ? props.size : 30)}px;
`;

const QtyButton = styled.div`
  display: flex;
`;
const QuantityContainer = styled.div`
  display: grid;
  align-self: center;
  place-content: center;
  width: ${(props) => (props.size ? props.size : 30)}px;
  height: ${(props) => (props.size ? props.size : 30)}px;
  border-radius: ${(props) => (props.size ? props.size / 6 : 30 / 6)}px;
  font-size: 20px;
  font-weight: 600;
  color: black;
  background-color: white;
`;