import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext } from "../../contexts/Context";
import axios from "axios";
import { IoCloseCircle } from "react-icons/io5";

const AdvocadoPin = ({ show, setShow, setError, setShowError, handleCallback, forgetPass,
    phoneNumber, countryCallingCode, pin, setPin, setMember, setNew, setIsPin, isNew }) => {
    const [displayShow, setDisplay] = useState(show ? "grid" : "none");
    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { lanIndex, token, userId } = React.useContext(UserContext);

    const [warningMsg, setWarningMsg] = useState("");
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        setDisplay(show ? "flex" : "none");
    }, [show]);


    const memberSubmit = () => {
        if (token && userId && show) {
            axios.post(`/v2/api/selfOrder/advocado/pin`, {
                phone: phoneNumber,
                countryCode: countryCallingCode.toString(),
                pin: pin,
                isNew: isNew ? 1 : 0
            }, {
                headers: { Authorization: "Bearer " + token },
            })
                .then(function (response) {
                    setProcessing(false);
                    console.log(response.status);
                    if (response.status == 200) {
                        setNew(response.data.isNew);
                        setIsPin(response.data.isPin);
                        // setMember(response.data.isMember);
                        setWarningMsg(response.data.errorMsg);
                        setShow(false);
                        handleCallback();
                    } else {
                        console.log("RETURN then");
                        setError([{ errorMessage: "Something went wrong. Please try again" }]);
                        setShowError(true);
                    }

                })
                .catch(function (response) {
                    //show warning
                    console.log(response);
                    setProcessing(false);
                    console.log("RETURN catch");
                    setError([{ errorMessage: "Something went wrong. Please try again" }]);
                    setShowError(true);
                })
        }
    }

    return (
        <React.Fragment>
            <ModalContainer display={displayShow}>

                <ContentWrapper>
                    <IconButton onClick={() => {
                        setShow(false);
                        setDisplay(false);
                    }}>
                        <IoCloseCircle size={36} />
                    </IconButton>
                    <WarningMsg themeContext={themeContext}>
                        {warningMsg}
                    </WarningMsg>
                    <Title>
                        Enter 4 Digit Pin
                    </Title>
                    <InputWrapper>
                        <PhoneWrapper>
                            <Input type="password" width={75} value={pin} onChange={(e) => { setPin(e.target.value) }} style={{ paddingRight: 24 }} pattern="[0-9]*" inputmode="numeric" />
                        </PhoneWrapper>
                    </InputWrapper>
                    {!isNew &&
                        <InputWrapper>
                            <FgButton onClick={forgetPass}>
                                {languageContext[lanIndex].forgetPwd}
                            </FgButton>
                        </InputWrapper>
                    }
                    <ButtonWrapper>
                        <Button themeContext={themeContext} onClick={() => {
                            if (pin == "") {
                                setWarningMsg("Pin is required");
                                return;
                            } else if (pin.length < 4 || pin.length > 4) {
                                setWarningMsg("Please input 4 digit pin");
                                return;
                            }
                            if (processing) return;
                            setProcessing(true);
                            memberSubmit();
                        }} >
                            {languageContext[lanIndex].generalSubmit}
                        </Button>

                    </ButtonWrapper>
                </ContentWrapper>
            </ModalContainer>
        </React.Fragment >
    );
}

export default AdvocadoPin;
const WarningMsg = styled.div`
    color: ${(props) => props.themeContext.fontAlert};
`;
const PhoneWrapper = styled.div`
    display: flex;
    width: 80%;
`;
const Input = styled.input`
  height: 30px;
  border-radius: 4px;
  padding-left: 16px;
  font-size: 16px;
  border: 1px solid #aaa;
 
  width: ${(props) => props.width}%;
`;

const FgButton = styled.div`
    
    font-size: 12px;
    text-decoration: underline;
`;

const InputWrapper = styled.pre`
   
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
`;


const IconButton = styled.div`
    display:flex;
    justify-content: flex-end;
    margin-top: 16px;
    width: 100%;
    background: white;
    z-index: 99;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: 500;

`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    
    width: 70%;
    overflow-y: auto;
    margin-top: 48px;
    background: white;
    border-radius: 16px;
    padding: 0px 16px;
`;
const Button = styled.div`
    display: flex;
    width: 120px;
    margin: 8px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    border-radius: 25px;
    line-height: 40px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
   
`;

const ButtonWrapper = styled.div`
    display: flex;
    
    margin-bottom: 16px;
`;
const ModalContainer = styled.div`
    display: ${(props) => props.display};
    position: fixed;
    flex-direction: column;
    background: rgba(0,0,0,0.6);
    top: 0;

    width: 100vw;
    max-width: 550px;
    height: 100dvh;
    z-index: 9;
    justify-content: center;
    align-items: center;
    animation: modalOrder .1s;
    animation-timing-function: ease-out;
    @keyframes modalOrder {
        0%  {
        transform: translateY(350px);
        opacity: 0;
        }
        
        100%  {
        transform: translateY(0px);
        opacity: 1;
        }
        
    }
  `;

