import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../../contexts/Context";
import axios from "axios";
import { IoCloseCircle } from "react-icons/io5";

const CustomerForgot = ({ show, setShow, setError, setShowError }) => {
    const [displayShow, setDisplay] = useState(show ? "grid" : "none");

    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { lanIndex, token, userId } = React.useContext(UserContext);

    const [email, setEmail] = useState("");

    useEffect(() => {
        setDisplay(show ? "flex" : "none");
    }, [show]);


    const handleChange = (e, name) => {
        setEmail(e);
    }
    const submitForgot = () => {
        if (token && userId && show) {
            axios.post(`/v2/api/customer/forgotPwd`, { email }, {
                headers: { Authorization: "Bearer " + token },
            })
                .then(function (response) {
                    console.log(response.status);
                    if (response.status == 200) {
                        setError([{ errorMessage: "An email has sent to your mailbox. Please check" }]);
                        setShowError(true);
                    }

                })
                .catch(function (error) {
                    //show warning
                    console.log("RETURN catch");
                    if (error.response && error.response.status == 402) {
                        setError([{ errorMessage: "Email cannot be found. Please contact staff for assistance" }]);
                        setShowError(true);
                    } else {
                        console.log("RETURN then");
                        setError([{ errorMessage: "Something went wrong. Please try again" }]);
                        setShowError(true);
                    }
                })


        }
    }

    return (
        <React.Fragment>
            <ModalContainer display={displayShow} themeContext={themeContext}>
                <IconButton onClick={() => {
                    setShow(false);
                    setDisplay(false);
                }}>
                    <IoCloseCircle size={36} />
                </IconButton>
                <Title>
                    Forgot Password
                </Title>
                <ContentWrapper>

                    <InputWrapper>
                        <ModalContentText
                            fontWeight={400}
                            color={"black"}
                        >
                            {languageContext[lanIndex].email}
                        </ModalContentText>
                        <PhoneWrapper>
                            <Input width={100} value={email} type="email" onChange={(e) => { handleChange(e.target.value, 'email') }} />
                        </PhoneWrapper>
                    </InputWrapper>
                    <ButtonWrapper>
                        <Button themeContext={themeContext} onClick={() => {
                            submitForgot();
                        }} >
                            {languageContext[lanIndex].ok}
                        </Button>

                    </ButtonWrapper>
                </ContentWrapper>
            </ModalContainer>

        </React.Fragment >
    );
}

export default CustomerForgot;
const ButtonWrapper = styled.div`
    display: flex;
`;
const Button = styled.div`
    display: flex;
    width: 120px;
    margin: 8px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    background-color:${(props) => props.themeContext.primaryColor};
    color: ${(props) => props.themeContext.cartFont};
    border-radius: 25px;
    line-height: 40px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
   
`;

const Input = styled.input`
  height: 30px;
  border-radius: 4px;
  padding-left: 16px;
  font-size: 16px;
  border: 1px solid #aaa;
  margin-bottom: 10px;
  width: ${(props) => props.width}%;
`;


const PhoneWrapper = styled.div`
    display: flex;
    width: 100%;
`;
const InputWrapper = styled.pre`
   
    width: 100%;
    
    overflow-y: auto;
    overflow-x: hidden;
`;


const IconButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  
  z-index: 99;
`;
const ModalContentText = styled.div`
    padding: 8px;
    white-space: pre-wrap;
`;
const Title = styled.div`
    font-size: 18px;
    font-weight: 500;
    margin-top: 24px;
    margin-left: 8px;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    overflow-y: auto;
    margin-top: 24px;
    padding: 24px 24px;
`;

const ModalContainer = styled.div`
    display: ${(props) => props.display};
    position: fixed;
    flex-direction: column;
    background:${(props) => props.themeContext.backgroundColor};
    top: 0;

    width: 100vw;
    max-width: 550px;
    height: 100dvh;
    z-index: 9;

    animation: modalOrder .1s;
    animation-timing-function: ease-out;
    @keyframes modalOrder {
        0%  {
        transform: translateY(350px);
        opacity: 0;
        }
        
        100%  {
        transform: translateY(0px);
        opacity: 1;
        }
        
    }
  `;

