import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { IoIosAdd, IoIosRemove } from "react-icons/io";

import { UserContext, ThemeContext } from "../../contexts/Context";
import QuantityRecAdjust from "./QuantityRecAdjust";
import ItemSize3 from "./ItemSize3";
const MenuItem = ({
  item,
  handleOnClick,
  containerWidth,
  containerHeight,
  active,
  isSetMeal,
  hasRecommended,
  adjustQuantity,
  theme,
  lanIndex,
  isLanReverse,
  currencySymbol,
  selectedItems,
  addQty,
  minusQty,
  addItemDirect,
  cp,
  shouldHide
}) => {
  const themeContext = React.useContext(ThemeContext);
  const { tax, displayNetAmount, hidePrice, customer } = React.useContext(UserContext);
  const ref = useRef(null);
  const [codeId, setCodeId] = useState("");
  const [hasCustomer, setHasCustomer] = useState(false);
  const [hasPricebook, setHasPricebook] = useState(false);
  useEffect(() => {
    if (item.codeId != codeId) {
      if (!shouldHide) return;
      if (ref.current == null) return;
      if (ref.current.getBoundingClientRect().top <= window.innerHeight) {
        ref.current.style.backgroundImage = `url('${ref.current.dataset.bg}')`;
      }
      setCodeId(item.codeId);
      if (typeof item.pricebook != 'undefined' && Object.keys(item.pricebook).length > 0 && item.pricebook[Object.keys(item.pricebook)[0]].minQty == 1) {
        setHasPricebook(true);
        if (customer)
          setHasCustomer(true);
      }
    }
  }, [codeId, item.codeId]);

  useEffect(() => {
    if (!shouldHide) return;
    if (ref.current == null) return;
    if (ref.current.getBoundingClientRect().top < window.innerHeight) {
      ref.current.style.backgroundImage = `url('${ref.current.dataset.bg}')`;
      ref.current.classList.remove('item-img');
    }

  }, []);

  return (
    <>
      <ItemContainer
        height={containerHeight}
        width={containerWidth}
        hideDisplay={item.hideDisplay}
        active={active}
        size={item.size}
        themeContext={themeContext}
        theme={theme}
        cp={cp}
        hasRecommended={hasRecommended}
        style={{ width: item.size == 1 || item.size == 2 || item.size == 3 ? containerWidth * 2 : containerWidth, margin: '' }}
        onClick={(e) => {
          e.stopPropagation();
          if (!item.isSoldOut) {
            handleOnClick();
          }
        }}
      >
        {item.size == 3 && //horizontal new layout
          <ItemSize3
            lanIndex={lanIndex}
            isLanReverse={isLanReverse}
            item={item}
            shouldHide={shouldHide}
            isSetMeal={isSetMeal}
            selectedItems={selectedItems}
            addItemDirect={addItemDirect}
            currencySymbol={currencySymbol}
            cp={cp}
            addQty={addQty}
            minusQty={minusQty}
          />
        }
        {item.size != 3 &&
          <React.Fragment>
            {item.size != 2 &&
              <ItemDisplay
                // key={columnIndex + rowIndex * 2}
                ref={ref}
                key={item.image.codeId}
                className="item-img"
                data-bg={item.image}
                shouldHide={shouldHide}
              >
                {item.isSoldOut &&
                  <SoldOut src="/soldout.png" />
                }
                <IconHolder icons={item.icons} enlarge={cp == 1 && !hasRecommended} />
                {(typeof item.comparePrice != 'undefined' && item.comparePrice > 0 && !hidePrice) &&
                  <ComparePriceDisplay themeContext={themeContext} enlarge={cp == 1 && !hasRecommended}>
                    {`${isSetMeal ? "+" : ""}${currencySymbol} ${(displayNetAmount ? (item.comparePrice * (1 + (tax.taxMode == 0 ? tax.tax : item.tax))) : item.comparePrice).toFixed(2)}`}
                  </ComparePriceDisplay>
                }
                {item.price > 0 && !hidePrice &&
                  <PriceDisplay themeContext={themeContext} enlarge={cp == 1 && !hasRecommended} hasCustomer={hasCustomer}>
                    {`${!hasCustomer && hasPricebook ? "ORI:" : ""} ${isSetMeal ? "+" : ""}${currencySymbol} ${(displayNetAmount ? (item.price * (1 + (tax.taxMode == 0 ? tax.tax : item.tax))) : item.price).toFixed(2)}`}
                  </PriceDisplay>
                }
                {(hasPricebook && item.pricebook[Object.keys(item.pricebook)[0]]) &&
                  <PricebookDisplay themeContext={themeContext}>
                    {`${item.pricebook[Object.keys(item.pricebook)[0]].title}: ${isSetMeal ? "+" : ""}${currencySymbol} ${(displayNetAmount ? (item.pricebook[Object.keys(item.pricebook)[0]].price * (1 + (tax.taxMode == 0 ? tax.tax : item.tax))) : item.pricebook[Object.keys(item.pricebook)[0]].price).toFixed(2)}`}
                  </PricebookDisplay>
                }

              </ItemDisplay>
            }
            {(!hasRecommended && selectedItems && selectedItems[item.codeId] && addItemDirect) &&
              <ItemQtyWrapper size={item.size}>
                <ItemQtyIcon onClick={minusQty} enlarge={cp == 1 && !hasRecommended}><IoIosRemove size={24} /></ItemQtyIcon>
                <ItemQtyTxt themeContext={themeContext} size={item.size} enlarge={cp == 1 && !hasRecommended}>{selectedItems[item.codeId]}</ItemQtyTxt>
                <ItemQtyIcon onClick={addQty} enlarge={cp == 1 && !hasRecommended}><IoIosAdd size={24} /></ItemQtyIcon>
              </ItemQtyWrapper>
            }
            {(!hasRecommended && selectedItems && selectedItems[item.codeId] && !addItemDirect) &&
              <ItemQty themeContext={themeContext} size={item.size} enlarge={cp == 1 && !hasRecommended}>x{selectedItems[item.codeId]}</ItemQty>
            }
            {item.size != 2 &&
              <ItemTitleWrapper themeContext={themeContext} size={item.size} cp={cp} enlarge={cp == 1 && !hasRecommended}>
                <ItemTitle themeContext={themeContext} title={item.title} theme={theme} enlarge={cp == 1 && !hasRecommended}>
                  {(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ?
                    (item.isCategory ? item.category : item.title) :
                    (item.isCategory ? item.category2 : item.title2)
                  }
                </ItemTitle>

              </ItemTitleWrapper>
            }
            {item.size == 2 &&
              <ItemTextOnly themeContext={themeContext} enlarge={cp == 1 && !hasRecommended}>
                {item.isSoldOut &&
                  <SoldOut2 src="/soldout.png" />
                }
                <ItemPlainWrapper>
                  <ItemPlainTitle enlarge={cp == 1 && !hasRecommended}>
                    {(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ?
                      (item.isCategory ? item.category : item.title) :
                      (item.isCategory ? item.category2 : item.title2)
                    }
                  </ItemPlainTitle>
                  {item.description &&
                    <ItemPlainDescription size={item.size}>
                      {(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ?
                        item.description :
                        (item.description2 ? item.description2 : item.description)
                      }
                    </ItemPlainDescription>
                  }
                </ItemPlainWrapper>
                <ItemPrice enlarge={cp == 1 && !hasRecommended}>
                  {`${isSetMeal ? "+" : ""}${currencySymbol} ${(displayNetAmount ? (item.price * (1 + tax.tax)) : item.price).toFixed(2)}`}
                </ItemPrice>
              </ItemTextOnly>

            }
          </React.Fragment>
        }
      </ItemContainer>
      {(hasRecommended && item.active) &&
        <QuantityRecAdjust
          size={32}
          quantity={item.qty ? item.qty : 1}
          adjustQuantity={adjustQuantity}
        />
      }
    </>
  );
};

export default MenuItem;

const IconHolder = ({ icons, enlarge }) => {
  const userContext = React.useContext(UserContext);
  return (

    <IconsContainer enlarge={enlarge}>
      {Array.isArray(icons) && icons.map((iconsLink, index) => (
        <Icons
          enlarge={enlarge}
          key={index + iconsLink + "Icons"}
          iconsLink={iconsLink}
          iconsImg={userContext.icons}
        />
      ))}
    </IconsContainer>
  );
};
const ItemPlainWrapper = styled.div`
  flex-grow:1;
`;
const ItemPlainTitle = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  flex-grow: 1;
  text-align: left;
  white-space: pre-wrap;
  padding-right: 4px;
  overflow: hidden;
`;
const ItemPlainDescription = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-align: ${(props) => props.size != 2 ? 'center' : 'left'};
  overflow: hidden;
  -webkit-box-orient: vertical;
  font-size: 12px;
  padding-left: ${(props) => props.size != 2 ? '8px' : '0px'};
  padding-right: ${(props) => props.size != 2 ? '8px' : '16px'};
  color: #9E9E9E;
  font-weight: 300;
  padding-top: 4px;
  margin-bottom: 8px;
`;
const ItemQtyTxt = styled.div`
  font-size: ${props => props.enlarge ? 20 : 16}px;
  width: ${props => props.enlarge ? 40 : 32}px;
  height: ${props => props.enlarge ? 40 : 32}px;
  color: white;
  display:flex;
  justify-content: center;
  align-items: center;
`;
const ItemQtyIcon = styled.div`
  width: ${props => props.enlarge ? 40 : 32}px;
  height: ${props => props.enlarge ? 40 : 32}px;
  border-radius: ${props => props.enlarge ? 20 : 16}px; 
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.6);
  color: black;
  font-size: 16px;
  font-weight:600;
`;
const ItemQtyWrapper = styled.div`
  display:flex;
  padding: ${(props) => props.size == 1 ? "16px  16px" : "8px 4px"};
  border-radius: 6px;
  background-color: rgba(0,0,0,0.6);
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top:  ${(props) => props.size == 1 ? "40%" : props.size == 2 ? "50%" : "35%"};
  transform: translate(-50%, -50%);
`;
const ItemQty = styled.div`
  position: absolute;
  font-size: ${props => props.enlarge ? 16 : 12}px;
  color: ${(props) => props.themeContext.cartFont};
  background-color: ${(props) => props.themeContext.primaryColor}; 
  bottom: ${(props) => {
    if (props.enlarge)
      return props.size != 2 ? 50 : 75
    else
      return props.size != 2 ? 40 : 75
  }}px;
  left: 2px;
  border-radius: ${props => props.enlarge ? 18 : 13}px;
  width:${props => props.enlarge ? 36 : 25}px;
  height:${props => props.enlarge ? 36 : 25}px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.65); 
`;

const ItemTextOnly = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.themeContext.fontSecondary};
  font-size: ${props => props.enlarge ? 16 : 12}px;
  font-weight: 400;
  padding: 0px 16px;
`;
const ItemPrice = styled.div`
  white-space: nowrap;
`;
const SoldOut = styled.img`
  width: 100%;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
`;
const SoldOut2 = styled.img`
  width: 40%;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateX(50%) translateY(-50%);
`;
const Icons = styled.div`
  height: ${props => props.enlarge ? 36 : 30}px;
  width: 56px;
  background-image: url(${(props) => props.iconsImg[props.iconsLink]});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
`;
const IconsContainer = styled.div`
  position: relative;
  display: block;
  font-size: ${props => props.enlarge ? 28 : 25}px;
  min-width: ${props => props.enlarge ? 36 : 30}px;
  height: ${props => props.enlarge ? 36 : 30}px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.size == 3 ? "row" : "column"};
  flex: 1;
  position: relative;
  justify-content: center;
  box-shadow: 0px 0px 4px 0px darkgray; 
  border-radius: 8px;
  margin: ${(props) => props.cp == 1 ? "12px" : "8px"};
  margin-top:${(props) => props.size == 2 ? "0px" : "8px"};
  height: ${(props) => (props.height ? props.height + "px" : props.size != 2 ? "90%" : "80%")};
  width: ${(props) => (props.width ? props.width + "px" : "90%")};
  border: ${(props) => props.active ? `3px solid ${props.themeContext.primaryColor}` : ""};
  opacity: ${(props) => () => {
    if (props.hasRecommended || props.active === undefined) {
      return 1;
    } else {
      return props.active ? 1 : 0.8;
    }
  }};
  z-index:${(props) => props.hideDisplay ? 0 : 1};
  background-color:${(props) => props.size == 2 ? props.themeContext.optionHeaderBackgroundColor : ""};
`;
const ItemDisplay = styled.div`
  display: grid;
  position: relative;
  flex: 1;
  justify-content: flex-end;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  height: 72%;
  min-width: 100%;
  //Image based on image url in item given
  background-image: url(${(props) => props.shouldHide ? "" : props["data-bg"]});
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
`;
const ComparePriceDisplay = styled.div`
  position: absolute;
  right: 0px;
  bottom: ${(props) => props.enlarge ? 18 : 14}px;
  color: ${(props) => props.themeContext.itemPrice};
  text-shadow: 0px 0px 7px rgba(0,0,0,70%);
  z-index:2;
  font-weight: 700;
  font-size: ${props => props.enlarge ? 16 : 12}px;
  margin-right: 10px;
  text-decoration: line-through;
`;
const PriceDisplay = styled.div`
  position: absolute;
  right: 0px;
  bottom: 2px;
  text-shadow: 0px 0px 7px #000;
  color: ${(props) => props.themeContext.itemPrice};
  font-weight: 700;
  font-size: ${props => props.enlarge ? 14 : 14}px;
  margin-right: 10px;
  text-decoration: ${(props) => props.hasCustomer ? "line-through" : "none"};

`;
const PricebookDisplay = styled.div`
  position: absolute;
  right: 0px;
  bottom: ${(props) => props.enlarge ? 18 : 18}px;
  color: ${(props) => props.themeContext.itemPrice};
  text-shadow: 0px 0px 7px rgba(0,0,0,70%);
  z-index:2;
  font-weight: 700;
  font-size: ${props => props.enlarge ? 14 : 14}px;
  margin-right: 10px;
`;
const ItemTitleWrapper = styled.div`
  display: grid;
  flex: ${(props) => props.size == 1 ? '0.2' : '0.3'};
  justify-content: ${(props) => props.enlarge ? "flex-start" : "center"};;
  padding: 8px 0px;
 
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top: 1px solid ${(props) => props.themeContext.borderColor};
  background-color:${(props) => props.themeContext.optionHeaderBackgroundColor};
`;
const ItemTitle = styled.div`
  display: -webkit-box;
  flex: 0.3;
  place-content: center;
  text-align: ${(props) => props.enlarge ? "left" : "center"};
  font-weight: ${(props) => props.enlarge ? 600 : 400};
  padding: 0 ${(props) => props.enlarge ? 12 : 8}px;
  font-family: ${(props) => props.themeContext.fontFamily};
  font-size: ${(props) => {
    if (props.enlarge) {
      return 14;
    } else {
      if (props.theme == 3) {
        return 12;
      } else if (!props.title) {
        return 12;
      } else if (props.title.length > 30) {
        return 12;
      } else if (props.title.length > 19) {
        return 12;
      } else {
        return 12;
      }
    }
  }}px;
  color: ${(props) => props.themeContext.fontSecondary};
  /*min-height: ${(props) => props.enlarge ? 66 : 44}px;
  max-height: ${(props) => props.enlarge ? 66 : 44}px;*/
 
  //Image based on image url in item given
  -webkit-line-clamp: 3;
  word-wrap:break-word;
  -webkit-box-orient: vertical;
  overflow:hidden;
`;
