import React, { useState } from "react";
import styled from "@emotion/styled";

const TextAreaContainer = styled.div`
  display: grid;
  background-color: white;
  align-items: center;
  width: 100%;
`;

const TextArea = styled.textarea`
  box-sizing: border-box;
  border: 1px solid darkgrey;
  border-radius: 8px;
  padding: 4px 8px;
  width: 100%;
  max-width: 100%;
 
  font-family:'Gotham';
`;

const TextField = ({ state, setState }) => {
  const [text, setText] = useState(state);
  const handleChange = (event) => {
    setText(event.target.value);
  };
  return (
    <TextAreaContainer>
      <TextArea
        value={text}
        onChange={handleChange}
        onBlur={() => {
          setState(text);
        }}
        rows={3}
      />
    </TextAreaContainer>
  );
};

export default TextField;
