import React from "react";
import styled from "@emotion/styled";
import { IoIosAdd, IoIosRemove } from "react-icons/io";

import { ThemeContext, UserContext, LanguageContext } from "../contexts/Context";
import promo from '../newcomponents/customerComponents/Promotion';

const CheckoutPanel = ({
  index,
  cart,
  item,
  height,
  width,
  deleteActive,
  handleDelete,
  handleAdd,
  handleMinus,
  handleItemClick,
  lanIndex,
  isLanReverse,
  currencySymbol,
  hidePrice
}) => {
  //State
  let additionalPrice = 0;


  const themeContext = React.useContext(ThemeContext);
  const { tax, displayNetAmount } = React.useContext(UserContext);
  const languageContext = React.useContext(LanguageContext);
  const finalPrice = promo.subTotalAmount([item]);
  const defaultPrice = promo.subTotalDefaultAmount([item]);


  //Option Array's option description mapping
  const mapItemDesc = (optionArray) => {
    return optionArray.map((item, index) => {
      if (item.optionActive === true && item.options) {
        let desc;

        desc = returnSelectedOptionMulti(item.options);

        if (desc) {
          return (
            <ItemDesc
              key={index}
              themeContext={themeContext}
            >{`${desc}`}</ItemDesc>
          );
        }
      }
    });
  };

  //Option Array's option description mapping
  const returnSelectedOptionMulti = (options) => {
    const filteredArray = options.filter((item) => item.active === true);

    let selection = "";
    filteredArray.forEach((item, index) => {
      if (selection.length > 0) {
        selection += ", ";
      }
      selection += `${item.qty && item.qty > 1 ? item.qty + " x " : ""}${(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? item.title : item.title2}`;

      if (item.optionArray) {
        let subDesc = item.optionArray.map((it, index) => {
          if (it.optionActive === true && it.options) {
            let de = returnSelectedOptionMulti(it.options);
            if (de)
              return de;
            else
              return "";
          }
        });
        if (subDesc && subDesc.length > 0 && subDesc[0] != "") {
          // console.log(subDesc);
          selection += " - " + subDesc.join(", ");
        }
      }
    });

    if (selection != "")
      return selection;
  };

  if (typeof lanIndex === 'undefined') {
    return <></>
  }
  return (
    <PanelContainer themeContext={themeContext} deleteActive={deleteActive}>
      <Content height={height} width={width} themeContext={themeContext} >


        <ItemInformationContainer themeContext={themeContext}>
          <ItemTitle themeContext={themeContext}>{!deleteActive ? item.qty + " x " : ""}{(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? item.title : item.title2}</ItemTitle>
          {mapItemDesc(item.optionArray)}

          {/* Remember to wrap overflow */}
          {(item.hasRemark && item.remark != '') &&
            <ItemDesc
              themeContext={themeContext}
            >{`${languageContext[lanIndex].remark}: ${item.remark}`}</ItemDesc>
          }
          {(additionalPrice > 0) &&
            <ItemDesc
              themeContext={themeContext}
            >{`${languageContext[lanIndex].additional}: ${(additionalPrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : item.tax)) : 1)).toFixed(2)}`}</ItemDesc>
          }
          {(deleteActive && item.optionArray.length > 0) &&
            <EditButton onClick={handleItemClick(item, index)} themeContext={themeContext}>
              {languageContext[lanIndex].edit}
            </EditButton>
          }

        </ItemInformationContainer>


        <ItemPrice themeContext={themeContext}>
          {defaultPrice != finalPrice && !hidePrice &&
            <ItemPriceValue strikThrough={true}>
              {`${currencySymbol} ${(defaultPrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : item.tax)) : 1)).toFixed(2)}`}
            </ItemPriceValue>
          }
          {!hidePrice &&
            <ItemPriceValue strikThrough={false}>
              {`${currencySymbol} ${(finalPrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : item.tax)) : 1)).toFixed(2)}`}
            </ItemPriceValue>
          }
          {deleteActive &&
            <QtyWrapper>
              <QtyButton
                qty={item.qty}
                onClick={() => {
                  handleMinus(cart, index);
                }}
              >
                <IoIosRemove size={24} />
              </QtyButton>
              <ItemQty themeContext={themeContext}>{item.qty}</ItemQty>
              <QtyButtonRight
                onClick={() => {
                  handleAdd(cart, index);
                }}
              >
                <IoIosAdd size={24} />
              </QtyButtonRight>
            </QtyWrapper>
          }
        </ItemPrice>

      </Content>
    </PanelContainer>
  );
};

export default CheckoutPanel;
const ItemPriceValue = styled.div`
  text-decoration:${props => props.strikThrough ? "line-through" : "none"};
`;
const EditButton = styled.div`
  font-size: 12px;
  padding-top: 8px;
  text-decoration: underline;
  color:${props => props.themeContext.primaryColor}
`;
const QtyWrapper = styled.div`
  display: flex;
  color: black;
  margin-top: 12px;
  margin-bottom: 12px;
  justify-content: flex-end;
`;
const PanelContainer = styled.div`
  width: 100%;
  max-width: 550px;
  min-height: 60px;
  margin-bottom: ${props => props.deleteActive ? 10 : 0}px;
  place-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) => Math.min(props.width, 550) - 40}px;
  min-height:40px;
  border-bottom: 1px solid ${(props) => props.themeContext.borderColor};
  padding-left: 20px;
`;

const ItemInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: bold;
  font-family: ${(props) => props.themeContext.fontFamily};
  color: ${(props) => props.themeContext.fontPrimary};
  width: 80%;
  margin-bottom: 8px;
  flex-grow:1;
`;
const ItemTitle = styled.div`
  display: grid;
  font-size: 15px;
  font-weight: bold;
  font-family: ${(props) => props.themeContext.fontFamily};
  color: ${(props) => props.themeContext.fontPrimary};
  
  margin-top: 2px;
`;
const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 5px;
  color: ${(props) => props.themeContext.fontPrimary};
`;
const QtyText = styled.div`
  display: grid;
  font-size: 14px;
  font-weight: 500;
  opacity: 1;
  font-family: ${(props) => props.themeContext.fontFamily};
`;
const ItemQty = styled.div`
  display: grid;
  font-size: 18px;
  font-weight: 500;
  opacity: 1;
  padding:0px 4px;
  font-family: ${(props) => props.themeContext.fontFamily};
`;
const QtyButton = styled.div`
  padding: 0 8px;
  display: flex;
  color:${props => props.qty == 1 ? "#b4b4b4" : "black"};
`;
const QtyButtonRight = styled.div`
  padding: 0 8px;
  display: flex;
  padding-right: 0px;
`;
const ItemDesc = styled.div`
  display: grid;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.8;
  font-family: ${(props) => props.themeContext.fontFamily};
  color: ${(props) => props.themeContext.fontPrimary};
 
  margin-top: 2px;
`;

const ItemPrice = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 500;
  font-family: ${(props) => props.themeContext.fontFamily};
  /*color: ${(props) => props.themeContext.fontPrice};*/
  color: #757575;
  /*margin-top: 10px;*/
  align-self: right;
  text-align: right;
  justify-content: flex-start;
  min-width: 100px;
  word-wrap: none;
`;
const DeleteButton = styled.div`
  display: grid;
  align-self: center;
  place-content: center;
  color: white;
  background-color: ${(props) => props.themeContext.selectedColor};
  min-width: 26px;
  height: 26px;
  margin-bottom: 5px;
  border-radius: 15px;
`;

// const DeleteButtonLine = styled.div`
//   display: grid;
//   background-color: white;
//   width: 12px;
//   height: 4px;
// `;

{/* <ItemWrapper themeContext={themeContext}>
            <QtyText themeContext={themeContext}>{languageContext[lanIndex].qty}: </QtyText>
            {deleteActive &&
              <QtyButton
                onClick={() => {
                  handleMinus(cart, index);
                }}
              >
                <IoIosRemoveCircleOutline size={24} />
              </QtyButton>
            }
            <ItemQty themeContext={themeContext}>{item.qty}</ItemQty>
            {deleteActive &&
              <QtyButton
                onClick={() => {
                  handleAdd(cart, index);
                }}
              >
                <IoIosAddCircleOutline size={24} />
              </QtyButton>
            }
            
          </ItemWrapper> */}
// {deleteActive ? (
//   <DeleteButton
//     themeContext={themeContext}
//     onClick={() => {
//       handleDelete(cart, index);
//     }}
//   >
//     <IoMdClose size={22} />
//   </DeleteButton>
// ) : (
//   <React.Fragment />
// )}