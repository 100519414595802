import React, { useEffect, useState } from "react";
import { ThemeContext, ActionContext, LanguageContext, UserContext } from "../contexts/Context";
import styled from "@emotion/styled";
import { navigate } from "@reach/router"
import useWindowDimensions from "../hooks/useWindowDimensions";

import Header from "../newcomponents/Header";
// import menuList from "./Menulist";

const Category = () => {
    const languageContext = React.useContext(LanguageContext);
    const themeContext = React.useContext(ThemeContext);
    const { width, height } = useWindowDimensions();
    const { lanIndex, theme, title, menus } = React.useContext(UserContext);
    let { menuList } = React.useContext(UserContext);

    const [catList, setCatList] = useState([]);
    const [headerHeight, setHeaderHeight] = useState(0);

    const onBackChange = () => {
        console.log("Trigger Back");
        console.log("Prevent Back");
        window.history.pushState(null, null, "/category");
    }
    useEffect(() => {
        window.addEventListener('popstate', onBackChange, false);
        return () => {
            window.removeEventListener('popstate', onBackChange);
        }
    }, []);

    useEffect(() => {
        if (title && menus && menus.length > 1) {
            console.log(title);
            menuList = menuList.filter((mn) => {
                return mn.title == title;
            });
        }

        let catList = [];
        menuList.forEach((mn) => {
            catList.push(mn);
        });
        setCatList(catList);

        let headerHeight = themeContext.headerHeight;
        setHeaderHeight(headerHeight);
    }, [menuList]);
    const setSelectedCategory = (category) => {
        navigate("/menu/menu/" + category);
    }


    return (
        <React.Fragment>
            <Header hasBack={false} />
            <MenuWrapper
                className="no-scrollbars"
                themeContext={themeContext}
                height={height - headerHeight}
                width={width}
                headerHeight={headerHeight}
            >
                {catList && catList.map((ct, i) =>
                    <div style={{
                        width: Math.min(width, 550) / 2,
                        height: (Math.min(width, 550)) / 2 + 60,
                        marginTop: 16,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <ItemContainer
                            height={(Math.min(width, 550) - 24) / 2 + 60}
                            width={(Math.min(width, 550) - 24) / 2}
                            themeContext={themeContext}
                            style={{ width: (width - 24) / 2 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setSelectedCategory(ct.category);
                            }}
                        >
                            <ItemDisplay
                                key={ct.catImg}
                                className="item-img"
                                data-bg={ct.catImg}
                            >

                            </ItemDisplay>
                            <ItemTitleWrapper themeContext={themeContext} >
                                <ItemTitle themeContext={themeContext} theme={theme} >
                                    {lanIndex == 0 ? ct.category : ct.category2}
                                </ItemTitle>
                            </ItemTitleWrapper>
                        </ItemContainer>

                    </div>
                )}
            </MenuWrapper>
        </React.Fragment>
    )
}

export default Category;

const MenuWrapper = styled.div`
    margin-top:${(props) => props.headerHeight}px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 60px;
    background-color: ${(props) => props.themeContext.backgroundColor};
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  justify-content: center;
  box-shadow: 0px 0px 4px 0px darkgray; 
  border-radius:6px;
  margin: 8px;
  margin-top: 8px;
  height: ${(props) => (props.height + "px")};
  width: ${(props) => (props.width ? props.width + "px" : "90%")};
`;
const ItemDisplay = styled.div`
  display: grid;
  position: relative;
  flex: 1;
  justify-content: flex-end;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  height: 72%;
  min-width: 100%;
  //Image based on image url in item given
  background-image: url(${(props) => `https://ireachfnb.com${props["data-bg"]}`});
  /*background-image: url(${(props) => `https://newdemo.chachingfnb.com${props["data-bg"]}`});*/
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
`;
const ItemTitleWrapper = styled.div`
  display: grid;
  flex: 0.3;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top: 1px solid ${(props) => props.themeContext.borderColor};
  background-color:${(props) => props.themeContext.optionHeaderBackgroundColor};
`;

const ItemTitle = styled.div`
  display: -webkit-box;
  flex: 0.3;
  place-content: center;
  font-family: ${(props) => props.themeContext.fontFamily};
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.themeContext.fontSecondary};
  padding: 0 4px;
  -webkit-line-clamp: 3;
  word-wrap:break-word;
  -webkit-box-orient: vertical;
  overflow:hidden;
`;