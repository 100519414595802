import React from "react";
import styled from "@emotion/styled";
import { ThemeContext } from "../../contexts/Context";
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';

const MultiQtyOption = ({
    height,
    array,
    setActiveState,
    lanIndex,
    isLanReverse,
    isMultiple,
    produceArrayStateMulti,
    produceMinusArray,
    currencySymbol,
    displayNetAmount,
    tax,
    itemTax
}) => {
    const themeContext = React.useContext(ThemeContext);

    return (
        <React.Fragment>
            {array.map((item, index) =>
                <ArrayOption themeContext={themeContext} key={index}>
                    <DotTextContainer themeContext={themeContext}>
                        {(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? item.title : item.title2 ? item.title2 : item.title}
                        {item.addPrice ? " +" + currencySymbol + " " + (item.addPrice * (displayNetAmount ? (1 + (tax.taxMode == 0 ? tax.tax : itemTax)) : 1)).toFixed(2) : ""}
                
                    </DotTextContainer>
                    <DotBtn onClick={() => produceArrayStateMulti(array, item.itemOptionId)}>
                        <IoAddCircleOutline size={32} />
                    </DotBtn>
                    <DotQty>
                        {item.qty ? item.qty : 0}
                    </DotQty>
                    <DotBtn onClick={() => produceMinusArray(array, item.itemOptionId)}>
                        <IoRemoveCircleOutline size={32} />
                    </DotBtn>

                </ArrayOption>

            )}
        </React.Fragment>
    )
};

export default MultiQtyOption;

const ArrayOption = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.themeContext.backgroundColor};
  border-bottom: 1px solid ${(props) => props.themeContext.borderColor};
  margin: 0px;
  min-height: 40px;
  margin-left:8px;
  margin-right: 8px;
  align-items: center;
`;

const DotTextContainer = styled.div`
  font-family: ${(props) => props.themeContext.fontFamily};
  display: grid;
  align-items: center;
  flex-grow: 1;
  min-height: 25px;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 3px;
  font-weight: 500;
  text-align: left;
  overflow-wrap: break-word;
`;
const DotBtn = styled.div`
    display: flex;
    align-items: center;
`;
const DotQty = styled.div`
    font-size: 16px;
    padding: 0px;
    text-align: center;
    width: 24px;
    min-width: 24px
`;
