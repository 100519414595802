import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../../contexts/Context";
import axios from "axios";
import { IoCloseCircle } from "react-icons/io5";

const CustomerPdpa = ({ show, setShow, setError, setShowError, handleCallback }) => {
    const [displayShow, setDisplay] = useState(show ? "grid" : "none");
    const [password, setPassword] = useState("");
    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { lanIndex, token, userId, customer } = React.useContext(UserContext);

    useEffect(() => {
        setDisplay(show ? "flex" : "none");
    }, [show]);


    return (
        <React.Fragment>
            <ModalContainer display={displayShow}>
                <div style={{ width: "100%" }}>
                    <IconButton onClick={() => {
                        setShow(false);
                        setDisplay(false);
                    }}>
                        <IoCloseCircle size={36} />
                    </IconButton>

                    <ContentWrapper>
                        <InputWrapper>
                            <Title>
                                Enter Password
                            </Title>
                            <ModalContentText
                                fontWeight={400}
                                color={"black"}
                            >
                                {languageContext[lanIndex].password}
                            </ModalContentText>
                            <PhoneWrapper>
                                <Input type="password" width={100} value={password} onChange={(e) => { setPassword(e.target.value) }} />
                            </PhoneWrapper>
                        </InputWrapper>
                        <ButtonWrapper>
                            <Button themeContext={themeContext} onClick={() => {
                                handleCallback(password);
                            }} >
                                {languageContext[lanIndex].ok}
                            </Button>

                        </ButtonWrapper>
                    </ContentWrapper>
                </div>
            </ModalContainer>
        </React.Fragment >
    );
}

export default CustomerPdpa;
const ButtonWrapper = styled.div`
    display: flex;
`;
const Button = styled.div`
    display: flex;
    width: 120px;
    margin: 8px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    border-radius: 25px;
    line-height: 40px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
   
`;

const Input = styled.input`
  height: 30px;
  border-radius: 4px;
  padding-left: 16px;
  font-size: 16px;
  border: 1px solid #aaa;
  margin-bottom: 10px;
  width: ${(props) => props.width}%;
`;

const PhoneWrapper = styled.div`
    display: flex;
    width: 80%;
`;
const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;



const IconButton = styled.div`
  text-align: right;
  margin-right: 16px;
  top: 16px;
  background: white;
  z-index: 99;
`;
const ModalContentText = styled.div`
    padding: 8px;
    white-space: pre-wrap;
`;
const Title = styled.div`
    font-size: 18px;
    font-weight: 500;
   
    margin-left: 8px;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    overflow-y: auto;
    margin-top: 24px;
`;

const ModalContainer = styled.div`
    display: ${(props) => props.display};
    position: fixed;
    
    background: #fff;
    top: 0;
    align-items: center;
    width: 100vw;
    max-width: 550px;
    height: 100dvh;
    z-index: 9;

    animation: modalOrder .1s;
    animation-timing-function: ease-out;
    @keyframes modalOrder {
        0%  {
        transform: translateY(350px);
        opacity: 0;
        }
        
        100%  {
        transform: translateY(0px);
        opacity: 1;
        }
        
    }
  `;

