const promotion = {
    clearPromotion(items) {
        for (let item of items) {
            item.promotion = [];
        }

        return items;
    },
    promotionBaseOnPricebook(items, event) {
        let priceBookId = event.priceBookId;
        let i = 0;
        for (let item of items) {

            if (item.promotion.length > 0) {
                i++;
                continue;
            }
            if (item.pricebook[priceBookId]) {

                let pb = item.pricebook[priceBookId];

                if (item.qty >= pb.minQty) {
                    let noOfItemHaveDiscount = Math.floor(item.qty / pb.maxQty);
                    noOfItemHaveDiscount = noOfItemHaveDiscount * (1 + pb.maxQty - pb.minQty);
                    if (item.qty % pb.maxQty >= pb.minQty) {
                        noOfItemHaveDiscount += item.qty % pb.maxQty - pb.minQty + 1;
                    }
                    if (noOfItemHaveDiscount == 0) {
                        i++;
                        continue;
                    }
                    items = this.enjoyPriceBookDiscount(pb, items, i, noOfItemHaveDiscount, event);
                }
            }
            i++;
        }


        return items;
    },
    enjoyPriceBookDiscount(pb, items, index, noOfItemDiscount, event) {
        let discountPrice = 0, discountPercent = 0;
        discountPrice = items[index].price - pb.price;
        if (items[index].qty > noOfItemDiscount) {
            let newCkItem = JSON.parse(JSON.stringify(items[index]));
            newCkItem.qty = noOfItemDiscount;
            items[index].qty -= noOfItemDiscount;
            discountPrice = discountPrice * newCkItem.qty
            newCkItem.hasDiscount = true;
            newCkItem.promotion.push({
                promoId: event._id,
                eventName: event.eventName,
                discountByPercent: false,
                discountPercent,
                discountPrice,
                excludeOption: event.excludeOption
            });

            items.push(newCkItem);
        } else {
            discountPrice = discountPrice * items[index].qty;
            items[index].hasDiscount = true;
            items[index].promotion.push({
                promoId: event._id,
                eventName: event.eventName,
                discountByPercent: false,
                discountPercent,
                discountPrice,
                excludeOption: event.excludeOption
            });
        }

        return items;

    },
    applyPromotion(events, items) {
        let eventObj = {};

        for (let event of events) {
            eventObj[event._id] = event;
        }
        for (let event of events) {
            if (event.type == 2) {
                items = this.promotionBaseOnPricebook(items, event);
            } else if (event.condition == 0) {
                items = this.promotionAnyItem(items, event, eventObj);
            } else if (event.condition == 1) {
                items = this.promotionSpecificItem(items, event, eventObj);
            } else if (event.condition == 2) {
                items = this.promotionComboItem(items, event, eventObj);
            } else if (event.condition == 3) {
                items = this.promotionAboveAmount(items, event, eventObj);
            } else if (event.condition == 4) {
                items = this.promotionAboveAmountSpecificItem(items, event, eventObj);
            } else if (event.condition == 5) {
                items = this.promotionAnyComboItem(items, event, eventObj);
            }
        }


        return items;
    },
    countValidQty(items, event, eventObj) {
        let qty = 0;
        if (this.isPromosExcluded(items, event, eventObj)) {
            return 0;
        } else if (event.condition == 0 || event.condition == 3) {
            for (let item of items) {
                console.log(item.codeId, item.qty);
                if (item.qty < 0) continue;
                if (this.isItemExcluded(item, event)) continue;
                if (item.isVoucher) continue;
                if (item.isPackage) continue;
                if (item.promotion.length > 0 && !event.canStack) continue;
                qty += item.qty;
            }
        } else if (event.condition == 1 || event.condition == 4 || event.condition == 5) {
            for (let item of items) {
                console.log(item.codeId, item.qty);
                if (item.qty < 0) continue;
                if (!this.isItemIncluded(item, event)) continue;
                if (this.isItemExcluded(item, event)) continue;
                if (item.isVoucher) continue;
                if (item.isPackage) continue;
                if (item.promotion.length > 0 && !event.canStack) continue;
                qty += item.qty;
            }
        }

        return qty;
    },
    promotionAboveAmountSpecificItem(items, event, eventObj) {
        let itemAmount = 0;
        itemAmount = this.countTotalAmount(items, event, eventObj);
        let itemQty = this.countValidQty(items, event, eventObj);

        //if has limited Amount
        if (event.hasLimitedAmount && event.limitedAmount <= 0) {
            return items;
        }

        if (itemAmount > event.minAmount) {
            //apply discount, check if total Bill or
            if (event.discountType == 1) {
                //if discount is applied to final bill
                if (event.discountApply == 1) {
                    //all items have discount
                    items = this.discountApplyToAll(items, event);

                    //apply to specific item
                } else if (event.discountApply == 0) {
                    //count no of item can be discounted
                    let noOfItemHaveDiscount = itemQty;
                    if (event.hasLimitedQty) {
                        noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.limitedQty);
                    }
                    if (event.hasMaxQtyPerBill) {
                        noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.maxQtyPerBill);
                    }
                    items = this.enjoyDiscount(items, event, noOfItemHaveDiscount,
                        event.discountByPercent,
                        event.discountPercent,
                        event.discountPrice, eventObj);
                } else if (event.discountApply == 2) {
                    let validItemByAmt = Math.floor(itemAmount / event.minAmount);
                    let validItemByQty = itemQty;


                    //let repeatedAvailQty = countRepeatedAvailQty(items, event.eventName);
                    //check if promo has applied to other item
                    let repeatedDiscountQty = this.countRepeatedPromotion(items, event.eventName);
                    //let repeatedDiff = repeatedAvailQty - repeatedDiscountQty;
                    validItemByQty -= repeatedDiscountQty;
                    //if (validItemByQty < repeatedDiff) {
                    //validItemByQty = repeatedDiff;
                    //}
                    if (event.hasMinQty && event.itemQty != 0) {
                        validItemByQty = Math.floor(validItemByQty / event.itemQty);
                    }
                    let validQty = Math.min(validItemByAmt, validItemByQty);

                    let noOfItemHaveDiscount = this.countValidDiscountQty(items, event);
                    noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, validQty);
                    if (event.hasLimitedQty) {
                        noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.limitedQty);
                    }
                    if (event.hasMaxQtyPerBill) {
                        noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.maxQtyPerBill);
                    }
                    items = this.enjoyDiscountOnSpecifyItemBelow(items, event, noOfItemHaveDiscount,
                        event.discountByPercent,
                        event.discountPercent,
                        event.discountPrice, eventObj);
                }
                //following items
            } else if (event.discountType == 0) { //pay a fixed amount
                if (event.discountApply == 0) { //apply to item above
                    let noOfItemHaveDiscount = itemQty;
                    if (event.hasLimitedQty) {
                        noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.limitedQty);
                    }
                    let unitPrice = event.fixedPrice;
                    items = this.enjoyDiscountOnFixedPrice(items, event, noOfItemHaveDiscount, unitPrice, eventObj);
                } else if (event.discountApply == 2) { //apply to item below
                    let validItemByAmt = Math.floor(itemAmount / event.minAmount);
                    let validItemByQty = itemQty;

                    //let repeatedAvailQty = countRepeatedAvailQty(items, event.eventName);
                    let repeatedDiscountQty = this.countRepeatedPromotion(items, event.eventName);
                    //let repeatedDiff = repeatedAvailQty - repeatedDiscountQty;
                    validItemByQty -= repeatedDiscountQty;
                    //                    if (validItemByQty < repeatedDiff) {
                    //                        validItemByQty = repeatedDiff;
                    //                    }
                    if (event.hasMinQty && event.itemQty != 0) {
                        validItemByQty = Math.floor(validItemByQty / event.itemQty);
                    }
                    let validQty = Math.min(validItemByAmt, validItemByQty);

                    let noOfItemHaveDiscount = this.countValidDiscountQty(items, event);
                    noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, validQty);
                    if (event.hasLimitedQty) {
                        noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.limitedQty);
                    }
                    let unitPrice = event.fixedPrice;
                    items = this.enjoyDiscountOnFixedPriceBelow(items, event, noOfItemHaveDiscount, unitPrice, eventObj);
                }
            }

        }
        return items;
    },
    countRepeatedPromotion(items, eventName) {
        let repeatedDiscount = 0;
        //for Each Promotion, check Max

        for (let item of items) {
            if (item.qty < 0) continue;
            if (item.promotion && item.promotion.length > 0) {
                for (let pm of item.promotion) {
                    if (pm.eventName == eventName) {
                        repeatedDiscount += item.qty;
                    }
                }
            }
        }


        return repeatedDiscount;
    },
    promotionAboveAmount(items, event, eventObj) {

        let itemAmount = 0;
        itemAmount = this.countTotalAmount(items, event, eventObj);

        let itemQty = this.countValidQty(items, event, eventObj);

        //if has limited Amount
        if (event.hasLimitedAmount && event.limitedAmount <= 0) {
            return items;
        }

        //spend above amount
        if (itemAmount >= event.minAmount) {
            //enjoy discount
            if (event.discountType == 1) {
                if (event.discountApply == 1) {
                    //all items have discount
                    items = this.discountApplyToAll(items, event);

                } else if (event.discountApply == 0) { //apply to specific item
                    //count no of item can be discounted
                    if (event.hasLimitedQty) {
                        itemQty = Math.min(itemQty, event.limitedQty);
                    }
                    if (event.hasMaxQtyPerBill) {
                        itemQty = Math.min(itemQty, event.maxQtyPerBill);
                    }
                    items = this.enjoyDiscount(items, event, itemQty,
                        event.discountByPercent,
                        event.discountPercent,
                        event.discountPrice, eventObj);

                } else if (event.discountApply == 2) {
                    itemQty = this.countValidDiscountQty(items, event);
                    if (event.hasMinQty && event.itemQty != 0) {
                        itemQty = Math.floor(itemQty / event.itemQty);
                    }
                    if (event.hasLimitedQty) {
                        itemQty = Math.min(itemQty, event.limitedQty);
                    }
                    if (event.hasMaxQtyPerBill) {
                        itemQty = Math.min(itemQty, event.maxQtyPerBill);
                    }
                    items = this.enjoyDiscountOnSpecifyItemBelow(items, event, itemQty,
                        event.discountByPercent,
                        event.discountPercent,
                        event.discountPrice, eventObj);
                }

            } else if (event.discountType == 0) { //pay a fixed amount
                if (event.discountApply == 0) { //apply to item above
                    let noOfItemHaveDiscount = itemQty;
                    if (event.hasLimitedQty) {
                        noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.limitedQty);
                    }
                    let unitPrice = event.fixedPrice;
                    items = this.enjoyDiscountOnFixedPrice(items, event, noOfItemHaveDiscount, unitPrice, eventObj);
                } else if (event.discountApply == 2) { //apply to item below
                    let validItemByAmt = Math.floor(itemAmount / event.minAmount);
                    let validItemByQty = itemQty;
                    //let repeatedAvailQty = countRepeatedAvailQty(items, event.eventName);
                    let repeatedDiscountQty = this.countRepeatedPromotion(items, event.eventName);
                    //let repeatedDiff = repeatedAvailQty - repeatedDiscountQty;
                    validItemByQty -= repeatedDiscountQty;
                    //                    if (validItemByQty < repeatedDiff) {
                    //                        validItemByQty = repeatedDiff;
                    //                    }
                    if (event.hasMinQty && event.itemQty != 0) {
                        validItemByQty = Math.floor(validItemByQty / event.itemQty);
                    }
                    let validQty = Math.min(validItemByAmt, validItemByQty);

                    let noOfItemHaveDiscount = this.countValidDiscountQty(items, event);
                    noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, validQty);
                    if (event.hasLimitedQty) {
                        noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.limitedQty);
                    }
                    let unitPrice = event.fixedPrice;
                    items = this.enjoyDiscountOnFixedPriceBelow(items, event, noOfItemHaveDiscount, unitPrice, eventObj);
                }
            }

            return items;
        }

        return items;
    },
    calculateEventPromoAmt(items, event) {
        let promoAmt = 0;

        for (let item of items) {
            //option
            let optAmt = 0;
            if (item.optionArray.length > 0 && !event.excludeOption) {
                item.optionArray.forEach((element) => {
                    if (element.optionType === 3 || element.optionType === 5 || element.optionType === 6) { //set meal
                        if (element.optionActive === true) {
                            element.options.forEach(function (op) {
                                if (!op.active) return;

                                optAmt +=
                                    (op && op.price ? op.price : (op.addPrice ? op.addPrice : 0));


                                op.optionArray.forEach((element) => {
                                    element.options.forEach((it) => {
                                        if (it.active) {
                                            if (it.price) {
                                                optAmt += it.price * (it.qty ? it.qty : 1);
                                            } else if (it.addPrice) {
                                                optAmt += it.addPrice * (it.qty ? it.qty : 1);
                                            }
                                        }
                                    })
                                });

                            });
                        }
                    } else {
                        const filteredArray = element.options.filter(
                            (item) => item.active === true
                        );
                        filteredArray.forEach((item) => {
                            optAmt += item.addPrice * (item.qty ? item.qty : 1);
                        });
                    }
                });
                if (item.promotion)
                    for (let pm of item.promotion) {
                        if (pm.discountByPercent) {
                            let pa = pm.discountPercent * optAmt;
                            optAmt = (1 - pm.discountPercent) * optAmt;
                            if (event._id == pm.promoId) {
                                promoAmt += pa;
                            }
                        }
                    }
            }


            let itemAmt = 0;
            itemAmt = item.price * item.qty;
            if (item.promotion)
                for (let pm of item.promotion) {
                    if (pm.discountByPercent) {
                        let pa = pm.discountPercent * itemAmt;
                        itemAmt = (1 - pm.discountPercent) * itemAmt;
                        if (event._id == pm.promoId) {
                            promoAmt += pa;
                        }
                    } else {
                        let pa = pm.discountPrice;
                        itemAmt = itemAmt - pa;
                        if (event._id == pm.promoId) {
                            promoAmt += pa;
                        }
                    }
                }
        }

        return promoAmt;
    },
    discountApplyToAll(items, events) {
        let totalAmount = 0;
        let diff = 0;
        let amt = 0;
        let disAmt = 0;

        if (events.discountPrice > 0) {
            for (let ck of items) {
                if (ck.qty < 0) continue;
                if (ck.promotion.length > 0 && !events.canStack) continue;
                totalAmount += ck.price * (ck.qty);
                let ckList = [];
                ckList.push(ck);
                amt += this.subTotalAmount(ckList);
            }
            //get amount before further
            diff = events.discountPrice <= amt ? events.discountPrice : amt;
            disAmt = events.discountPrice <= amt ? events.discountPrice : amt;
        } else if (events.discountPercent > 0 && events.hasMaxDiscount) {
            disAmt = events.maxDiscount;
        }


        for (let i = 0; i < items.length; i++) {
            if (items[i].qty < 0) continue;
            if (this.isItemExcluded(items[i], events)) continue;

            let discountPrice = 0;
            let discountPercent = 0;
            let discountByPercent = events.discountByPercent;
            //if discount by percent
            if (events.discountByPercent) {
                discountPercent = events.discountPercent;
                //if has max Discount
                if (events.hasMaxDiscount) {
                    if (disAmt <= 0) continue;
                    let ar = []
                    ar.push(items[i]);
                    let itemAmt = this.subTotalAmount(ar);

                    let discount = itemAmt * discountPercent;
                    //existing discount Amt
                    let billDis = this.calculateEventPromoAmt(items, events);
                    if (billDis + discount < events.maxDiscount, items[i].tax) {

                    } else if (billDis >= events.maxDiscount) {
                        discountByPercent = false;
                        discountPrice = 0;
                    } else {
                        discountByPercent = false;
                        let remaining = events.maxDiscount - billDis;
                        discountPrice = Math.min(discount, remaining);
                    }
                }
            } else {
                if (items[i].promotion.length > 0 && !events.canStack) continue;
                discountPrice = disAmt * items[i].price / totalAmount * (items[i].qty);
                //only discount once, after that break`
                diff -= discountPrice;
                if (i == items.length - 1) {
                    discountPrice += diff;
                }
            }
            if (!events.canStack) {
                // Log.d("variable", "Set item discount to true");
                items[i].hasDiscount = true;
            }
            items[i].promotion.push({
                promoId: events._id,
                eventName: events.eventName,
                discountByPercent,
                discountPercent,
                discountPrice,
                excludeOption: events.excludeOption
            });

        }

        return items;


    },
    promotionComboItem(items, event, eventObj) {
        let itemQty = 0;
        itemQty = this.countValidQty(items, event, eventObj);

        //if has limited Amount
        if (event.hasLimitedAmount && event.limitedAmount <= 0) {
            return items;
        }

        if (itemQty > 0) {
            //enjoy certain discount items
            if (event.discountType == 1) {
                //if discount on the entire bill
                if (event.discountApply == 1) {
                    //all items have discount
                    if (!event.discountByPercent) {
                        items = this.enjoyComboDiscount(items, event, 1);
                    } else {
                        items = this.discountApplyToAll(items, event);
                    }
                    //apply to combo item
                } else if (event.discountApply == 0) {
                    items = this.enjoyComboDiscount(items, event, itemQty);
                } else if (event.discountApply == 2) {
                    let repeatedDiscountQty = this.countRepeatedPromotion(items, event.eventName);
                    itemQty -= repeatedDiscountQty;
                    if (event.hasMinQty && event.itemQty != 0) {
                        itemQty = Math.floor(itemQty / event.itemQty);
                    }
                    items = this.enjoyDiscountOnSpecifyItemBelow(items, event, itemQty,
                        event.discountByPercent,
                        event.discountPercent,
                        event.discountPrice, eventObj);
                }
                //pay fixed amount
            } else if (event.discountType == 0) {
                items = this.enjoyComboDiscount(items, event, itemQty);
            }
        }
        return items;
    },
    promotionAnyComboItem(items, event, eventObj) {
        let itemQty;
        itemQty = this.countValidQty(items, event, eventObj);

        //if has limited Amount
        if (event.hasLimitedAmount && event.limitedAmount <= 0) {
            return items;
        }

        //if category qty is greater than the condition
        if (itemQty >= event.minQty) {
            //apply discount, check if total Bill or
            if (event.discountType == 1) {
                let noOfItemHaveDiscount = Math.floor(itemQty / event.minQty);
                noOfItemHaveDiscount = noOfItemHaveDiscount * event.minQty;
                if (event.maxQty - event.minQty > 0) {
                    noOfItemHaveDiscount += Math.min(itemQty % (event.minQty), event.maxQty - event.minQty);
                }
                if (event.hasLimitedQty) {
                    noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.limitedQty);
                }
                if (event.hasMaxQtyPerBill) {
                    noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.maxQtyPerBill);
                }
                //count no of item can be discounted
                items = this.enjoyDiscount(items, event, noOfItemHaveDiscount,
                    event.discountByPercent,
                    event.discountPercent,
                    event.discountPrice, eventObj);

            } else if (event.discountType == 0) { //pay a fixed amount
                let noOfItemHaveDiscount = Math.floor(itemQty / event.minQty);
                noOfItemHaveDiscount = noOfItemHaveDiscount * event.minQty;
                if (event.hasLimitedQty) {
                    noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.limitedQty);
                }
                if (event.hasMaxQtyPerBill) {
                    noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.maxQtyPerBill);
                }
                let unitPrice = event.fixedPrice / event.minQty;
                items = this.enjoyDiscountOnFixedPrice(items, event, noOfItemHaveDiscount, unitPrice, eventObj);
            }
        }

        return items;

    },
    promotionSpecificItem(items, event, eventObj) {

        let itemQty;
        itemQty = this.countValidQty(items, event, eventObj);

        //if has limited Amount
        if (event.hasLimitedAmount && event.limitedAmount <= 0) {
            return items;
        }

        //if category qty is greater than the condition
        if (itemQty >= event.minQty) {
            //apply discount, check if total Bill or
            if (event.discountType == 1) {
                let noOfItemHaveDiscount = Math.floor(itemQty / event.maxQty) * (1 + event.maxQty - event.minQty);
                if (itemQty % event.maxQty >= event.minQty) {
                    noOfItemHaveDiscount += itemQty % event.maxQty - event.minQty + 1;
                }
                //if discount is applied to final bill
                if (event.discountApply == 1) {
                    //all items have discount
                    items = this.discountApplyToAll(items, event);

                    //apply to specific item
                } else if (event.discountApply == 0) {
                    //count no of item can be discounted
                    if (event.hasLimitedQty) {
                        noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.limitedQty);
                    }
                    if (event.hasMaxQtyPerBill) {
                        noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.maxQtyPerBill);
                    }
                    items = this.enjoyDiscount(items, event, noOfItemHaveDiscount,
                        event.discountByPercent,
                        event.discountPercent,
                        event.discountPrice, eventObj);
                } else if (event.discountApply == 2) {
                    //apply to specific item below
                    let validDiscountItem = this.countValidDiscountQty(items, event);
                    if (event.hasMinQty && event.itemQty != 0) {
                        validDiscountItem = Math.floor(validDiscountItem / event.itemQty);
                    }
                    let multiply = Math.floor(itemQty / event.minQty);
                    //eligible no of discount
                    let itemDis = multiply * (1 + event.maxQty - event.minQty);

                    //let repeatedAvailQty = countRepeatedAvailQty(items, event.eventName);
                    let repeatedDiscountQty = this.countRepeatedPromotion(items, event.eventName);
                    //                    let repeatedDiff = repeatedAvailQty - repeatedDiscountQty;
                    itemDis -= repeatedDiscountQty;
                    //                    if (itemDis < repeatedDiff) {
                    //                        itemDis = repeatedDiff;
                    //                    }
                    noOfItemHaveDiscount = Math.min(itemDis, validDiscountItem);

                    if (event.hasLimitedQty) {
                        noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.limitedQty);
                    }

                    if (event.hasMaxQtyPerBill) {
                        noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.maxQtyPerBill);
                    }

                    items = this.enjoyDiscountOnSpecifyItemBelow(items, event, noOfItemHaveDiscount,
                        event.discountByPercent,
                        event.discountPercent,
                        event.discountPrice, eventObj);
                }
            } else if (event.discountType == 0) { //pay a fixed amount
                if (event.discountApply == 0) { //apply to item above
                    let noOfItemHaveDiscount = Math.floor(itemQty / event.minQty);
                    if (event.hasLimitedQty) {
                        noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.limitedQty);
                    }
                    if (event.hasMaxQtyPerBill) {
                        noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.maxQtyPerBill);
                    }
                    let unitPrice = event.fixedPrice;
                    items = this.enjoyDiscountOnFixedPrice(items, event, noOfItemHaveDiscount, unitPrice, eventObj);
                } else if (event.discountApply == 2) { //apply to item below
                    let validDiscountItem = this.countValidDiscountQty(items, event);
                    if (event.hasMinQty && event.itemQty != 0) {
                        validDiscountItem = Math.floor(validDiscountItem / event.itemQty);
                    }
                    let multiply = Math.floor(itemQty / event.minQty);
                    let itemDis = multiply * (1 + event.maxQty - event.minQty);
                    //let repeatedAvailQty = countRepeatedAvailQty(items, event.eventName);
                    let repeatedDiscountQty = this.countRepeatedPromotion(items, event.eventName);
                    //let repeatedDiff = repeatedAvailQty - repeatedDiscountQty;
                    itemDis -= repeatedDiscountQty;
                    //                    if (itemDis < repeatedDiff) {
                    //                        itemDis = repeatedDiff;
                    //                    }

                    let noOfItemHaveDiscount = Math.min(itemDis, validDiscountItem);

                    if (event.hasLimitedQty) {
                        noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.limitedQty);
                    }
                    if (event.hasMaxQtyPerBill) {
                        noOfItemHaveDiscount = Math.min(noOfItemHaveDiscount, event.maxQtyPerBill);
                    }
                    let unitPrice = event.fixedPrice;
                    items = this.enjoyDiscountOnFixedPriceBelow(items, event, noOfItemHaveDiscount, unitPrice, eventObj);
                }
            }
        }

        return items;
    },
    promotionAnyItem(items, event, eventObj) {
        let itemQty = this.countValidQty(items, event, eventObj);

        if (event.hasLimitedAmount && event.limitedAmount <= 0) {
            return items;
        }
        //Enjoy Certain Discount
        if (event.discountType == 1) {
            //if discount on the item
            //all items have discount
            //apply to specific item
            //count no of item can be discounted
            if (event.discountApply == 1) {
                items = this.discountApplyToAll(items, event);
            } else if (event.discountApply == 0 && itemQty > 0) {
                if (event.hasLimitedQty) {
                    itemQty = Math.min(itemQty, event.limitedQty);
                }
                if (event.hasMaxQtyPerBill) {
                    itemQty = Math.min(itemQty, event.maxQtyPerBill);
                }
                items = this.enjoyDiscount(items, event, itemQty,
                    event.discountByPercent,
                    event.discountPercent,
                    event.discountPrice, eventObj);
            } else if (event.discountApply == 2) {
                //apply on certain specified Items
                itemQty = this.countValidDiscountQty(items, event);
                if (event.hasMinQty && event.itemQty != 0) {
                    itemQty = Math.floor(itemQty / event.itemQty);
                }
                if (event.hasLimitedQty) {
                    itemQty = Math.min(itemQty, event.limitedQty);
                }
                if (event.hasMaxQtyPerBill) {
                    itemQty = Math.min(itemQty, event.maxQtyPerBill);
                }
                items = this.enjoyDiscountOnSpecifyItemBelow(items, event, itemQty,
                    event.discountByPercent,
                    event.discountPercent,
                    event.discountPrice, eventObj);
            }
            //following items
        }

        return items;
    },
    isItemSpecifyBelowExcluded(item, event) {
        let isExcluded = false;

        if (event.itemsDiscountExclude.indexOf(item.codeId) > -1) {
            isExcluded = true;
        }


        return isExcluded;
    },
    isItemSpecifyBelowInclude(item, event) {
        let isInclude = false;

        if (event.itemsDiscountInclude.indexOf(item.codeId) > -1) {
            isInclude = true;
        }

        return isInclude;
    },
    isItemDiscountIncluded(item, event) {
        let isIncluded = false;

        if (item.promotion.length > 0 && !event.canStack) {
            return isIncluded;
        }

        if (event.itemsDiscountInclude.indexOf(item.codeId) > -1) {
            isIncluded = true;
        }

        return isIncluded;
    },
    isItemExcluded(item, event) {
        let isExcluded = false;

        if (event.itemsExclude.indexOf(item.codeId) > -1) {
            isExcluded = true;
        }

        return isExcluded;
    },
    isItemIncluded(item, event) {
        let isIncluded = false;

        if (item.promotion.length > 0 && !event.canStack) {
            return isIncluded;
        }


        if (event.itemsInclude.indexOf(item.codeId) > -1) {
            isIncluded = true;
        }

        return isIncluded;
    },
    countValidDiscountQty(items, event) {
        let qty = 0;

        for (let item of items) {
            if (item.qty < 0) continue;
            if (item.isPackage) continue;
            if (item.isVoucher) continue;
            if (!event.canStack && item.promotion.length > 0) continue;
            if (this.isItemDiscountIncluded(item, event)) {
                qty += item.qty;
            }
        }

        return qty;
    },
    isPromoExcluded(item, event, eventObj) {
        //skip checking when the item is refund
        if (item.qty < 0) return false;

        for (let pl of item.promotion) {
            if (event.cantStackWithSpecificPromo) {
                for (let sl of event.listOfPromo) {
                    if (pl.promoId == sl.value) {
                        return true;
                    }
                }
            }
            //same promo, should skip during find lowest index
            if (pl.promoId == event._id) return true;

            //if can stack
            let ed = eventObj[pl.promoId];
            if (ed != null && ed.cantStackWithSpecificPromo) {
                for (let sl of ed.listOfPromo) {
                    if (sl.value == event._id) {
                        return true;
                    }
                }
            }
        }

        return false;
    },
    isPromosExcluded(items, event, eventObj) {
        for (let item of items) {
            if (this.isPromoExcluded(item, event, eventObj)) {
                return true;
            }
        }
        return false;
    },
    findLowestIndexOnSpecifyItemBelow(items, event, eventObj) {
        let lowestIndex = -1;
        for (let k = 0; k < items.length; k++) {
            if (items[k].qty < 0) continue;
            if (items[k].isPackage) continue;
            if (items[k].isVoucher) continue;

            if (this.isPromoExcluded(items[k], event, eventObj)) continue;

            if (this.isItemSpecifyBelowExcluded(items[k], event)) continue;
            //if item is not included in the list, SKIP
            if (!this.isItemSpecifyBelowInclude(items[k], event)) continue;


            if ((items[k].promotion.length == 0 || event.canStack)) {
                if (lowestIndex < 0) {
                    lowestIndex = k;
                } else {
                    // let lowestAmt = items[lowestIndex].price * items[lowestIndex].qty;
                    // let kAmt = items[k].price * items[k].qty;
                    let lowestAmt = items[lowestIndex].price;
                    let kAmt = items[k].price;
                    if (lowestAmt > kAmt) {
                        lowestIndex = k;
                    }
                }
            }
        }

        return lowestIndex;
    },
    findLowestIndex(items, event, eventObj) {
        let lowestIndex = -1;
        for (let k = 0; k < items.length; k++) {
            if (items[k].qty < 0) continue;
            if (items[k].isPackage) continue;
            if (items[k].isVoucher) continue;

            if (this.isPromoExcluded(items[k], event, eventObj)) continue;

            //if item dont have discount
            if (this.isItemExcluded(items[k], event)) continue;
            //if item is not included in the list, SKIP
            if (event.condition == 1 || event.condition == 4 || event.condition == 5) {
                if (!this.isItemIncluded(items[k], event)) continue;
            }
            if (items[k].promotion.length == 0 || event.canStack) {
                if (lowestIndex < 0) {
                    lowestIndex = k;
                } else {
                    // let lowestAmt = items[lowestIndex].price * items[lowestIndex].qty;
                    // let kAmt = items[k].price * items[k].qty;
                    let lowestAmt = items[lowestIndex].price;
                    let kAmt = items[k].price;
                    if (lowestAmt > kAmt) {
                        lowestIndex = k;
                    }
                }
            }
        }
        return lowestIndex;
    },
    subTotalDefaultAmount(cart) {
        let subtotal = 0;

        cart.forEach((item) => {
            let price = 0;

            if (item.fixedPrice) {
                price = item.price * item.qty;
                subtotal = subtotal + price;
                return;
            }

            if (item.optionArray)
                item.optionArray.forEach((element) => {
                    if (element.optionType === 3 || element.optionType === 5 || element.optionType === 6) { //set meal
                        if (element.optionActive === true) {
                            element.options.forEach(function (op) {
                                if (!op.active) return;

                                price = price +
                                    (op && op.price ? op.price : (op.addPrice ? op.addPrice : 0));


                                op.optionArray.forEach((element) => {
                                    element.options.forEach((it) => {
                                        if (it.active) {
                                            if (it.price) {
                                                price += it.price * (it.qty ? it.qty : 1);
                                            } else if (it.addPrice) {
                                                price += it.addPrice * (it.qty ? it.qty : 1);
                                            }
                                        }
                                    })
                                });

                            });
                        }
                    } else {
                        const filteredArray = element.options.filter(
                            (item) => item.active === true
                        );
                        filteredArray.forEach((item) => {
                            price = price + item.addPrice * (item.qty ? item.qty : 1);
                        });
                    }
                });
            let basePrice = item.price * item.qty;
            price = price * item.qty;

            subtotal = subtotal + price + basePrice;
        });

        return subtotal;
    },
    getFinalAmount(cart, discount, serviceCharge, tax) {
        let totalAmount = 0;
        //use for further Discount
        let subTotalDefaultAmount = this.subTotalDefaultAmount(cart);
        cart.forEach((item) => {
            let subtotal = 0;
            let price = 0;
            if (item.fixedPrice) {
                price = item.price * item.qty;
                if (item.promotion) {
                    for (let promo of item.promotion) {
                        if (promo.discountByPercent) {
                            price = price * (1 - promo.discountPercent);
                        } else {
                            price = price - promo.discountPrice;
                        }
                    }
                }
                if (item.hasSpecialDiscount) {
                    if (item.specialDiscount > 0) {
                        price = price * (1 - item.specialDiscount);
                    } else {
                        price = price - item.specialDiscountAmount
                    }
                }
                subtotal = subtotal + price;
                if (discount && discount != null) {
                    if (discount.hasFurtherDiscount) {
                        if (discount.furtherDiscount > 0) {
                            subtotal = subtotal * (1 - discount.furtherDiscount);
                        } else {
                            subtotal = subtotal - discount.furtherDiscountAmount * (this.subTotalDefaultAmount([item]) / subTotalDefaultAmount);
                        }
                    }
                }
                subtotal = subtotal * (1 + serviceCharge);
                totalAmount += subtotal * (1 + (tax.taxMode == 0 ? tax.tax : item.tax));
                return;
            }

            if (item.optionArray)
                item.optionArray.forEach((element) => {

                    if (element.optionType === 3 || element.optionType === 5 || element.optionType === 6) { //set meal
                        if (element.optionActive === true) {
                            element.options.forEach(function (op) {
                                if (!op.active) return;

                                price = price +
                                    (op && op.price ? op.price : (op.addPrice ? op.addPrice : 0));


                                op.optionArray.forEach((element) => {
                                    element.options.forEach((it) => {
                                        if (it.active) {
                                            if (it.price) {
                                                price += it.price * (it.qty ? it.qty : 1);
                                            } else if (it.addPrice) {
                                                price += it.addPrice * (it.qty ? it.qty : 1);
                                            }
                                        }
                                    })
                                });

                            });
                        }
                    } else {
                        const filteredArray = element.options.filter(
                            (item) => item.active === true
                        );
                        filteredArray.forEach((item) => {
                            price = price + item.addPrice * (item.qty ? item.qty : 1);
                        });
                    }
                });

            let basePrice = item.price * item.qty;
            price = price * item.qty;
            if (item.promotion) {
                for (let promo of item.promotion) {
                    if (!promo.excludeOption) {
                        if (promo.discountByPercent) {
                            price = price * (1 - promo.discountPercent);
                        }
                    }
                    if (promo.discountByPercent) {
                        basePrice = basePrice * (1 - promo.discountPercent);
                    } else {
                        basePrice = basePrice - promo.discountPrice;
                    }

                }
            }
            subtotal = price + basePrice;
            if (item.hasSpecialDiscount) {
                if (item.specialDiscount > 0) {
                    subtotal = subtotal * (1 - item.specialDiscount);
                } else {
                    subtotal = subtotal - item.specialDiscountAmount
                }
            }

            if (discount && discount != null) {
                if (discount.hasFurtherDiscount) {
                    if (discount.furtherDiscount > 0) {
                        subtotal = subtotal * (1 - discount.furtherDiscount);
                    } else {
                        subtotal = subtotal - discount.furtherDiscountAmount * (this.subTotalDefaultAmount([item]) / subTotalDefaultAmount);
                    }
                }
            }
            subtotal = subtotal * (1 + serviceCharge);
            totalAmount += subtotal * (1 + (tax.taxMode == 0 ? tax.tax : item.tax));
        });



        return totalAmount;
    },
    subTotalAmount(cart, discount) {
        let subtotal = 0;
        cart.forEach((item) => {
            let price = 0;
            if (item.fixedPrice) {
                price = item.price * item.qty;
                if (item.promotion) {
                    for (let promo of item.promotion) {
                        if (promo.discountByPercent) {
                            price = price * (1 - promo.discountPercent);
                        } else {
                            price = price - promo.discountPrice;
                        }
                    }
                }
                if (item.hasSpecialDiscount) {
                    if (item.specialDiscount > 0) {
                        price = price * (1 - item.specialDiscount);
                    } else {
                        price = price - item.specialDiscountAmount
                    }
                }
                subtotal = subtotal + price;
                return;
            }

            if (item.optionArray)
                item.optionArray.forEach((element) => {
                    if (element.optionType === 3 || element.optionType === 5 || element.optionType === 6) { //set meal
                        if (element.optionActive === true) {
                            element.options.forEach(function (op) {
                                if (!op.active) return;

                                price = price +
                                    (op && op.price ? op.price : (op.addPrice ? op.addPrice : 0));


                                op.optionArray.forEach((element) => {
                                    element.options.forEach((it) => {
                                        if (it.active) {
                                            if (it.price) {
                                                price += it.price * (it.qty ? it.qty : 1);
                                            } else if (it.addPrice) {
                                                price += it.addPrice * (it.qty ? it.qty : 1);
                                            }
                                        }
                                    })
                                });
                            });
                        }
                    } else {
                        const filteredArray = element.options.filter(
                            (item) => item.active === true
                        );
                        filteredArray.forEach((item) => {
                            price = price + item.addPrice * (item.qty ? item.qty : 1);
                        });
                    }
                });

            let basePrice = item.price * item.qty;
            price = price * item.qty;
            if (item.promotion) {
                for (let promo of item.promotion) {
                    if (!promo.excludeOption) {
                        if (promo.discountByPercent) {
                            price = price * (1 - promo.discountPercent);
                        }
                    }
                    if (promo.discountByPercent) {
                        basePrice = basePrice * (1 - promo.discountPercent);
                    } else {
                        basePrice = basePrice - promo.discountPrice;
                    }

                }
            }
            let sb = price + basePrice;
            if (item.hasSpecialDiscount) {
                if (item.specialDiscount > 0) {
                    sb = sb * (1 - item.specialDiscount);
                } else {
                    sb = sb - item.specialDiscountAmount
                }
            }

            subtotal = subtotal + sb
        });

        if (discount && discount != null) {
            if (discount.hasFurtherDiscount) {
                if (discount.furtherDiscount > 0) {
                    subtotal = subtotal * (1 - discount.furtherDiscount);
                } else {
                    subtotal = subtotal - discount.furtherDiscountAmount;
                }
            }
        }

        return subtotal;
    },
    enjoyDiscountOnSpecifyItemBelow(items, event, noOfItemDiscount, discountByPercent, percent, price, eventObj) {
        let loop = noOfItemDiscount;
        for (let j = 0; j < loop; j++) {
            //look for lowest price item
            let lowestIndex = -1;
            //find the lowest price item
            lowestIndex = this.findLowestIndexOnSpecifyItemBelow(items, event, eventObj);
            if (lowestIndex < 0) {
                //no free item, break;
                break;
            } else {

                let newCkItem;
                let discountPrice = 0;
                let discountPercent = 0;
                //discount in $
                if (!discountByPercent) {
                    discountPrice = price;
                } else {
                    discountPercent = percent;
                }

                let ck = JSON.parse(JSON.stringify(items[lowestIndex]));
                ck.qty = (1);
                let ckList = []
                ckList.push(ck);
                let itemAmt = this.subTotalAmount(ckList);

                //check if has max discount
                if (event.hasMaxDiscount && discountByPercent) {
                    let discount = itemAmt * discountPercent;
                    if (discount > event.maxDiscount) {
                        discountByPercent = false;
                        discountPrice = event.maxDiscount;
                    }
                }

                //check if discount Amt > selling Price
                if (discountPrice > itemAmt) {
                    discountPrice = itemAmt;
                }

                if (items[lowestIndex].qty > noOfItemDiscount) {
                    //need to split the item
                    newCkItem = JSON.parse(JSON.stringify(items[lowestIndex]));
                    newCkItem.qty = (noOfItemDiscount);

                    items[lowestIndex].qty = (items[lowestIndex].qty - noOfItemDiscount);

                    discountPrice = discountPrice * newCkItem.qty;

                    newCkItem.promotion.push({
                        promoId: event._id,
                        eventName: event.eventName,
                        discountByPercent,
                        discountPercent,
                        discountPrice,
                        excludeOption: event.excludeOption
                    });

                    items.push(newCkItem);
                    noOfItemDiscount = 0;
                } else {
                    //item qty less than no of discount Item
                    discountPrice = discountPrice * items[lowestIndex].qty;

                    items[lowestIndex].promotion.push({
                        promoId: event._id,
                        eventName: event.eventName,
                        discountByPercent,
                        discountPercent,
                        discountPrice,
                        excludeOption: event.excludeOption
                    });
                    noOfItemDiscount = noOfItemDiscount - Math.floor(items[lowestIndex].qty);
                }

                if (noOfItemDiscount <= 0) {
                    break;
                }

            }
        }
        return items;
    },
    enjoyDiscountOnFixedPriceBelow(items, event, noOfItemDiscount, unitPrice, eventObj) {
        let loop = noOfItemDiscount;
        for (let j = 0; j < loop; j++) {
            //look for lowest price item
            let lowestIndex = -1;
            //find the lowest price item
            lowestIndex = this.findLowestIndexOnSpecifyItemBelow(items, event, eventObj);
            if (lowestIndex < 0) {
                //no free item, break;
                break;
            } else {

                let newCkItem;
                let discountPrice = 0;
                let discountPercent = 0;
                //discount in $
                let defaultPrice = items[lowestIndex].price;


                discountPrice = defaultPrice - unitPrice;

                if (items[lowestIndex].qty > noOfItemDiscount) {
                    //need to split the item
                    newCkItem = JSON.parse(JSON.stringify(items[lowestIndex]));
                    newCkItem.qty = (noOfItemDiscount);

                    items[lowestIndex].qty = (items[lowestIndex].qty - noOfItemDiscount);


                    discountPrice = discountPrice * newCkItem.qty;

                    newCkItem.promotion.push({
                        promoId: event._id,
                        eventName: event.eventName,
                        discountByPercent: false,
                        discountPercent,
                        discountPrice,
                        excludeOption: event.excludeOption
                    });

                    items.push(newCkItem);
                    noOfItemDiscount = 0;
                } else {
                    discountPrice = discountPrice * items[lowestIndex].qty;
                    if (!event.canStack) {
                        items[lowestIndex].hasDiscount = true;
                    }
                    items[lowestIndex].promotion.push({
                        promoId: event._id,
                        eventName: event.eventName,
                        discountByPercent: false,
                        discountPercent,
                        discountPrice,
                        excludeOption: event.excludeOption
                    });
                    noOfItemDiscount = noOfItemDiscount - Math.floor(items[lowestIndex].qty);
                }

                if (noOfItemDiscount <= 0) {
                    break;
                }
            }
        }

        return items;
    },
    enjoyDiscountOnFixedPrice(items, event, noOfItemDiscount, unitPrice, eventObj) {
        let loop = noOfItemDiscount;
        for (let j = 0; j < loop; j++) {
            //look for lowest price item
            let lowestIndex = -1;
            //find the lowest price item
            lowestIndex = this.findLowestIndex(items, event, eventObj);
            if (lowestIndex < 0) {
                //no free item, break;
                break;
            } else {

                let newCkItem;
                let discountPrice = 0;
                let discountPercent = 0;
                //discount in $
                let defaultPrice = items[lowestIndex].price;

                discountPrice = defaultPrice - unitPrice;

                if (items[lowestIndex].qty > noOfItemDiscount) {
                    //need to split the item
                    newCkItem = JSON.parse(JSON.stringify(items[lowestIndex]));
                    newCkItem.qty = (noOfItemDiscount);

                    items[lowestIndex].qty = (items[lowestIndex].qty - noOfItemDiscount);

                    discountPrice = discountPrice * newCkItem.qty;

                    newCkItem.promotion.push({
                        promoId: event._id,
                        eventName: event.eventName,
                        discountByPercent: false,
                        discountPercent,
                        discountPrice,
                        excludeOption: event.excludeOption
                    });

                    items.push(newCkItem);
                    noOfItemDiscount = 0;
                } else {
                    discountPrice = discountPrice * items[lowestIndex].qty;
                    if (!event.canStack) {
                        items[lowestIndex].hasDiscount = true;
                    }
                    items[lowestIndex].promotion.push({
                        promoId: event._id,
                        eventName: event.eventName,
                        discountByPercent: false,
                        discountPercent,
                        discountPrice,
                        excludeOption: event.excludeOption
                    });
                    noOfItemDiscount = noOfItemDiscount - Math.floor(items[lowestIndex].qty);
                }

                if (noOfItemDiscount <= 0) {
                    break;
                }
            }
        }

        return items;
    },
    enjoyDiscount(items, event, noOfItemDiscount, discountByPercent, percent, price, eventObj) {
        console.log("ENJOY DISCOUNT", noOfItemDiscount);
        let loop = noOfItemDiscount;
        let maxDis = event.maxDiscount;
        for (let j = 0; j < loop; j++) {
            //look for lowest price item
            let lowestIndex = -1;
            //find the lowest price item
            lowestIndex = this.findLowestIndex(items, event, eventObj);
            console.log(lowestIndex);
            if (lowestIndex < 0) {
                //no free item, break;
                break;
            } else {
                let newCkItem;
                let discountPrice = 0;
                let discountPercent = 0;
                let byPercent = discountByPercent;
                //discount in $
                if (!discountByPercent) {
                    discountPrice = price;
                }
                if (discountByPercent) {
                    discountPercent = percent;
                }
                //check if has max discount
                let ck = JSON.parse(JSON.stringify(items[lowestIndex]));
                if (items[lowestIndex].qty > noOfItemDiscount) {
                    ck.qty = (noOfItemDiscount);
                }
                let ckList = [];
                ckList.push(ck);
                let itemAmt = this.subTotalAmount(ckList);
                console.log(itemAmt, discountByPercent, event.hasMaxDiscount);
                if (event.hasMaxDiscount && discountByPercent) {
                    if (maxDis <= 0) continue;
                    let discount = itemAmt * discountPercent;
                    if (discount > maxDis) {
                        discountByPercent = false;
                        discountPrice = maxDis;
                        maxDis = 0;
                    } else {
                        maxDis -= itemAmt * discountPercent;
                    }
                }
                //check if discount Amt > selling Price
                if (discountPrice > itemAmt) {
                    discountPrice = itemAmt;
                }

                if (items[lowestIndex].qty > noOfItemDiscount) {
                    //need to split the item
                    newCkItem = JSON.parse(JSON.stringify(items[lowestIndex]));
                    newCkItem.qty = (noOfItemDiscount);

                    items[lowestIndex].qty = (items[lowestIndex].qty - noOfItemDiscount);


                    discountPrice = discountPrice * newCkItem.qty;

                    newCkItem.promotion.push({
                        promoId: event._id,
                        eventName: event.eventName,
                        discountByPercent,
                        discountPercent,
                        discountPrice,
                        excludeOption: event.excludeOption
                    });

                    items.push(newCkItem);
                    noOfItemDiscount = 0;
                } else {

                    discountPrice = discountPrice * items[lowestIndex].qty;

                    items[lowestIndex].promotion.push({
                        promoId: event._id,
                        eventName: event.eventName,
                        discountByPercent,
                        discountPercent,
                        discountPrice,
                        excludeOption: event.excludeOption
                    });
                    noOfItemDiscount = noOfItemDiscount - Math.floor(items[lowestIndex].qty);
                }

                if (noOfItemDiscount <= 0) {
                    break;
                }

            }
        }

        return items;
    },
    enjoyComboDiscount(items, event, noOfItemHaveDiscount) {
        //if pay fixed price, need to know each item minus how much
        let payForFixedPrice = []
        let discountAmount = 0;
        for (let j = 0; j < event.itemsBundle.length; j++) {
            for (let i = 0; i < items.length; i++) {
                if (event.itemsBundle[j].itemId == items[i].codeId) {
                    payForFixedPrice[j] = items[i].price * event.itemBundle[j].qty;
                    break;
                }
            }
        }
        let bundleDefaultPrice = 0;
        //total price for a bundle item, need to split accordingly
        for (let j = 0; j < payForFixedPrice.length; j++) {
            bundleDefaultPrice += payForFixedPrice[j];
        }

        //pay for fixed price
        if (event.discountType == 0) {
            //calculte total bundle discount price
            discountAmount = bundleDefaultPrice - event.fixedPrice;
        } else if (!event.discountByPercent) {
            //count each item pay for how much
            discountAmount = bundleDefaultPrice - event.discountPrice;
        }
        for (let j = 0; j < payForFixedPrice.length; j++) {
            payForFixedPrice[j] = payForFixedPrice[j] / bundleDefaultPrice * discountAmount / event.itemBundle[j].qty;
        }

        for (let j = 0; j < event.itemBundle.length; j++) {
            //use to determine how many item can be discounted
            let bundleItemMaxQty = noOfItemHaveDiscount * event.itemBundle[j].qty;
            for (let i = 0; i < items.length; i++) {
                if (items[i].promotion.length > 0) continue;
                if (items[i].qty < 0) continue;
                if (items[i].isPackage) continue;
                if (items[i].isVoucher) continue;
                if (items[i].codeId == event.itemBundle[j].itemId) {

                    //enjoy certain discount
                    let discountPrice = 0;
                    let discountPercent = 0;
                    //bundle item
                    if (event.discountType == 1) {
                        //discount in $
                        if (!event.discountByPercent) {
                            discountPrice = payForFixedPrice[j];
                        } else {
                            discountPercent = event.discountPercent;
                        }
                        //pay for fixed amount
                    } else if (event.discountType == 0) {
                        event.discountByPercent = (false);
                        discountPrice = payForFixedPrice[j];

                    }

                    //need to split the qty
                    if (items[i].qty > bundleItemMaxQty) {
                        let newCkItem = JSON.parse(JSON.stringify(items[i]));
                        items[i].qty = (items[i].qty - bundleItemMaxQty);

                        //get the following items
                        newCkItem.qty = (bundleItemMaxQty);

                        //multiply by no of items
                        discountPrice = discountPrice * newCkItem.qty;

                        newCkItem.promotion.push({
                            promoId: event._id,
                            eventName: event.eventName,
                            discountByPercent: event.discountByPercent,
                            discountPercent,
                            discountPrice,
                            excludeOption: event.excludeOption
                        });

                        items.push(newCkItem);
                        //no more item will be discounted
                        bundleItemMaxQty = 0;
                    } else {
                        discountPrice = discountPrice * items[i].qty;

                        items[i].promotion.push({
                            promoId: event._id,
                            eventName: event.eventName,
                            discountByPercent: event.discountByPercent,
                            discountPercent,
                            discountPrice,
                            excludeOption: event.excludeOption
                        });

                        bundleItemMaxQty = bundleItemMaxQty - Math.floor(items[i].qty);
                    }
                }
                //item all discounted, next combo item
                if (bundleItemMaxQty <= 0) {
                    break;
                }
            }
        }
        return items;
    },
    countTotalAmount(items, event, eventObj) {
        let totalAmount = 0;
        for (let i = 0; i < items.length; i++) {
            if (this.isItemExcluded(items[i], event)) continue;
            if (event.condition == 4) {
                if (!this.isItemIncluded(items[i], event)) continue;
            }
            if (this.isPromoExcluded(items[i], event, eventObj)) continue;
            totalAmount += this.subTotalAmount([items[i]], "");
        }

        return totalAmount;
    },
    removeContainer(items) {
        let i = 0;
        while (i < items.length) {
            if (items[i].isContainer) {
                items.splice(i, 1);
            } else {
                i++;
            }
        }

        return items;
    },
    applyContainer(items, containers) {
        let ctr = [];

        for (let it of items) {
            if (it.autoAddContainer) {
                for (let c of it.containerIds) {
                    if (!containers || !containers[c]) continue;
                    let ct = JSON.parse(JSON.stringify(containers[c]));
                    ct.qty = it.qty;
                    ctr.push(ct);
                }
            }
        }
        items = items.concat(ctr);
        return items;
    }

}

export default promotion;