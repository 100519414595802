import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, UserContext, ActionContext, LanguageContext } from "../contexts/Context";
import axios from "axios";
import MenuItemDisplay2 from "./MenuItemDisplay2";

const RecommendedEngine = ({
    codeId,
    recommendedEngine,
    lanIndex,
    addRecItemToCart
}) => {
    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { addRecommendation } = React.useContext(ActionContext);
    const { cart, token, recommend, currencySymbol, isLanReverse } = React.useContext(UserContext);
    const [qtyObj, setQty] = useState({});

    useEffect(() => {
        addRecommendation([]);
        let body = {
            codeId: codeId,
            existingIds: [],
            recommendedEngine: recommendedEngine
        }

        for (let it of cart) {
            body.existingIds.push(it.codeId);
        }
        const fetchData = async () => {
            let response = await axios.post('v2/api/selforder/recommend', body, { headers: { Authorization: "Bearer " + token } });
            if (response.status == 200) {
                addRecommendation(response.data);
            }
        };
        fetchData().catch((e) => {
            console.log(e);
        });

        // addRecommendation([
        //     {
        //         codeId: "salmon",
        //         title: "Aburi Salmon Mentai Don",
        //         active: false,
        //         price: 13,
        //         discount: 0,
        //         isSoldOut: false,
        //         description:
        //             "Akamaru Shinaji served with simmered pork belly chashu.",
        //         image: "/rice/AburiSalmonMentaiDon.png",
        //         icons: [0, 1],
        //         optionArray: [

        //         ],

        //     },
        //     {
        //         codeId: "unagi",
        //         title: "Unagi Bento",
        //         active: false,
        //         price: 13,
        //         discount: 0,
        //         isSoldOut: false,
        //         description:
        //             "Akamaru Shinaji served with simmered pork belly chashu.",
        //         image: "/rice/unagibento.png",
        //         icons: [0, 1],
        //         optionArray: [

        //         ],

        //     }])

    }, [])




    return (
        <RecContainer>
            {recommend && recommend.length > 0 &&
                <RecTitle themeContext={themeContext}>
                    {languageContext[lanIndex].frequentBought}
                </RecTitle>
            }
            <RecWrapper>
                {recommend && recommend.map((it, i) =>
                    <MenuItemDisplay2
                        key={i}
                        lanIndex={lanIndex}
                        isLanReverse={isLanReverse}
                        item={it}
                        qty={qtyObj[it.codeId] ? qtyObj[it.codeId] : 0}
                        currencySymbol={currencySymbol}
                        addRecItemToCart={addRecItemToCart}
                    />
                )}
            </RecWrapper>
        </RecContainer>
    );


};

export default RecommendedEngine;

const RecTitle = styled.div`
    font-family: ${(props) => props.themeContext.fontFamily};
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    margin-left: 16px;
`;
const RecContainer = styled.div`
    display:block;
    width: 100%;
    margin-top: 32px;
    padding-top: 16px;
    background: #f5f5f5;
`;
const RecWrapper = styled.div`
    display: flex;
    padding-bottom: 16px;
    overflow-y: hidden;
    overflow-x: auto;
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
`;