import React from "react";
import styled from "@emotion/styled";
import { ThemeContext,LanguageContext } from "../../contexts/Context";

const Modal = ({
  show,
  setShow,
  confirmCheckOut,
  lanIndex
}) => {
  //State
  const displayShow = show ? "flex" : "none";
  const themeContext = React.useContext(ThemeContext);
  const languageContext= React.useContext(LanguageContext);

  const modalContent = () => {
    return (
      <React.Fragment key={0}>
        <ModalContentText
          textAlign={"center"}
          fontSize={16}
          fontWeight={600}
          color={themeContext.fontPrimary}
          margin={10}
        >
          {languageContext[lanIndex].confirmCK}
        <br />
        </ModalContentText>
      </React.Fragment>
    );

  };

  const modalButton = () => {
    return (
      <ModalButtonContainer>
        <ModalConfirmButton
          themeContext={themeContext}
          onClick={() => {
            setShow(false);
          }}
        >
          <ModalText
            fontSize={16}
            fontWeight={600}
            color={themeContext.fontPrimary}
          >
            {languageContext[lanIndex].cancel}
            </ModalText>
        </ModalConfirmButton>
        <ModalConfirmButton
          themeContext={themeContext}
          backgroundColor={themeContext.fontPrimary}
          onClick={() => {
            setShow(false);
            confirmCheckOut();
          }}
        >
          <ModalText
            fontSize={16}
            fontWeight={600}
            color={themeContext.cartFont}
          >
            {languageContext[lanIndex].ok}
            </ModalText>
        </ModalConfirmButton>
      </ModalButtonContainer>
    );
  }

  return (
    <ModalContainer display={displayShow}>
      <ModalMain themeContext={themeContext}>
        <ModalContent themeContext={themeContext}>
          {modalContent()}
        </ModalContent>
        {modalButton()}
      </ModalMain>
    </ModalContainer>
  );
};

export default Modal;

const ModalContainer = styled.div`
  display: ${(props) => props.display};
  position: fixed;
  justify-content: center;
  top: 0;
  align-items: center;
  width: 100vw;
  max-width: 550px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
`;

const ModalMain = styled.div`
  border-radius: 5px;
  position: relative;
  width: 80%;
  padding: 15px;
  font-size: 20px;
  font-weight: 500;
  font-family: ${(props) => props.themeContext.fontFamily};
  background-color: #fff;
  color: black;
  z-index: 10;
`;

const ModalContent = styled.div`
  text-align: ${(props) => (props.error ? "left" : "center")};
  margin-top: 20px;
`;
const ModalButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const ModalConfirmButton = styled.div`
  flex: 1;
  background-color: ${(props) => props.backgroundColor};
  color: white;
  margin: 2%;
  padding: 2%;
  line-height: 28px;
  text-align: center;
  border-radius: 10px;
  border: 2px solid;
  border-color: ${(props) => props.themeContext.fontPrimary};
`;

const ModalContentText = styled.ul`
  align-self: center;
  justify-content: center;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  padding-left: ${(props) => (props.margin ? props.margin : 0)}px;
  padding-right: ${(props) => (props.margin ? props.margin : 0)}px;
`;

const ModalText = styled.span`
  align-self: center;
  justify-content: center;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  padding-left: ${(props) => (props.margin ? props.margin : 0)}px;
  padding-right: ${(props) => (props.margin ? props.margin : 0)}px;
`;
