import React, { useState } from "react";
import { ThemeContext, LanguageContext, UserContext } from "../../contexts/Context";
import styled from "@emotion/styled";
import { BiFoodMenu } from "react-icons/bi";
import { IoPeopleCircleOutline, IoBookmark, IoTime } from "react-icons/io5";
import moment from 'moment';

const ReserveInfo = () => {
    const language = React.useContext(LanguageContext);
    const themeContext = React.useContext(ThemeContext);
    const { lanIndex, reserveData, tableLabel, pax } = React.useContext(UserContext);

    return (
        <React.Fragment>
            <HeaderDisplay themeContext={themeContext}>
                <Wrapper>
                    <IconWraper>
                        <BiFoodMenu fontSize={24} />
                    </IconWraper>
                    <Content themeContext={themeContext} style={{ marginLeft: 8 }}>
                        <SubContent>{language[lanIndex].table}</SubContent>
                        <b>{tableLabel}</b>
                    </Content>
                </Wrapper>
                <Wrapper>
                    <IconWraper>
                        <IoPeopleCircleOutline fontSize={24} />
                    </IconWraper>
                    <Content themeContext={themeContext} style={{ marginLeft: 8 }}>
                        <SubContent>{language[lanIndex].guest}</SubContent>
                        <b>{pax}</b>
                    </Content>
                </Wrapper>
                <Wrapper>
                    <IconWraper>
                        <IoTime fontSize={24} />
                    </IconWraper>
                    <Content themeContext={themeContext} style={{ marginLeft: 8 }}>
                        <SubContent>{language[lanIndex].reserveTime}</SubContent>
                        <b>{reserveData ? moment(reserveData.startTime).format("DD/MM HH:mm") : "-"}</b>
                    </Content>
                </Wrapper>
                <Wrapper>
                    <IconWraper>
                        <IoBookmark fontSize={24} />
                    </IconWraper>
                    <Content themeContext={themeContext} style={{ marginLeft: 8 }}>
                        <SubContent>{language[lanIndex].name}</SubContent>
                        <b>{reserveData ? reserveData.customerName : "-"}</b>
                    </Content>
                </Wrapper>

            </HeaderDisplay>
        </React.Fragment>
    )



}

export default ReserveInfo;

const Content = styled.div`
    
`;
const SubContent = styled.div`
    font-size: 12px;
`;
//Styled Components
const HeaderDisplay = styled.div`
   margin: 0 16px;
   padding:8px;
   border: 1px solid;
   border-radius: 6px;
   margin-bottom: 8px;
   display: flex;
   flex-wrap: wrap;

`;
const Wrapper = styled.div`
    display:flex;
    align-items: center;
    margin-right: 16px;
`;
const IconWraper = styled.div`
   
`;

