import React, { useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../../contexts/Context";
import { IoCloseCircle } from "react-icons/io5";
import moment from 'moment';
const CustomerEdit = ({ show, setShow, setShowError, setError, onSubmit, setShowPdpa }) => {
    const [displayShow, setDisplay] = useState(show ? "grid" : "none");
    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { lanIndex, customer, company, landingImg, mustDob, hideOptionalField } = React.useContext(UserContext);
    const { addCustomer } = React.useContext(ActionContext);

    useEffect(() => {
        setDisplay(show ? "flex" : "none");
    }, [show]);

    const handleChange = (e, name) => {
        console.log(e, name);

        let cus = JSON.parse(JSON.stringify(customer));
        cus[name] = e;
        console.log(cus);
        addCustomer(cus);
    }

    const handleSubmit = () => {

        if (customer.phone == "" || customer.phone.length < 7 || customer.phone.length > 15) {
            setError([{ errorMessage: "Phone no cannot be less than 7 or greater than 15" }]);
            return setShowError(true);
        } else if (customer.pdpa == 0) {
            setError([{ errorMessage: "Must agree the terms & condition" }]);
            return setShowError(true);
        } else if (mustDob) {
            if (typeof customer.dob == "string" && !moment(customer.dob, "DDMMYYYY").isValid()) {
                setError([{ errorMessage: "Incorrect DOB Format" }]);
                return setShowError(true);
            }
            if (typeof customer.dob == 'string')
                customer.dob = moment(customer.dob, "DDMMYYYY").valueOf();

            if (customer.dob == 0) {
                setError([{ errorMessage: "Please Input your DOB" }]);
                return setShowError(true);
            }
        } else if (!mustDob) {
            if (!moment(customer.dob, "DDMMYYYY").isValid()) {
                customer.dob = 0;
            } else {
                customer.dob = moment(customer.dob, "DDMMYYYY").valueOf();
            }

        }

        onSubmit(customer)
    }

    return (
        <React.Fragment>
            <ModalContainer display={displayShow}>
                <IconButton onClick={() => setShow(false)}>
                    <IoCloseCircle size={36} />
                </IconButton>
                <ContentWrapper>
                    <ImgWrapper image={landingImg || company.companyLogo} />

                    <InputWrapper>
                        <ModalContentText
                            fontWeight={400}
                            color={"black"}
                        >
                            {languageContext[lanIndex].phone}
                        </ModalContentText>
                        <PhoneWrapper>
                            <Input width={100} value={customer ? customer.phone : ""} type="number" onChange={(e) => { handleChange(e.target.value, 'phone') }} placeholder="e.g. 91231111" />
                        </PhoneWrapper>
                    </InputWrapper>
                    {!hideOptionalField &&
                        <InputWrapper>
                            <ModalContentText
                                fontWeight={400}
                                color={"black"}
                            >
                                {languageContext[lanIndex].name}
                            </ModalContentText>
                            <PhoneWrapper>
                                <Input width={100} value={customer ? customer.customerName : ""} type="text" onChange={(e) => { handleChange(e.target.value, 'customerName') }} />
                            </PhoneWrapper>
                        </InputWrapper>
                    }
                    {!hideOptionalField &&
                        <InputWrapper>
                            <ModalContentText
                                fontWeight={400}
                                color={"black"}
                            >
                                {languageContext[lanIndex].email + " (" + languageContext[lanIndex].optional + ")"}
                            </ModalContentText>
                            <PhoneWrapper>
                                <Input width={100} value={customer ? customer.email : ""} type="email" onChange={(e) => { handleChange(e.target.value, 'email') }} />
                            </PhoneWrapper>
                        </InputWrapper>
                    }
                    {!hideOptionalField &&
                        <InputWrapper>
                            <ModalContentText
                                fontWeight={400}
                                color={"black"}
                            >
                                {languageContext[lanIndex].gender + " (" + languageContext[lanIndex].optional + ")"}
                            </ModalContentText>
                            <PhoneWrapper>
                                <Input style={{height: 16}} width={8} id="male" name="gender" value={0} type="radio" onChange={(e) => { handleChange(e.target.value, 'gender') }} />
                                <Label for="male">{languageContext[lanIndex].male}</Label><br />
                                <Input style={{height: 16}} width={8} id="female" name="gender" value={1} type="radio" onChange={(e) => { handleChange(e.target.value, 'gender') }} />
                                <Label for="female">{languageContext[lanIndex].female}</Label><br />
                            </PhoneWrapper>
                        </InputWrapper>
                    }
                    {mustDob &&
                        <InputWrapper>
                            <ModalContentText
                                fontWeight={400}
                                color={"black"}
                            >
                                {languageContext[lanIndex].dob + " (DDMMYYYY)" + " (" + languageContext[lanIndex].optional + ")"}
                            </ModalContentText>
                            <PhoneWrapper>
                                <Input width={100} value={customer ? typeof customer.dob == 'number' ? moment(customer.dob).format("DDMMYYYY") : customer.dob : ""} type="text" onChange={(e) => { handleChange(e.target.value, 'dob') }} />
                            </PhoneWrapper>
                        </InputWrapper>
                    }
                    {!hideOptionalField &&
                        <InputWrapper>
                            <ModalContentText
                                fontWeight={400}
                                color={"black"}
                            >
                                {"Password"}
                            </ModalContentText>
                            <PhoneWrapper>
                                <Input type="password" width={100} value={customer ? customer.userPassword : ""} onChange={(e) => { handleChange(e.target.value, 'userPassword') }} style={{ paddingRight: 24 }} />
                            </PhoneWrapper>
                        </InputWrapper>
                    }

                    <InputWrapper>
                        <LoginButton themeContext={themeContext} onClick={handleSubmit} >
                            {languageContext[lanIndex].edit}
                        </LoginButton>
                    </InputWrapper>

                </ContentWrapper>


            </ModalContainer>

        </React.Fragment >
    );
}

export default CustomerEdit;
const Footer = styled.div`
    position: absolute;
    bottom: 8px;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    width: 100%
`;


const WarningMsg = styled.div`
    color: ${(props) => props.themeContext.fontAlert};
`;
const FgButton = styled.div`
    margin-top: 16px;
    font-size: 12px;
    text-decoration: underline;
`;
const LoginButton = styled.div`
    display: flex;
    width: 60%;
    margin: 8px auto;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    border-radius: 25px;
    line-height: 40px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
   
`;
const ImgWrapper = styled.div`
    width: 30%;
    height: 20%;
    margin-top: 10%;
    background-image: url(${(props) => `https://ireachfnb.com${props.image}` || '/toppings/toppings.jpg'});
    /*background-image: url(${(props) => `https://newdemo.chachingfnb.com${props.image}` || '/toppings/toppings.jpg'});*/

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;
const PhoneWrapper = styled.div`
    display: flex;
    width: 80%;
    align-items: center;
`;
const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Input = styled.input`
  height: 30px;
  border-radius: 4px;
  padding-left: 8px;
  font-size: 16px;
  border: 1px solid #aaa;
  margin-bottom: 10px;
  width: ${(props) => props.width}%;
`;
const Label = styled.label`
    margin-bottom: 8px;
`;
const IconButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  background: white;
  z-index: 99;
`;
const ModalContentText = styled.div`
    width: 80%;
    font-weight: 600;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    overflow: auto;
`;

const ModalContainer = styled.div`
    display: ${(props) => props.display};
    position: fixed;
    flex-direction: column;
    background: #fff;
    top: 0;

    width: 100vw;
    max-width: 550px;
    height: 100dvh;
    z-index: 8;

    animation: modalOrder .1s;
    animation-timing-function: ease-out;
    @keyframes modalOrder {
        0%  {
        transform: translateY(350px);
        opacity: 0;
        }
        
        100%  {
        transform: translateY(0px);
        opacity: 1;
        }
        
    }
  `;

