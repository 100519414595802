import React, { useEffect } from "react";
import { ThemeContext, ActionContext, LanguageContext, UserContext } from "../contexts/Context";
import styled from "@emotion/styled";
import { Link } from "@reach/router";
import useWindowDimensions from "../hooks/useWindowDimensions";


const Fail = () => {
  const languageContext = React.useContext(LanguageContext);
  const themeContext = React.useContext(ThemeContext);
  const { width } = useWindowDimensions();
  const { addToCart } = React.useContext(ActionContext);
  const {lanIndex} = React.useContext(UserContext);
  useEffect(() => {
    let order = JSON.parse(localStorage.getItem("order"));
    if (order) {

      addToCart(order);
    }
  }, [addToCart]);

  if (typeof lanIndex === 'undefined') {
    return <></>
  }
  return (
    <BackgroundColor themeContext={themeContext}>
      <Circle className="circle">
        <CheckIcon className="checkmark">-</CheckIcon>
      </Circle>

      <Header className="header">{languageContext[lanIndex].cancel}</Header>
      <Paragraph className="paragraph">.<br /><br />
      </Paragraph>

      <OkButtonContainer width={width}>
        <Link to="/checkout">
          <OkButton width={width} themeContext={themeContext}>
            {languageContext[lanIndex].returnCK}
          </OkButton>
        </Link>
      </OkButtonContainer>

    </BackgroundColor>
  );


}

export default Fail;

const Header = styled.div`
    color: #d50000;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-top: 40px;
    text-align: center;
`;

const Paragraph = styled.div`
color: #404F5E;
font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
font-size:15px;
margin-top: 40px;
text-align: center;
`;

const CheckIcon = styled.div`
    color: #d50000;
    font-family: "Times", "Times New Roman", serif;
    font-size: 200px;
    line-height: 160px;
    text-align: center;
`;

const Circle = styled.div`
    border-radius:200px; 
    height:200px; 
    width:200px; 
    background: #F8FAF5; 
    margin:auto;
    margin-top: 100px;
`;

const BackgroundColor = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  max-width: 550px;
  background-color: ${(props) => props.themeContext.backgroundColor};
  overflow-y: scroll;
  z-index: -10;
`;

const OkButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  position: absolute;
  bottom: 0rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

const OkButton = styled.div`
  display: flex;
  position: fixed;
  bottom: 5px;
  text-align: center;
  color: ${(props) => props.themeContext.cartFont};
  font-family: ${(props) => props.themeContext.fontFamily};
  font-size: 20px;
  background-color: ${(props) =>
    props.themeContext.fontPrimary
  };
  justify-content: center;
  align-items: center;
  height: 40px;
  right: 5px;
  left:5px;
  border: 1px solid darkgrey;
  border-radius: 7px;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 70%);
  
`;
