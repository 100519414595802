import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { ThemeContext, UserContext, ActionContext, LanguageContext } from "../contexts/Context";
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from "react-icons/io";


const MenuItemDisplay2 = ({
    item,
    lanIndex,
    isLanReverse,
    currencySymbol,
    addRecItemToCart
}) => {
    const { cart } = React.useContext(UserContext);
    const themeContext = React.useContext(ThemeContext);
    const [qty2, setQty] = useState(0);

    const updateQtyObj = () => {
        let qty = 0;
        for (let it of cart) {
            if (it.codeId == item.codeId) {
                qty = (it.qty);
                break;
            }
        }
        setQty(qty);
    }

    useEffect(() => {
        updateQtyObj();
    }, [cart]);

    const quantityEdit = (plusOrMinus) => {
        let qt = plusOrMinus ? 1 : -1;
        item.qty = qt;
        item.active = true;
        // console.log(item);
        addRecItemToCart([item]);
    }
    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        item.qty = 1;
        item.active = true;
        addRecItemToCart([item]);
    };

    return (
        <ItemWrapper onClick={handleClick}>
            <ItemDisplay
                image={item.image}
                themeContext={themeContext}
            ></ItemDisplay>
            <OptionsDisplay themeContext={themeContext} >
                <MenuInfoHeaderContainer>
                    <TitleContainer
                        themeContext={themeContext}
                    >{`${(lanIndex == 0 && !isLanReverse) || (lanIndex == 1 && isLanReverse) ? item.title : item.title2 ? item.title2 : item.title}`}</TitleContainer>

                </MenuInfoHeaderContainer>
                <PriceField>
                    {currencySymbol}&nbsp;{item.price}
                </PriceField>
                {/* Quantity Component */}

                <QtyContainer themeContext={themeContext}>
                    {qty2 > 0 &&
                        <QtyButton onClick={(e) => { e.preventDefault(); e.stopPropagation(); quantityEdit(false) }}>
                            <IoIosRemoveCircleOutline />
                        </QtyButton>
                    }
                    {qty2 > 0 &&
                        <QuantityContainer>{qty2}</QuantityContainer>
                    }
                    <QtyButton onClick={(e) => { e.preventDefault(); e.stopPropagation(); quantityEdit(true) }}>
                        <IoIosAddCircleOutline />
                    </QtyButton>
                </QtyContainer>

            </OptionsDisplay>
        </ItemWrapper>
    );


};

export default MenuItemDisplay2;

const ItemWrapper = styled.div`
  display: flex;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 8px;
  padding: 8px;
  border-radius: 8px;
  min-width: 80%;
  width: 80%;
  background: white;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.7);
`;
const ItemDisplay = styled.div`
  display: block;
  text-align: center;
  justify-content: center;
  align-items: center;
  height:  60px;
  width: 60px;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const OptionsDisplay = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 6px;

  font-family: ${(props) => props.themeContext.fontFamily};
  text-align: left;
  font-size: 14px;
  width: calc(100% - 60px);
`;

const MenuInfoHeaderContainer = styled.div`
  display: -webkit-box;
  flex-grow:1;
  -webkit-line-clamp: 2;
  word-wrap:break-word;
  -webkit-box-orient: vertical;
  overflow:hidden;
`;

const TitleContainer = styled.div`
  display: block;
  font-weight: 600;

`;

const PriceField = styled.div`
  display: block;
`;

const QtyContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 30px;
  color: ${(props => props.themeContext.primaryColor)};
`;

const QtyButton = styled.div`
  display:flex;
`;
const QuantityContainer = styled.div`
  display:block;
  font-size: 20px;
  padding: 0px 8px;
  
`;