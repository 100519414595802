import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../../contexts/Context";
import {  IoCallOutline } from "react-icons/io5";
import { IoIosWarning } from "react-icons/io";
import axios from "axios";
import { MdCardMembership } from "react-icons/md";
import Modal from '../optioncomponents/Modal';
// import { adCustomer, campaign2 } from '../../screens/AdCustomer';
const AdvocadoCredit = ({ show, setShow, handleCallback, totalAmount }) => {
    const [displayShow, setDisplay] = useState(show ? "grid" : "none");
    const [credit, setCredit] = useState();
    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { lanIndex, token, userId } = React.useContext(UserContext);
    const { adCustomer } = React.useContext(UserContext);
    const [warningMsg, setWarningMsg] = useState(false);
    const { addAdCustomer } = React.useContext(ActionContext);
    const [error, setError] = useState([]);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        setDisplay(show ? "flex" : "none");

    }, []);
    useEffect(() => {
        getUserInfo();

    }, [userId, token]);
    const getUserInfo = () => {
        if (token && userId) {
            axios.post(`/v2/api/selfOrder/advocado/user`,
                {
                    userId: userId,
                    phone: adCustomer.phoneNumber,
                    countryCode: adCustomer.countryCallingCode.toString(),
                    pin: adCustomer.pin,
                },
                {
                    headers: { Authorization: "Bearer " + token },
                })
                .then(function (response) {
                    if (response.status == 200) {
                        response.data.pin = adCustomer.pin;
                        localStorage.setItem("adCustomer", JSON.stringify(response.data));
                        addAdCustomer(response.data);
                    } else {
                        setError([{ errorMessage: "Something went wrong. Please try again" }]);
                        setShowError(true);
                    }
                })
                .catch(function (response) {
                    //show warning
                    setError([{ errorMessage: "Something went wrong. Please try again" }]);
                    setShowError(true);
                })


        }
    }

    useEffect(() => {
        if (!adCustomer) return;
        if (!adCustomer.campaignBalances) return;
        for (let d of adCustomer.campaignBalances) {
            if (d.campaignType == "stored-value") {
                setCredit(d.values);
            }
        }

    }, [adCustomer]);

    if (!adCustomer) {
        return <></>;
    }

    const modalButton = () => {
        return (
            <ModalButtonContainer>
                <ModalConfirmButton
                    themeContext={themeContext}
                    backgroundColor="#d9d9d9"
                    onClick={() => {
                        setShow(false);
                    }}
                >
                    <ModalText
                        fontSize={16}
                        fontWeight={600}
                        color="#4c4c4c"
                    >
                        {languageContext[lanIndex].cancel}
                    </ModalText>
                </ModalConfirmButton>
                <ModalConfirmButton
                    color="#fff"
                    backgroundColor={themeContext.fontPrimary}
                    themeContext={themeContext}
                    onClick={() => {
                        if (credit < totalAmount) {
                            setWarningMsg(true);
                            return;
                        }

                        setShow(false);
                        handleCallback();
                    }}
                >
                    <ModalText
                        fontSize={16}
                        fontWeight={600}
                        color={themeContext.cartFont}
                    >
                        {languageContext[lanIndex].redeem}
                    </ModalText>
                </ModalConfirmButton>
            </ModalButtonContainer>
        );
    }

    return (
        <React.Fragment>
            <ModalContainer display={displayShow}>
                <ContentWrapper>
                    <HeaderTitle>{languageContext[lanIndex].memberInfo}</HeaderTitle>
                    <Container>
                        <ItemContainer>
                            <Container>
                                <Subtitle>
                                    {languageContext[lanIndex].name}
                                </Subtitle>
                                <Title>
                                    {`${adCustomer.firstName ? adCustomer.firstName : "-"} ${adCustomer.lastName ? adCustomer.lastName : ""}`}
                                    <div style={{ marginLeft: 4, padding: "0px 4px", border: "1px solid #023047", borderRadius: 4 }}><b>{adCustomer.membership.length > 0 ? adCustomer.membership[adCustomer.membership.length - 1].membershipTagName : ""}</b></div>
                                </Title>
                            </Container>

                        </ItemContainer>
                    </Container>
                    <Container>
                        <ItemContainer>
                            <IoCallOutline size={24} />
                            <TextWrapper>
                                <Subtitle>
                                    {languageContext[lanIndex].phone}
                                </Subtitle>
                                <Title style={{ marginLeft: 4 }}>
                                    {`${adCustomer.countryCallingCode} XXXX${adCustomer.phoneNumber.substring(4)}`}
                                </Title>
                            </TextWrapper>
                        </ItemContainer>
                    </Container>
                    {warningMsg &&
                        <div style={{ color: "rgb(207, 0, 13)", fontSize: 12, lineHeight: "12px", marginTop: 8 }}>
                            {languageContext[lanIndex].insufficientCredit}
                        </div>
                    }
                    <PContainer>
                        {credit < totalAmount &&
                            <PItemContainer>
                                <IoIosWarning size={24} />
                                <TextWrapper>
                                    <Title>
                                        {`${languageContext[lanIndex].insufficientCredit}`}
                                    </Title>
                                </TextWrapper>
                            </PItemContainer>
                        }
                        {credit >= totalAmount &&
                            <PItemContainer>
                                <MdCardMembership size={24} />
                                <TextWrapper>
                                    <Subtitle>
                                        Credit
                                    </Subtitle>
                                    <Title>
                                        {`${credit ? credit.toFixed(2) : "-"}`}
                                    </Title>
                                </TextWrapper>
                            </PItemContainer>
                        }
                    </PContainer>
                    {modalButton()}
                </ContentWrapper>
            </ModalContainer>
            <Modal show={showError} setShow={setShowError} error={error} />
            
        </React.Fragment >
    );
}

export default AdvocadoCredit;

const HeaderTitle = styled.div`
    font-size: 24px;
    font-weight: 500;
`;
const Container = styled.div`
    width: 100%;
    margin-bottom: 8px;
`;

const Subtitle = styled.div`
    font-size: 12px;
    font-weight: 300;
`;
const Title = styled.div`
    display: flex;
    font-size: 16px;
    font-weight: 400;
`;
const TextWrapper = styled.div`
    margin-left: 8px;
`;
const PItemContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px;
    margin: 4px;
    border: 1px solid;
    border-radius: 8px;
`;
const ItemContainer = styled.div`
    display: flex;
    align-items: flex-end;
    width: 45%;
    padding: 8px;

`;
const PContainer = styled.div`
    display: flex;
    width: 100%;
`;
const ModalButtonContainer = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
`;

const ModalConfirmButton = styled.div`
  flex: 1;
  background-color: ${(props) => props.backgroundColor};
  margin: 2%;
  margin-bottom: 0;
  padding: 5px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  place-content: center;
  border-radius: 6px;
`;

const ModalText = styled.span`
  align-self: center;
  justify-content: center;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => props.fontSize}px;
  font-weight: ${(props) => props.fontWeight};
  padding-left: ${(props) => (props.margin ? props.margin : 0)}px;
  padding-right: ${(props) => (props.margin ? props.margin : 0)}px;
`;

const ContentWrapper = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    overflow-y: auto;
    margin-top: 0px;
`;
const ModalContainer = styled.div`
    display: ${(props) => props.display};
    position: fixed;
    flex-direction: column;
    background: #fff;
    top: 0;

    width: 100vw;
    max-width: 550px;
    height: 100dvh;
    z-index: 9;

    animation: modalOrder .1s;
    animation-timing-function: ease-out;
    @keyframes modalOrder {
        0%  {
        transform: translateY(350px);
        opacity: 0;
        }
        
        100%  {
        transform: translateY(0px);
        opacity: 1;
        }
        
    }
  `;

