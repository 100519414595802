import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext } from "../../contexts/Context";
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from "react-icons/io";

const QuantityAdjust = ({
  marginTop,
  marginBottom,
  quantity,
  size,
  adjustQuantity,
  lanIndex,
  minQty
}) => {
  const [quantityState, setQuantity] = React.useState(quantity);
  const themeContext = React.useContext(ThemeContext);
  const languageContext = React.useContext(LanguageContext);
  useEffect(() => {
    adjustQuantity(quantityState ? quantityState : 1)
  }, [adjustQuantity, quantityState]);

  const quantityEdit = (value) => {
    let finalQuantity = 0;
    if (value > 0) {
      if (quantityState < 99) {
        finalQuantity = quantityState + 1;
      } else {
        finalQuantity = 99;
      }
    } else if (value < 0) {
      if (quantityState > minQty) {
        finalQuantity = quantityState - 1;
      } else {
        finalQuantity = minQty;
      }
    }
    setQuantity(finalQuantity);
    //adjustQuantity(finalQuantity);
    let element = document.getElementById("itemQty");
    if (element != null) {
      element.classList.add("pax-animate");
      setTimeout(() => {
        element.classList.remove("pax-animate");
      }, 200);
    }

  };

  return (
    <React.Fragment>
      {/* Label */}
      <MenuInfoHeaderContainer themeContext={themeContext}>
        <TitleContainer themeContext={themeContext}>{languageContext[lanIndex].qty} *</TitleContainer>
      </MenuInfoHeaderContainer>
      {/* Component */}
      <RootContainer
        size={size}
        marginTop={marginTop}
        marginBottom={marginBottom}
        themeContext={themeContext}
      >

        <QtyButton onClick={(e) => { e.preventDefault(); e.stopPropagation(); quantityEdit(-1) }}>
          <IoIosRemoveCircleOutline size={size} />
        </QtyButton>
        <QuantityContainer id="itemQty">{quantityState}</QuantityContainer>
        <QtyButton onClick={(e) => { e.preventDefault(); e.stopPropagation(); quantityEdit(1) }}>
          <IoIosAddCircleOutline size={size} />
        </QtyButton>


        {/* <Button size={size} onClick={(e) => { e.preventDefault(); e.stopPropagation(); quantityEdit(-1) }}>
          <HorizontalPlus />
        </Button>
        <QuantityContainer size={size}>{quantityState}</QuantityContainer>
        <Button size={size} onClick={(e) => { e.preventDefault(); e.stopPropagation(); quantityEdit(1) }}>
          <HorizontalPlus />
          <VerticalPlus />
        </Button> */}
      </RootContainer>
    </React.Fragment>
  );
};

export default QuantityAdjust;

const TitleContainer = styled.div`
  display: grid;
  align-items: center;
  min-width: 70%;
  max-width: 100%;
  height: 25px;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 3px;
  font-weight: 600;
  font-family: ${(props) => props.themeContext.fontFamily};
  text-align: left;
`;
const MenuInfoHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${(props) => props.themeContext.borderColor};
  border-bottom: 1px solid ${(props) => props.themeContext.borderColor};
  color: ${(props) => props.themeContext.fontPrimary};
  margin-top: 24px;
  padding-left: 8px;
  padding-right: 8px;
`;

const RootContainer = styled.div`
  display: grid;
  bottom: 0px;
  left: 20px;
  /*position: fixed;*/
  z-index: 7;
  grid-template-columns: 35% 30% 35%;
  justify-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  height: ${(props) => (props.size ? props.size : 30)}px;
  color: ${(props) => props.themeContext.primaryColor};
  width: 100%;
  /*animation: moveQty 0.5s;
  @keyframes moveQty {
    0%  {
      bottom: -100px;
    }
    100%  {
      bottom: 0px;
    }
    */
}
`;

const QuantityContainer = styled.div`
  display: grid;
  align-self: center;
  place-content: center;
  width: ${(props) => (props.size ? props.size : 30)}px;
  height: ${(props) => (props.size ? props.size : 30)}px;
  border-radius: ${(props) => (props.size ? props.size / 6 : 30 / 6)}px;
  font-size: 20px;
  font-weight: 600;
  color: black;
  /*background-color: white; */
`;
const QtyButton = styled.div`
  display: flex;
`;
const Button = styled.div`
  display: flex;
  align-self: center;
  width: ${(props) => (props.size ? props.size : 30)}px;
  height: ${(props) => (props.size ? props.size : 30)}px;
  border-radius: ${(props) => (props.size ? props.size / 2 : 30 / 2)}px;
  background-color: black;
`;
const HorizontalPlus = styled.div`
  position: relative;
  background-color: #ffffff;
  width: 40%;
  height: 5%;
  left: 30%;
  top: 49%;
`;
const VerticalPlus = styled.div`
  position: relative;
  background-color: #ffffff;
  width: 5%;
  height: 40%;
  left: 10%;
  top: 31%;
  transform: translateX(-50%);
`;
