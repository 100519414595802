import React from "react";
import styled from "@emotion/styled";
import TextField from "./TextField";
import { ThemeContext, LanguageContext } from "../../contexts/Context";

const RemarksTab = ({ state, setState, lanIndex }) => {
  const themeContext = React.useContext(ThemeContext);
  const languageContext = React.useContext(LanguageContext);
  return (
    <React.Fragment>
      {/* Label */}
      <MenuInfoHeaderContainer themeContext={themeContext}>
        <TitleContainer themeContext={themeContext}>{languageContext[lanIndex].remark}</TitleContainer>
      </MenuInfoHeaderContainer>
      {/* Component */}
      <RootContainer height={50}>
        <TextField state={state} setState={setState} />
      </RootContainer>
    </React.Fragment>
  );
};

export default RemarksTab;

const TitleContainer = styled.div`
  display: grid;
  align-items: center;
  min-width: 70%;
  max-width: 100%;
  height: 25px;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 3px;
  font-weight: 600;
  font-family: ${(props) => props.themeContext.fontFamily};
  text-align: left;
`;
const MenuInfoHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${(props) => props.themeContext.borderColor};
  border-bottom: 1px solid ${(props) => props.themeContext.borderColor};
  color: ${(props) => props.themeContext.fontPrimary};
  margin-top: 24px;
  padding-left: 8px;
  padding-right: 8px;
  width: auto;
`;

const RootContainer = styled.div`
  display: grid;
  margin-top: 8px;
  margin-left: 8px;
  margin-right:8px;
  justify-items: center;
  margin-bottom: 32px;
`;
