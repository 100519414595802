import React, { useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ThemeContext, LanguageContext, UserContext, ActionContext } from "../../contexts/Context";
import axios from "axios";
import { IoCloseCircle } from "react-icons/io5";
import Modal from '../optioncomponents/Modal';
import AdvocadoPdpa from './AdvocadoPdpa';
import AdvocadoPin from "./AdvocadoPin";

const AdvocadoLogin = ({ show, setShow, handleCallback }) => {
    const [displayShow, setDisplay] = useState(show ? "grid" : "none");
    const themeContext = React.useContext(ThemeContext);
    const languageContext = React.useContext(LanguageContext);
    const { lanIndex, token, userId, company, landingImg, allowRegister } = React.useContext(UserContext);
    const { addAdCustomer } = React.useContext(ActionContext);
    const [phone, setPhone] = useState("");
    const [countryCode, setCountryCode] = useState("65");
    const [pin, setPin] = useState("");
    const [warningMsg, setWarningMsg] = useState("");
    const [isNew, setNew] = useState(false);
    const [isPin, setIsPin] = useState(false);
    const [isMember, setMember] = useState(false);
    const [error, setError] = useState([]);
    const [showError, setShowError] = useState(false);
    const [showPdpa, setShowPdpa] = useState(false);
    const [showPin, setShowPin] = useState(false);
    const [isLoad, setLoad] = useState(false);
    const [processing, setProcessing] = useState(false);
    useEffect(() => {
        setDisplay(show ? "flex" : "none");
    }, [show]);


    const handlingLogin = () => {
        if (isMember) { //is existing member
            if (!isPin) { //havent input Pin yet or wrong password
                setShowPin(true);
            } else {//user is Valid
                getUserInfo();
            }
        } else if (!allowRegister) {
            setError([{ errorMessage: "Please register from Advocado Qr" }]);
            setShowError(true);
        } else {
            if (isNew) {
                setShowPin(true);
            } else if (!isNew || isPin) {
                setShowPdpa(true);
            } else {
                setShowPin(true);
            }
        }
    }

    useEffect(() => {
        if (!isLoad) return;
        handlingLogin();
    }, [isMember, isNew, isPin])

    const memberSubmit = () => {
        if (phone == "") {
            setWarningMsg("Phone No is required");
            return;
        } else if (countryCode == "") {
            setWarningMsg("Country Code is required");
            return;
        }
        // else if (pin == "") {
        //     setWarningMsg("Pin is required");
        //     return;
        // }
        if (processing) return;
        setProcessing(true);
        if (token && userId) {
            axios.post(`/v2/api/selfOrder/advocado`,
                {
                    userId: userId,
                    phone: phone,
                    countryCode: countryCode,
                    // pin: pin
                },
                {
                    headers: { Authorization: "Bearer " + token },
                })
                .then(function (response) {
                    setProcessing(false);
                    console.log(response.status);
                    if (response.status == 200) {
                        setLoad(true);
                        setMember(response.data.isMember);
                        setIsPin(response.data.isPin);
                        setNew(response.data.isNew);
                        if(response.data.isMember == isMember && response.data.isPin == isPin && response.data.isNew == isNew){
                            handlingLogin();
                        }
                        // handlingLogin();
                        // if (response.data.isMember && response.data.isNew) {
                        //     if (allowRegister) {
                        //         // setAdCustomer(response.data.adCustomer);
                        //         //display pdpa
                        //         setNew(true);
                        //         setShowPdpa(true);
                        //     } else {
                        //         setError([{ errorMessage: "Please register from Advocado Qr" }]);
                        //         setShowError(true);
                        //     }
                        // } else if (response.data.isMember && !response.data.isPin) {
                        //     setError([{ errorMessage: "Incorrect Pin" }]);
                        //     setShowError(true);
                        // } else if (response.data.isMember && !response.data.isNew) {
                        //     localStorage.setItem("adCustomer", JSON.stringify(response.data.adCustomer));
                        //     addAdCustomer(response.data.adCustomer);
                        //     handleCallback();
                        // } else if (response.data.isNew) {
                        //     if (allowRegister) {
                        //         setAdCustomer(response.data.adCustomer);
                        //         //display pdpa
                        //         setShowPdpa(true);
                        //     } else {
                        //         setError([{ errorMessage: "Please register from Advocado Qr" }]);
                        //         setShowError(true);
                        //     }
                        // } else {
                        //     console.log("RETURN then");
                        //     setError([{ errorMessage: response.data.errorMsg }]);
                        //     setShowError(true);
                        // }
                    } else {
                        console.log("RETURN then");
                        setError([{ errorMessage: "Something went wrong. Please try again" }]);
                        setShowError(true);
                    }

                })
                .catch(function (response) {
                    //show warning
                    setProcessing(false);
                    console.log("RETURN catch");
                    setError([{ errorMessage: "Something went wrong. Please try again" }]);
                    setShowError(true);
                })


        }
    }

    const getUserInfo = () => {
        if (token && userId) {
            axios.post(`/v2/api/selfOrder/advocado/user`,
                {
                    userId: userId,
                    phone: phone,
                    countryCode: countryCode,
                    pin: pin,
                },
                {
                    headers: { Authorization: "Bearer " + token },
                })
                .then(function (response) {
                    if (response.status == 200) {
                        response.data.pin = pin;
                        localStorage.setItem("adCustomer", JSON.stringify(response.data));
                        addAdCustomer(response.data);
                        handleCallback();
                    } else {
                        setError([{ errorMessage: "Something went wrong. Please try again" }]);
                        setShowError(true);
                    }
                })
                .catch(function (response) {
                    //show warning
                    setError([{ errorMessage: "Something went wrong. Please try again" }]);
                    setShowError(true);
                })


        }
    }
    const forgetPass = () => {
        if (phone == "") {
            setWarningMsg("Phone No is required");
            return;
        } else if (countryCode == "") {
            setWarningMsg("Country Code is required");
            return;
        }
        if (processing) return;
        setProcessing(true);
        if (token && userId) {
            axios.post(`/v2/api/selfOrder/advocado/forgetPass`,
                {
                    userId: userId,
                    phone: phone,
                    countryCode: countryCode
                },
                {
                    headers: { Authorization: "Bearer " + token },
                })
                .then(function (response) {
                    setProcessing(false);
                    console.log(response.status);
                    if (response.status == 200) {
                        setError([{ errorMessage: "PIN reset message will be sent via SMS" }]);
                        setShowError(true);

                    } else {
                        console.log("RETURN then");
                        setError([{ errorMessage: "Something went wrong. Please try again" }]);
                        setShowError(true);
                    }

                })
                .catch(function (response) {
                    //show warning
                    setProcessing(false);
                    console.log("RETURN catch");
                    setError([{ errorMessage: "Something went wrong. Please try again" }]);
                    setShowError(true);
                })


        }
    }
    const pinVerify = () => {
        // setShowPin(false);
        // setShow(false);
        // handleCallback();
        // handlingLogin();
    }
    const agreePdpa = () => {
        if (token && userId) {
            axios.post(`/v2/api/selfOrder/advocado/pdpa`,
                {
                    userId: userId,
                    phone: phone,
                    countryCode: countryCode,
                    pin: pin,
                    pdpa: 1
                },
                {
                    headers: { Authorization: "Bearer " + token },
                })
                .then(function (response) {
                    if (response.status == 200) {
                        // localStorage.setItem("adCustomer", JSON.stringify(response.data.adCustomer));
                        // addAdCustomer(response.data.adCustomer);
                        // if (isNew) {
                        //     setShowPin(true);
                        //     setShowPdpa(false);
                        // } else {
                        //     setShowPdpa(false);
                        //     handleCallback();
                        // }
                        setMember(true);
                        setShowPdpa(false);
                        // handlingLogin();
                    } else {
                        setError([{ errorMessage: "Something went wrong. Please try again" }]);
                        setShowError(true);
                    }
                })
                .catch(function (response) {
                    //show warning
                    setError([{ errorMessage: "Something went wrong. Please try again" }]);
                    setShowError(true);
                })


        }
    }

    const handleChange = (e, name) => {
        if (name == 'phone') {
            setPhone(e);
        } else if (name == "countryCode") {
            setCountryCode(e);
        } else {
            setPin(e);
        }
        setWarningMsg("");
    }

    return (
        <React.Fragment>
            <ModalContainer display={displayShow}>
                <IconButton onClick={() => setShow(false)}>
                    <IoCloseCircle size={36} />
                </IconButton>
                <ContentWrapper>
                    <ImgWrapper image={landingImg || company.companyLogo} />
                    <WarningMsg themeContext={themeContext}>
                        {warningMsg}
                    </WarningMsg>
                    <InputWrapper>
                        <ModalContentText
                            fontWeight={400}
                            color={"black"}
                        >
                            {languageContext[lanIndex].phone}
                        </ModalContentText>
                        <PhoneWrapper>
                            <Input width={15} value={countryCode} onChange={(e) => { handleChange(e.target.value, 'countryCode') }} />
                            <Input width={60} value={phone} onChange={(e) => { handleChange(e.target.value, 'phone') }} />
                        </PhoneWrapper>

                    </InputWrapper>
                    {/* <InputWrapper>
                        <ModalContentText
                            fontWeight={400}
                            color={"black"}
                        >
                            {"Pin (4 digit)"}
                        </ModalContentText>
                        <PhoneWrapper>
                            <Input type="password" width={75} value={pin} onChange={(e) => { handleChange(e.target.value, 'pin') }} style={{ paddingRight: 24 }} pattern="[0-9]*" inputmode="numeric" />
                        </PhoneWrapper>
                    </InputWrapper> */}
                    <InputWrapper>
                        <LoginButton themeContext={themeContext} onClick={memberSubmit} >
                            {languageContext[lanIndex].login} {allowRegister ? " | " + languageContext[lanIndex].register : ""}
                        </LoginButton>
                    </InputWrapper>
                    {/* <InputWrapper>
                        <FgButton onClick={forgetPass}>
                            {languageContext[lanIndex].forgetPwd}
                        </FgButton>
                    </InputWrapper> */}
                </ContentWrapper>
                <Footer>
                    Powered by Advocado
                    <img src="/advocado.png" width={24} height={24} style={{ marginLeft: 8 }} />
                </Footer>
            </ModalContainer>
            <Modal show={showError} setShow={setShowError} error={error} />
            <AdvocadoPdpa show={showPdpa} setShow={setShowPdpa} setShowError={setShowError} setError={setError} handleCallback={agreePdpa} />
            <AdvocadoPin
                show={showPin}
                setShow={setShowPin}
                setShowError={setShowError}
                setError={setError}
                handleCallback={pinVerify}
                forgetPass={forgetPass}
                phoneNumber={phone}
                countryCallingCode={countryCode}
                pin={pin}
                isNew={isNew}
                setNew={setNew}
                setPin={setPin}
                setIsPin={setIsPin}
                setMember={setMember}
            />
        </React.Fragment >
    );
}

export default AdvocadoLogin;
const Footer = styled.div`
    position: absolute;
    bottom: 8px;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    width: 100%
`;
const WarningMsg = styled.div`
    color: ${(props) => props.themeContext.fontAlert};
`;
const FgButton = styled.div`
    margin-top: 16px;
    font-size: 12px;
    text-decoration: underline;
`;
const LoginButton = styled.div`
    display: flex;
    width: 60%;
    margin: 8px auto;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    border-radius: 25px;
    line-height: 40px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
   
`;
const ImgWrapper = styled.div`
    width: 30%;
    height: 20%;
    margin-top: 10%;
    background-image: url(${(props) => `https://ireachfnb.com${props.image}` || '/toppings/toppings.jpg'});
    /*background-image: url(${(props) => `https://newdemo.chachingfnb.com${props.image}` || '/toppings/toppings.jpg'});*/

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;
const PhoneWrapper = styled.div`
    display: flex;
    width: 80%;
`;
const InputWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Input = styled.input`
  height: 30px;
  border-radius: 4px;
  padding-left: 16px;
  font-size: 16px;
  border: 1px solid #aaa;
  margin-bottom: 10px;
  width: ${(props) => props.width}%;
`;

const IconButton = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  background: white;
  z-index: 99;
`;
const ModalContentText = styled.div`
    width: 80%;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow:1;
    overflow: auto;
`;

const ModalContainer = styled.div`
    display: ${(props) => props.display};
    position: fixed;
    flex-direction: column;
    background: #fff;
    top: 0;

    width: 100vw;
    max-width: 550px;
    height: 100dvh;
    z-index: 8;

    animation: modalOrder .1s;
    animation-timing-function: ease-out;
    @keyframes modalOrder {
        0%  {
        transform: translateY(350px);
        opacity: 0;
        }
        
        100%  {
        transform: translateY(0px);
        opacity: 1;
        }
        
    }
  `;

