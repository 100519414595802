import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router"
import styled from "@emotion/styled";

import PaxWarningModal from '../newcomponents/optioncomponents/PaxWarningModal';
import ReviewOrderModal from "../newcomponents/ReviewOrderModal";
import { UserContext, ActionContext, ThemeContext, LanguageContext } from "../contexts/Context";
import AdvocadoLogin from '../newcomponents/advocadoComponents/AdvocadoLogin';
import PaxModel from "../newcomponents/PaxModel";
import CustomerLogin from '../newcomponents/customerComponents/CustomerLogin';
import TakeAwayModal from '../newcomponents/optioncomponents/TakeAwayModal';
import DeliveryOrNotModal from '../newcomponents/optioncomponents/DeliveryOrNotModal';

import moment from 'moment';
import axios from "axios";
const CheckIn = ({ token, reserveId }) => {

  const { registerUserData, registerPax, updateTitle, setLanIndex, setTkType, addAdCustomer, setReserveData, addCustomer, addTakeAwayInfo } = React.useContext(ActionContext);
  const { company, ads, theme, hasCategoryScreen, greetingMessage, isTakeAway, isMenuView, skipDIPickUpTime, skipCustomerDetail,
    title, supportMultiLan, lanIndex, skipPaxControl, menus, tableLabel, hasMember, haveToLogin, currencySymbol,
    tkDiMenuDiff, hasAdvocado, selectPUTimeFirst, deliveryOrNot, hasTakeAway, tkType, takeAwayInfo, includeDineIn,
    showKitchenStatus
  } = React.useContext(
    UserContext
  );
  const languageContext = React.useContext(LanguageContext);
  const themeContext = React.useContext(ThemeContext);
  const userContext = React.useContext(UserContext);

  const [pax, setPax] = useState(0);
  const [isSubmit, setSubmit] = useState(false);
  const [showPax, setShowPax] = useState(false);
  const [time, setTime] = useState("");
  const [show, setShow] = useState(false);
  const [showAdvocado, setAdvocado] = useState(false);
  const [showCustomer, setCustomer] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false);
  const [showPickUpTime, setShowPickUpTime] = useState(false);
  const [hasFillUp, setHasFillUp] = useState(false);
  // let menus = ['ala carte', 'buffet'];

  //reset checkin
  localStorage.setItem("checkIn", new Date().getTime());

  const editPax = (value) => {
    if (pax >= 0 && pax <= themeContext.maxPax) {
      if (value > 0 && pax !== themeContext.maxPax) {
        setPax(pax + value);
      }
      if (value < 0 && pax !== 0) {
        setPax(pax + value);
      }
    }
  };

  useEffect(() => {
    if (pax > 0 && isSubmit) {
      handlingClick();
    }
  }, [pax, isSubmit]);
  useEffect(() => {
    localStorage.removeItem("finalOrder");
    localStorage.removeItem("order");
    localStorage.removeItem("adCustomer");
    localStorage.removeItem("customer");
    updateTitle("");

  }, []);


  useEffect(() => {
    console.log({ reserveId });
    localStorage.removeItem("reserveData");
    if (reserveId == "" || reserveId == null) return;
    //pull reservation data
    axios.get('/v2/api/selforder/reserve?reserveId=' + reserveId,
      {
        headers: {
          Authorization: "Bearer " + token,
        }
      })
      .then(function (response) {
        setReserveData(response.data);
        setPax(response.data.pax);
        localStorage.setItem("reserveData", JSON.stringify(response.data));
        registerPax(response.data.pax);
        setTkType(4);
        //skip no of pax, straigh away to menu
        handlingClick();
      });

  }, [reserveId]);


  useEffect(() => {
    let currentDate = new Date().getTime();
    setTime(currentDate);
    //setuser

    const makeid = (length) => {
      var result = [];
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result.push(
          characters.charAt(Math.floor(Math.random() * charactersLength))
        );
      }
      return result.join("");
    };

    const generateUserId = () => {
      let currentDate = new Date().getTime();
      return makeid(20) + currentDate;
    };
    console.log("Identify User");
    registerUserData(token, generateUserId(), currentDate);

  }, [registerUserData, token]);

  useEffect(() => {
    if (!menus) return;
    if ((skipPaxControl != null && skipPaxControl === true && skipPaxControl !== "" && !tkDiMenuDiff) ||
      (isMenuView != null && isMenuView === true && isMenuView !== '')
    ) {
      if (menus && menus.length > 1) { return; }
      if (menus && menus.length == 1) {
        updateTitle(menus[0]);
      }

      if (ads.length > 0)
        navigate("/promo");
      else if (hasCategoryScreen)
        navigate("/category");
      else
        navigate("/menu");
      window.history.pushState(null, null, "/menu");
    }
  }, [skipPaxControl, menus, isMenuView]);

  const handlingPaxCallback = (pax) => {
    setPax(pax);
    setSubmit(true);
  }
  const handleTitleClick = (title) => () => {
    updateTitle(title);
  }
  const handlingView = () => {
    setShowOrder(true);
  }

  const setDeliveryPrice = (value) => {
    takeAwayInfo.deliveryPrice = value;
    addTakeAwayInfo(takeAwayInfo);
  }

  const confirmDelivery = async () => {
    if (tkType == 1 && skipDIPickUpTime && skipCustomerDetail) {
      confirmTKCheckOut("", "", moment().valueOf(), "", "", [], tkType, "");
      return;
    }
    setShowPickUpTime(true);
  }
  const confirmTKCheckOut = async (customerName, phone, scheduleTime, address, remark, checkbox, deliveryType, roomNo) => {
    takeAwayInfo.customerName = customerName;
    takeAwayInfo.phone = phone;
    takeAwayInfo.scheduleTime = scheduleTime;
    takeAwayInfo.address = address;
    takeAwayInfo.remark = remark;
    takeAwayInfo.checkbox = checkbox;
    takeAwayInfo.deliveryType = deliveryType;
    takeAwayInfo.roomNo = roomNo;
    addTakeAwayInfo(takeAwayInfo);
    setHasFillUp(true);

  };

  useEffect(() => {
    if (hasFillUp) {
      handlingClick();
    }
  }, [hasFillUp])

  const handlingClick = () => {
    if (menus && menus.length == 1) {
      updateTitle(menus[0]);
    }

    if (tkDiMenuDiff && isTakeAway) { //dine in, takeaway has different menu, need to setTkType
      let tt = "";
      if (menus && menus.length == 1) {
        tt = menus[0];
      } else {
        tt = title;
      }
      tt = tt.trim().toLocaleLowerCase();
      if (tt.includes("dinein") || tt.includes("dine in")) {
        setTkType(1);
      } else if (reserveId != null && reserveId) {
        setTkType(4);
      } else {
        setTkType(0);
      }
    }

    if (isTakeAway && selectPUTimeFirst && !hasFillUp) {
      if (deliveryOrNot && hasTakeAway) {
        setShowDelivery(true);
      } else
        setShowPickUpTime(true);

      return;
    }

    if (pax == 0 && !skipPaxControl && (reserveId == "" || reserveId == null)) {
      setShowPax(true);
    } else if (menus && menus.length > 1 && title == "") {
      setShow(true);
    } else if (ads.length > 0) {
      if (reserveId == "" || reserveId == null) {
        registerPax(pax);
      }
      navigate("/promo");
      window.history.pushState(null, null, "/menu");
    } else if (hasCategoryScreen) {
      navigate("/category");
    } else {
      if (reserveId == "" || reserveId == null) {
        registerPax(pax);
      }
      navigate("/menu");
      window.history.pushState(null, null, "/menu");
    }
  }

  if (typeof lanIndex === 'undefined') {
    return <></>
  }
  return (
    <CheckinBox themeContext={themeContext}
      userContext={userContext}>
      {(company._id && supportMultiLan) &&
        <MenuWrapperNew themeContext={themeContext}>
          <MenuTitleNew isSelect={lanIndex == 0} themeContext={themeContext} onClick={() => setLanIndex(0)}>
            En
          </MenuTitleNew>
          <MenuTitleNew isSelect={lanIndex == 1} themeContext={themeContext} onClick={() => setLanIndex(1)}>
            中
          </MenuTitleNew>
        </MenuWrapperNew>
      }
      <TipText themeContext={themeContext}>
        <div style={{ fontSize: 28, padding: '0 0 8px', fontWeight: 500 }}>{languageContext[lanIndex].welcome} </div>
        <div style={{ fontSize: 22, paddingBottom: 8, fontWeight: 500 }}>{company.companyName}</div>
        <div style={{ marginBottom: 6, fontSize: isTakeAway ? 30 : 18 }}>{!isTakeAway ? `${languageContext[lanIndex].table}: ${tableLabel}` : languageContext[lanIndex].takeAway}</div>
        <div>{greetingMessage}</div>
        {!skipPaxControl &&
          <div style={{ marginTop: 2 }}>{languageContext[lanIndex].noOfPax}</div>
        }
      </TipText>
      <MenuImage PromptContainer userContext={userContext}
        image={userContext.landingImg || company.companyLogo}
      ></MenuImage>
      {menus && menus.length > 1 &&
        <MenuWrapper themeContext={themeContext}>
          {menus.map((tt, index) =>
            <MenuTitle isSelect={title == tt} themeContext={themeContext} key={index} onClick={handleTitleClick(tt)}>
              {tt}
            </MenuTitle>
          )}
        </MenuWrapper>
      }

      <PaxContainer themeContext={themeContext}>
        {!skipPaxControl &&
          <PaxButtonContainer themeContext={themeContext}>
            <PaxNumTitle style={{ fontSize: 24 }} themeContext={themeContext}>Guest: </PaxNumTitle>
            <PaxButton
              style={{ fontSize: 38, lineHeight: '26px' }}
              themeContext={themeContext}
              onClick={() => {
                editPax(-1);
                let element = document.getElementById("pax");
                if (element != null) {
                  element.classList.add("pax-animate");
                  setTimeout(() => {
                    element.classList.remove("pax-animate");
                  }, 200);

                }
              }}
            >-</PaxButton>
            <PaxNum themeContext={themeContext} id="pax">{pax}</PaxNum>
            <PaxButton
              style={{ fontSize: 38, lineHeight: '26px' }}
              themeContext={themeContext}
              onClick={() => {
                editPax(1);
                let element = document.getElementById("pax");
                if (element != null) {
                  element.classList.add("pax-animate");
                  setTimeout(() => {
                    element.classList.remove("pax-animate");
                  }, 200);
                }

              }}
            >+</PaxButton>
          </PaxButtonContainer>
        }
        <div style={{ display: 'flex', justifyContent: "center", marginTop: 24 }}>
          <ViewBillButton
            themeContext={themeContext}
            onClick={handlingView}
          >
            {showKitchenStatus ? languageContext[lanIndex].reviewOrderStatus :languageContext[lanIndex].reviewOrder}
          </ViewBillButton>
        </div>

        {!haveToLogin &&
          <EnterButton
            themeContext={themeContext}
            onClick={() => {
              addCustomer("");
              addAdCustomer("");
              handlingClick();
            }}
          >
            {hasAdvocado === true || hasMember === true ? languageContext[lanIndex].loginAsGuest : languageContext[lanIndex].enter}
          </EnterButton>
        }
        {hasAdvocado === true || hasMember === true &&
          <Divider ><DividerText themeContext={themeContext}>{languageContext[lanIndex].or}</DividerText></Divider>
        }
        {hasAdvocado === true ?
          <AdvocadoBtn
            theme={theme}
            onClick={() => setAdvocado(true)}>
            {/* <img src={theme != 0 ? "/advocado.png" : ""} style={{ height: 24, width: 24, marginRight: 8 }} /> */}
            {languageContext[lanIndex].MemberLogin}
          </AdvocadoBtn>
          : <></>
        }
        {hasMember === true ?
          <AdvocadoBtn
            theme={theme}
            onClick={() => setCustomer(true)}>
            {/* <img src={theme != 0 ? "/advocado.png" : ""} style={{ height: 24, width: 24, marginRight: 8 }} /> */}
            {languageContext[lanIndex].MemberLogin}
          </AdvocadoBtn>
          : <></>
        }
      </PaxContainer>
      <PaxWarningModal
        pax={pax}
        menus={menus}
        title={title}
        show={show}
        setShow={setShow}
      />
      {showOrder &&
        <ReviewOrderModal
          show={showOrder}
          setShow={setShowOrder}
        />
      }
      {showAdvocado &&
        <AdvocadoLogin
          show={showAdvocado}
          setShow={setAdvocado}
          handleCallback={handlingClick}
        />
      }
      {showCustomer &&
        <CustomerLogin
          show={showCustomer}
          setShow={setCustomer}
          handleCallback={handlingClick}
        />
      }
      {showPax &&
        <PaxModel
          show={showPax}
          setShow={setShowPax}
          handleCallback={handlingPaxCallback}
        />
      }
      {showPickUpTime &&
        <TakeAwayModal show={showPickUpTime} setShowTk={setShowPickUpTime} deliveryType={tkType} confirmTKCheckOut={confirmTKCheckOut}
          setDeliveryPrice={setDeliveryPrice} deliveryPrice={takeAwayInfo ? takeAwayInfo.deliveryPrice : 0} lanIndex={lanIndex} subtotal={0} currencySymbol={currencySymbol} />
      }
      {showDelivery &&
        <DeliveryOrNotModal show={showDelivery} setShowDelive={setShowDelivery} type={tkType} setDeliveryType={setTkType} deliveryOrNot={deliveryOrNot}
          confirmDelivery={confirmDelivery} lanIndex={lanIndex} deliveryPrice={takeAwayInfo ? takeAwayInfo.deliveryPrice : 0} currencySymbol={currencySymbol} includeDineIn={includeDineIn}
          tkDiMenuDiff={tkDiMenuDiff} hasTakeAway={hasTakeAway} />
      }
    </CheckinBox>
  );
};

export default CheckIn;
const AdvocadoBtn = styled.div`
  display:${(props) => props.theme == 0 ? "none" : "flex"};
  width: ${(props) => props.theme == 0 ? "0px" : "66%"};
  margin: 8px auto;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 25px;
  line-height: 48px;
  box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
`;
const DividerText = styled.div`
  background: white;
  padding: 0 8px;
  z-index: 2;
  font-size: 12px;
  margin-top: 3px;
  background-color: ${(props) => props.themeContext.backgroundColor};
  
`;
const Divider = styled.div`
  display: flex;
  justify-content: center;
  margin: 16px 0px;
  &:after {
    content: " ";
    width: 60%;
    position: absolute;
    margin-top: 12px;
    height: 1px;
    background: grey;
  }
`;

const CheckinBox = styled.div`
  position: relative;
  height: 100dvh;
  max-width: 550px;
  width: 100vw;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => props.themeContext.backgroundColor};
}
`
const TipText = styled.div`
  
  font-size:18px;
  color: black;
  margin-bottom: 0px;
  text-align: center;
  margin: 0 auto;
  display: block;
  color: ${(props) => props.themeContext.primaryColor};
`
const MenuImage = styled.div`
  width: 150px;
  min-height: 150px;
  margin: 3vh auto;
  border-radius: 50%;
  background-image: url(${(props) => `https://ireachfnb.com${props.image}` || '/toppings/toppings.jpg'});
  /*background-image: url(${(props) => `https://newdemo.chachingfnb.com${props.image}` || '/toppings/toppings.jpg'});*/
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

const PaxContainer = styled.div`
  width: 100%;
`;

const PaxButton = styled.div`
  &:active{
    background: rgba(0,0,0,0.1);
  }
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  color: ${(props) => props.themeContext.primaryColor};
  box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
  transition: background 0.1s;
`
const PaxButtonContainer = styled.div`
  display: -webkit-flex;
  color: white;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
`;
const PaxNumTitle = styled.div`
  font-size: 36px;
  font-weight: 400;
  padding: 0 15px;
  color: ${(props) => props.themeContext.primaryColor};
`;
const PaxNum = styled.div`
  font-size: 36px;
  font-weight: 400;
  padding: 0 15px;
  color: ${(props) => props.themeContext.primaryColor};

`;
const EnterButton = styled.div`
  width: 66%;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 400;
  color: white;
  text-align: center;
  line-height: 48px;
  margin: 5vh auto;
  margin-bottom: 8px;
  margin-top: 2vh;
  background: ${(props) => props.themeContext.primaryColor};
  box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
`;
const ViewBillButton = styled.div`
  text-align: center;
  width: 150px;
  text-decoration: underline;
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;
const MenuTitle = styled.div`
  display:flex;
  width: fit-content;
  background-color: ${(props) => !props.isSelect ? "white" : props.themeContext.primaryColor};
  border-radius: 6px;
  margin: 0 4px;
  padding: 6px 10px;
  color:${(props) => !props.isSelect ? props.themeContext.primaryColor : "white"};
  box-shadow: ${(props) => props.isSelect ? "" : "0px 1px 3px rgba(0,0,0,0.3)"};
  transition: all 0.3s;
  font-size: 16px;
  text-align:center
`;
const MenuWrapperNew = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`
const MenuTitleNew = styled.div`
  width: fit-content;
  background-color: ${(props) => props.themeContext.primaryColor};
  border-radius: 6px;
  font-size: 16px;
  color: white;
  text-align: center;
  display: ${(props) => !props.isSelect ? 'block' : "none"};
  margin: 0;
  padding: 2px 8px;
  line-height: 26px;
  box-shadow: 0px 1px 3px rgba(0,0,0,0.3);
`
